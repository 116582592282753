import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { Comparator } from 'react-bootstrap-table2-filter';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { onTableClick } from "../../../redux/actions/tableActions";
import { connect } from "react-redux";
import {
    Button
} from "react-bootstrap"

// eslint-disable-next-line no-extend-native
String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) { return letters[letter]; })
    return string.toUpperCase();
}

// eslint-disable-next-line no-extend-native
String.prototype.turkishToLower = function () {
    var string = this;
    var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) { return letters[letter]; })
    return string.toLowerCase();
}

const customTotal = (from, to, size) => (
    size > 0 ? <span className="react-bootstrap-table-pagination-total">
        <small style={{ marginLeft: 10 }}>
            Toplam <b>{size}</b> Kayıttan <b>{from}</b> - <b>{to}</b>
        </small>
    </span> : null
);

const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize, columns, tableProps, allData, currentIndex }) => (
    <div>
        <Card title={tableProps.title}>
            <CardHeader icon={tableProps.cardIcon} title={tableProps.title}>
                <CardHeaderToolbar>
                    {
                        tableProps.refreshTable ?
                            <Button onClick={() => tableProps.refreshTable(tableProps.token, tableProps.query)} style={{ marginRight: tableProps.actionsButtonHidden ? 0 : 10 }} variant="outline-primary">
                                <i className="fa fa-sync"></i>
                            </Button> : null

                    }

                    {
                        tableProps.actionsButtonHidden ? null :
                            <button
                                disabled={tableProps.disabledButton ? tableProps.disabledButton : false}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => tableProps.handleModal(true, "create")}
                            >
                                {tableProps.buttonIcon ? <i className={tableProps.buttonIcon}></i> : null}
                                {tableProps.buttonTitle}
                            </button>

                    }

                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ToolkitProvider
                    keyField={tableProps.tableKey ? tableProps.tableKey : "id"}
                    data={data}
                    columns={columns}
                    search={true}
                >
                    {
                        props => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    filter={filterFactory()}
                                    filterPosition="top"
                                    remote={{ search: true, pagination: true, sort: true, filter: true }}
                                    keyField={tableProps.tableKey ? tableProps.tableKey : "id"}
                                    wrapperClasses="table-responsive"
                                    bordered={tableProps.bordered ? tableProps.bordered : false}
                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                    selectRow={tableProps.selectRow}
                                    bootstrap4
                                    data={data}
                                    columns={columns}
                                    rowStyle={tableProps.rowStyle ? tableProps.rowStyle : {}}
                                    cellEdit={tableProps.cellEdit ? tableProps.cellEdit : {}}
                                    rowEvents={{
                                        onClick: (e, row, rowIndex) => {
                                            tableProps.onTableClick(e, row, rowIndex, currentIndex, sizePerPage, page)
                                        }
                                    }}
                                    pagination={paginationFactory({
                                        page,
                                        sizePerPage,
                                        totalSize,
                                        showTotal: true,
                                        hidePageListOnlyOnePage: true,
                                        paginationTotalRenderer: customTotal,
                                        paginationSize: 4,
                                        pageStartIndex: 1,
                                        sizePerPageList: [{
                                            text: '5', value: 5
                                        }, {
                                            text: '10', value: 10
                                        }]
                                    })}
                                    onTableChange={onTableChange}
                                    noDataIndication={() => {
                                        return <div style={{ height: 100, display: "flex", justifyContent: "center", alignItems: "center" }}><small>{tableProps.noDataMessage ? tableProps.noDataMessage : allData.length > 0 ? "Kayıt Bulunamadı" : "Tabloda Kayıt Bulunmamaktadır"}</small></div>
                                    }}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </CardBody>
        </Card>

    </div>
);

class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            allData: this.props.data,
            data: this.props.data.slice(0, 5),
            totalSize: this.props.data.length,
            sizePerPage: 5,
            columns: this.props.columns
        };
        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const currentIndex = (this.state.page - 1) * this.state.sizePerPage;
        this.setState({
            allData: nextProps.data,
            data: nextProps.data.slice(currentIndex, currentIndex + this.state.sizePerPage),
            totalSize: nextProps.data.length,
            columns: nextProps.columns
        });
    }

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
        const currentIndex = (page - 1) * sizePerPage;
        let result = this.state.allData;
        let search;
        switch (type) {
            case "search":
                search = result.filter((row) => {
                    let valid = true;
                    for (const dataField in filters) {
                        const { filterVal, filterType, comparator } = filters[dataField];
                        let search = filterVal;
                        let rowData;
                        if (String(dataField).split(".").length > 1) {
                            // object
                            let fields = String(dataField).split(".");
                            rowData = row[fields[0]][fields[1]];
                        } else {
                            // string
                            rowData = row[dataField];
                        }
                        if (typeof (row[dataField]) === "string") {
                            search = search.turkishToLower();
                            rowData = rowData.turkishToLower();
                        }
                        if (filterType === 'TEXT') {
                            if (comparator === Comparator.LIKE) {
                                valid = rowData.toString().indexOf(search) > -1;
                            } else {
                                valid = row[dataField] === filterVal;
                            }
                        }
                        if (!valid) break;
                    }
                    return valid;
                });
                break;
            case "sort":
                if (sortOrder === 'asc') {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return 1;
                        } else if (b[sortField] > a[sortField]) {
                            return -1;
                        }
                        return 0;
                    });
                } else {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return -1;
                        } else if (b[sortField] > a[sortField]) {
                            return 1;
                        }
                        return 0;
                    });
                }
                break;
            case "filter":
                // Handle column filters
                result = result.filter((row) => {
                    let valid = true;
                    for (const dataField in filters) {
                        const { filterVal, filterType, comparator } = filters[dataField];

                        let search = filterVal;
                        let rowData;
                        if (String(dataField).split(".").length > 1) {
                            // object
                            let fields = String(dataField).split(".");
                            rowData = row[fields[0]][fields[1]];
                        } else {
                            // string
                            rowData = row[dataField];
                        }

                        if (typeof (row[dataField]) === "string") {
                            search = search.turkishToLower();
                            rowData = rowData.turkishToLower();
                        }

                        if (filterType === 'TEXT') {
                            switch (dataField) {
                                case "isActive":
                                    row[dataField] = row[dataField] === true ? "aktif" : "pasif";
                                    break;
                                case "isBloked":
                                    row[dataField] = row[dataField] === true ? "bloke" : "aktif";
                                    break;
                                default:
                                    break;
                            }
                            if (comparator === Comparator.LIKE) {
                                valid = rowData.toString().indexOf(search) > -1;
                            } else {
                                valid = row[dataField] === filterVal;
                            }
                        }
                        if (!valid) break;
                    }
                    return valid;
                });
                break;
            default:
                break;
        }

        this.setState(() => ({
            page,
            data: type === "search" ? search : result.slice(currentIndex, currentIndex + sizePerPage),
            totalSize: result.length,
            sizePerPage
        }));
    }


    render() {
        const { data, sizePerPage, page, columns, options, allData } = this.state;
        const currentIndex = (page - 1) * sizePerPage;
        return (
            <RemoteAll
                allData={allData}
                tableProps={this.props}
                columns={columns}
                data={data}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={this.state.totalSize}
                options={options}
                currentIndex={currentIndex}
                onTableChange={this.handleTableChange}
            />
        );
    }
}

const mapStateToProps = function (state) {
    return {
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        onTableClick
    })(Container);