import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    update,
    tableActions,
    partialRefund
} from "../../../../../redux/actions/orderActions";
import {
    Button,
    Modal,
    Badge
} from "react-bootstrap";
import DataTable from "../../../../helpers/datatable/DataTable";
import {
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    FormControl,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Avatar,
    Chip,
    FormHelperText,
    TextField,
    Checkbox
} from "@material-ui/core";
import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';
import {
    globalURL
} from "../../../../constants/constants";
import { return3DErrors, returnStatusColor } from "../../../../helpers/functions";


function searchImages(item, array) {
    var result = array.find(x => String(x.color) === String(item.stockCard.color.id));
    if (result) {
        return globalURL + "/images/" + result.images[0].name;
    } else {
        return null
    }
}

function OrdersPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const columns = [
        {
            dataField: "orderNumber",
            text: "SİPARİŞ No",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.order.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "user.name",
            text: "AD",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.order.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "user.surname",
            text: "SOYAD",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.order.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "paidPrice",
            text: "TUTAR",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.order.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "state",
            text: "SİPARİŞ DURUMU",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.order.data.length > 0 ? "block" : "none"
                }
            }),
            formatter: (cell) => {
                return <Badge variant={returnStatusColor(cell)} className="mr-1">
                    {cell}
                </Badge>
            },
            headerSortingClasses
        },
        {
            dataField: "paymentStatus",
            text: "ÖDEME DURUMU",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.order.data.length > 0 ? "block" : "none"
                }
            }),
            formatter: (cell) => {
                return <Badge variant={returnStatusColor(cell)} className="mr-1">
                    {cell}
                </Badge>
            },
            headerSortingClasses
        },
        {
            dataField: "createdAt",
            text: "SİPARİŞ ZAMANI",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: {
                width: 150
            },
            formatter: (cell) => {
                return <div style={{ display: "flex", flexDirection: "column" }}>
                    <small>{moment(cell).format('DD.MM.YYYY HH:mm:ss')}</small>
                    <small>{moment(cell).fromNow()}</small>
                </div>
            }
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token, true)
            }

        }
    ];

    return <>
        <Loading show={props.order.isFetchingDetail || props.order.isFetching} />
        <Modal backdrop="static" size="lg" show={props.order.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.order.process === "create" ? "fa fa-plus" : props.order.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.order.process === "create" ? "Yeni Sipariş" : props.order.process === "update" ? "Sipariş Detayı" : "Sipariş Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.order.detail !== null ?
                        <><div className="row">
                            <div className="col-md-12">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SİPARİŞ DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Numarası
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.orderNumber}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Zamanı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <small>{moment(props.order.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                        <small>{moment(props.order.detail.createdAt).fromNow()}</small>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(props.order.detail.state)} className="mr-1">
                                                            {props.order.detail.state}
                                                        </Badge>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Notu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.orderNote}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <br />
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ÖDEME DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        3D Ödeme
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.used3D === true ? "Evet" : "Hayır"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ödeme Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {
                                                            props.order.detail.paymentStatus === "success" && props.order.detail.state !== "pending3DAction" ?
                                                                <Badge variant={returnStatusColor(String(JSON.parse(props.order.detail.paymentResult).paymentStatus).toLowerCase())} className="mr-1">
                                                                    {String(JSON.parse(props.order.detail.paymentResult).paymentStatus).toLowerCase()}
                                                                </Badge> :
                                                                <Badge variant={returnStatusColor(props.order.detail.paymentStatus).toLowerCase()} className="mr-1">
                                                                    {String(props.order.detail.paymentStatus).toLowerCase()}
                                                                </Badge>

                                                        }


                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    JSON.parse(props.order.detail.paymentResult).fraudStatus ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Fraud Statüsü
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {JSON.parse(props.order.detail.paymentResult).fraudStatus}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }

                                                {
                                                    props.order.detail.paymentStatus === "failure" ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Hata Mesajı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {JSON.parse(props.order.detail.paymentResult).errorMessage}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }

                                                {
                                                    props.order.detail.paymentStatus === "3DFailure" && props.order.detail.threedPaymentResult ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                3D Hata Detayı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {return3DErrors(JSON.parse(props.order.detail.threedPaymentResult).mdStatus)}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                                {
                                                    props.order.detail.bankName ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Banka
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.order.detail.bankName}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                                {
                                                    props.order.detail.cardFamilyName ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Adı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.order.detail.cardFamilyName}
                                                            </TableCell>
                                                        </TableRow> : null

                                                }
                                                {
                                                    props.order.detail.cardAssociation ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Şirketi
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.order.detail.cardAssociation}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }

                                                {
                                                    props.order.detail.cardType ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Tipi
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.order.detail.cardType}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }

                                                    <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Kargo Ücreti
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.shippingPrice}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                       Sepet Tutarı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.subTotal}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Vadesiz Tutar
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.price}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Taksit Sayısı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.installment}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ödenen Vadeli Tutar
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.paidPrice}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                              
                                              


                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div className='col-md-6'>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>KULLANICI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ad Soyad
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.user.name + " " + props.order.detail.user.surname}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Email
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.user.email}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Dil
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.user.preferedLanguage === "tr" ? "Türkçe" : "İngilizce"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <br />
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>TESLİMAT BİLGİLERİ</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        İsim
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.address.nameSurname}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Telefon
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {"+" + props.order.detail.address.phone}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Adres
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.address.address}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sokak / Mah.
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.address.street.streetName}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        İlçe
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.address.district.districtName}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Şehir
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.order.detail.address.city.cityName}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>

                                <br />
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ÜRÜN BİLGİLERİ</TableCell>
                                            {
                                                props.order.orderState === "partialRefund"
                                                && props.order.detail.canOrderRefund === true
                                                    && props.order.detail.state !== "refundError"
                                                    && props.order.detail.state !== "canceled"
                                                    && props.order.detail.state !== "canceledByAdmin"
                                                    && props.order.detail.state !== "sendedRefundRequest"
                                                    && props.order.detail.state !== "pending3DAction" ?
                                                    <TableCell></TableCell> : null
                                            }
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                props.order.orderState === "partialRefund"
                                                && props.order.detail.canOrderRefund === true
                                                    && props.order.detail.state !== "refundError"
                                                    && props.order.detail.state !== "canceled"
                                                    && props.order.detail.state !== "canceledByAdmin"
                                                    && props.order.detail.state !== "pending3DAction" ?
                                                    <TableCell></TableCell> : null
                                            }
                                            <TableCell>Resim</TableCell>
                                            <TableCell>Adet</TableCell>
                                            <TableCell>Ürün Detayı</TableCell>
                                            <TableCell>Fiyat</TableCell>
                                            <TableCell>Toplam</TableCell>
                                            <TableCell>Durum</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            props.order.detail.cart.cartItems.map((item, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {
                                                            props.order.orderState === "partialRefund" 
                                                                && props.order.detail.canOrderRefund === true
                                                                && props.order.detail.state !== "refundError"
                                                                && props.order.detail.state !== "canceled"
                                                                && props.order.detail.state !== "canceledByAdmin"
                                                                && props.order.detail.state !== "pending3DAction" ?
                                                                <TableCell>
                                                                    {
                                                                        item.canRefund ?
                                                                            <Checkbox checked={item.selected} onChange={(e) => props.setValue("selectedItem", e.target.checked, i)} /> : null
                                                                    }

                                                                </TableCell> : null
                                                        }
                                                        <TableCell component="th" scope="row">
                                                            {
                                                                searchImages(item, item.stockCard.product.stockData) !== null ?
                                                                    <img style={{ height: 75 }} src={searchImages(item, item.stockCard.product.stockData)} /> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.selected ?
                                                                <FormControl className="form-control" variant="outlined">
                                                                    <InputLabel htmlFor="outlined-age-simple">
                                                                        Seçiniz
                                                                    </InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        style={{ height: 50 }}
                                                                        value={item.selectedQuantity}
                                                                        onChange={(event) => props.setValue("selectedQuantity", event.target.value, i)}
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={47}
                                                                                name="age"
                                                                            />
                                                                        }
                                                                    >
                                                                        {
                                                                            item.options.map((option, key) => {
                                                                                return <MenuItem key={key} value={option.value}>{option.text}</MenuItem>
                                                                            })
                                                                        }
                                                                    </Select>

                                                                </FormControl> :
                                                                item.quantity}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {item.stockCard.product.productName.tr}<br />
                                                            {item.stockCard.color.colorName}<br />
                                                            {item.stockCard.size.sizeCode}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {item.actualHasDiscount ? item.actualDiscountedPrice.try : item.actualNormalPrice.try}{" TL"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.actualHasDiscount ? item.actualDiscountedPrice.try * item.quantity : item.actualNormalPrice.try * item.quantity}{" TL"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Badge variant={returnStatusColor(item.state)} className="mr-1">
                                                                {item.state}
                                                            </Badge>
                                                            {
                                                                item.pendingRefundCount > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <small style={{ marginTop: 10 }}>{item.pendingRefundCount}{" adet ürün için iade talebi gönderildi"}</small>
                                                                    </> : null
                                                            }
                                                            {
                                                                item.refundedCount > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <small style={{ marginTop: 10 }}>{item.refundedCount}{" adet ürün iade edildi"}</small>
                                                                    </> : null
                                                            }

                                                            {item.state === "refundError" ? <>
                                                                <hr />
                                                                <small>{JSON.parse(item.refundResult).errorMessage}</small>
                                                            </> : null}

                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                                <br />
                                <br />
                                {
                                    props.order.detail.paymentStatus === "success" ?
                                        <>

                                            <FormControl style={{ marginLeft: 6, marginTop: 8 }} className="form-control" variant="outlined">

                                                <InputLabel htmlFor="outlined-age-simple">
                                                    Sipariş Durumu
                                                </InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={props.order.detail.state === "refundError" ||
                                                    props.order.detail.state === "canceled" ||
                                                    props.order.detail.state === "canceledByAdmin" ||
                                                    props.order.detail.state === "pending3DAction" ? true : false}
                                                    error={props.order.orderState_error}
                                                    value={props.order.orderState}
                                                    onChange={(event) => props.setValue("orderState", event.target.value)}
                                                    input={
                                                        <OutlinedInput
                                                            labelWidth={100}
                                                            name="age"
                                                        />
                                                    }
                                                >
                                                    <MenuItem disabled value={"pending3DAction"}>3D Ödemesi Bekleniyor</MenuItem>
                                                    <MenuItem disabled={props.order.detail.state === "pending" ? false : true} value={"pending"}>Onay Bekliyor</MenuItem>
                                                    <MenuItem disabled={props.order.detail.state === "pending" ? false : true} value={"approved"}>Onaylandı</MenuItem>
                                                    <MenuItem disabled={props.order.detail.state === "approved" ? false : true} value={"preparing"}>Sipariş Hazırlanıyor</MenuItem>
                                                    <MenuItem disabled={props.order.detail.state === "preparing" ? false : true} value={"shipped"}>Kargoya Verildi</MenuItem>
                                                    <MenuItem  value={"delivered"}>Teslim Edildi</MenuItem>
                                                    <MenuItem disabled value={"canceled"}>İptal Edildi</MenuItem>
                                                    <MenuItem disabled value={"sendedRefundRequest"}>İade Talebi Gönderildi</MenuItem>
                                                    <MenuItem disabled={props.order.detail.state === "sendedRefundRequest" ||  props.order.detail.state === "canceledByAdmin" || props.order.detail.state === "partialRefund" ? true : false} value={"canceledByAdmin"}>Siparişi İptal Et</MenuItem>
                                                    <MenuItem disabled={props.order.detail.state === "canceledByAdmin" || props.order.detail.state === "canceled" ||  props.order.detail.canOrderRefund === false ? true : false} value={"partialRefund"}>Ürünleri Seç ve İptal Et</MenuItem>
                                                    <MenuItem disabled value={"refundError"}>Karta İade Yapılamadı</MenuItem>
                                                </Select>

                                            </FormControl>
                                            {
                                                props.order.orderState === "canceledByAdmin" || props.order.orderState === "partialRefund" ?
                                                    <TextField
                                                        label="İptal Nedeni"
                                                        style={{ marginTop: 30, marginLeft: 6 }}
                                                        placeholder="İptal Nedeni"
                                                        autoComplete="off"
                                                        disabled={props.order.detail.state === "canceledByAdmin" ? true : false}
                                                        error={props.order.cancelReason_error}
                                                        value={props.order.cancelReason}
                                                        onChange={(event) => props.setValue("cancelReason", event.target.value)}
                                                        helperText={props.order.cancelReason_helpertext}
                                                        fullWidth
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        FormHelperTextProps={{
                                                            style: {
                                                                marginLeft: 0
                                                            }
                                                        }}
                                                    /> : null
                                            }
                                            <br />
                                        </>
                                        : null
                                }
                            </div>

                        </div>
                            <div className='row'>
                                <div className="col-md-12">

                                    <br />
                                    <br />
                                    <>
                                        {
                                            props.order.detail.updatedBy !== null && props.order.detail.updatedBy ?
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>İŞLEM GEÇMİŞİ</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Chip
                                                                    style={{ marginLeft: -5 }}
                                                                    avatar={<Avatar>{String(props.order.detail.updatedBy.name[0]).toUpperCase() + String(props.order.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                    label={props.order.detail.updatedBy.username}
                                                                /><br />
                                                                <small>{moment(props.order.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                                <small>{moment(props.order.detail.updatedAt).fromNow()}</small>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table> : null
                                        }
                                    </>
                                </div>
                            </div>

                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.order.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.order.process)}>
                    Kapat
                </Button>
                {
                    props.order.detail !== null && props.order.detail.paymentStatus === "success" && props.order.orderState !== props.order.detail.state ?

                        <Button onClick={() => {
                            if (props.order.orderState === "partialRefund") {
                                if(props.order.detail.canOrderRefund === true){
                                    props.partialRefund(props.auth.user.token, props.order.detail.id, props.order.detail.cart.cartItems, props.order.detail.orderNumber, props.table.index, props.order.cancelReason);
                                }
                            } else {
                                props.update(props.auth.user.token, props.order.detail.id, props.order.orderState, props.order.detail.orderNumber, props.table.index, props.order.cancelReason);
                            }
                        }} style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.order.isFetchingActions} variant="primary">
                            {
                                props.order.isFetchingActions ?
                                    <span className="spinner spinner-white"></span> :
                                    "Güncelle"
                            }
                        </Button> : null
                }
            </Modal.Footer>
        </Modal>
        <DataTable
            actionsButtonHidden={true}
            handleModal={props.toggleModal}
            title="Tüm Siparişler"
            buttonTitle="Yeni Sipariş"
            cardIcon="fa fa-wallet"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.order.data}
            columns={columns}
            token={props.auth.user.token}
            refreshTable={props.getAll}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        order: state.order,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        update,
        tableActions,
        partialRefund

    })(OrdersPage);
