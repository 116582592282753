import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    update,
    tableActions,
} from "../../../../../redux/actions/refundActions";
import {
    Button,
    Modal,
    Badge
} from "react-bootstrap";
import DataTable from "../../../../helpers/datatable/DataTable";
import {
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    FormControl,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Avatar,
    Chip,
    FormHelperText,
    TextField
} from "@material-ui/core";
import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';
import {
    globalURL
} from "../../../../constants/constants";
import { returnStatusColor } from "../../../../helpers/functions";


function searchImages(item, array) {
    var result = array.find(x => String(x.color) === String(item.stockCard.color.id));
    if (result) {
        return globalURL + "/images/" + result.images[0].name;
    } else {
        return null
    }
}

function RefundsPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const columns = [
        {
            dataField: "cartItem",
            text: "ÜRÜN",
            sort: false,
            filter: null,
            formatter: (item) => {
                return <div style={{ minWidth: 90 }}>
                    <img src={searchImages(item, item.stockCard.product.stockData)} style={{ height: 90, width: 60 }} />
                    <br /><br />
                    <small><b>RENK : </b>{item.stockCard.color.colorCode}</small><br />
                    <small><b>BEDEN : </b>{item.stockCard.size.sizeCode}</small>
                </div>
            }
        },
        {
            dataField: "order.orderNumber",
            text: "SİPARİŞ NO",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.refund.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "quantity",
            text: "ADET",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.refund.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "reason",
            text: "İADE NEDENİ",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.refund.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "state",
            text: "DURUM",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.refund.data.length > 0 ? "block" : "none"
                }
            }),
            formatter: (cell) => {
                return <Badge variant={returnStatusColor(cell)} className="mr-1">
                    {cell}
                </Badge>
            },
            headerSortingClasses
        },
        {
            dataField: "createdAt",
            text: "ZAMANI",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: {
                width: 150
            },
            formatter: (cell) => {
                return <div style={{ display: "flex", flexDirection: "column" }}>
                    <small>{moment(cell).format('DD.MM.YYYY HH:mm:ss')}</small>
                    <small>{moment(cell).fromNow()}</small>
                </div>
            }
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token, true)
            }

        }
    ];

    return <>
        <Loading show={props.refund.isFetchingDetail || props.refund.isFetching} />
        <Modal backdrop="static" size="lg" show={props.refund.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.refund.process === "create" ? "fa fa-plus" : props.refund.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.refund.process === "create" ? "Yeni İade Talebi" : props.refund.process === "update" ? "İade Talebi Detayı" : "İade Talebi Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.refund.detail !== null ?
                        <><div className="row">
                            <div className="col-md-12">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SİPARİŞ DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Numarası
                                                    </TableCell>                                            <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.orderNumber}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Zamanı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <small>{moment(props.refund.detail.order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                        <small>{moment(props.refund.detail.order.createdAt).fromNow()}</small>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(props.refund.detail.order.state)} className="mr-1">
                                                            {props.refund.detail.order.state}
                                                        </Badge>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Notu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.orderNote}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <br />
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>KULLANICI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ad Soyad
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.user.name + " " + props.refund.detail.user.surname}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Email
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.user.email}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Dil
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.user.preferedLanguage === "tr" ? "Türkçe" : "İngilizce"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div className='col-md-6'>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ÖDEME DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    props.refund.detail.order.bankName ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Banka
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.refund.detail.order.bankName}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                                {
                                                    props.refund.detail.order.cardFamilyName ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Adı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.refund.detail.order.cardFamilyName}
                                                            </TableCell>
                                                        </TableRow> : null

                                                }
                                                {
                                                    props.refund.detail.order.cardAssociation ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Şirketi
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.refund.detail.order.cardAssociation}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }

                                                {
                                                    props.refund.detail.order.cardType ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Tipi
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.refund.detail.order.cardType}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }


                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sepet Tutarı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.subTotal}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Kargo Ücreti
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.shippingPrice}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Vadesiz Tutar
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.price}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ödenen Tutar
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.paidPrice}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Taksit Sayısı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.installment}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        3D Ödeme
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.order.used3D === true ? "Evet" : "Hayır"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ödeme Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(String(JSON.parse(props.refund.detail.order.paymentResult).status).toLowerCase())} className="mr-1">
                                                            {String(JSON.parse(props.refund.detail.order.paymentResult).status).toLowerCase()}
                                                        </Badge>

                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Fraud Statüsü
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {JSON.parse(props.refund.detail.order.paymentResult).fraudStatus}
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    props.refund.detail.order.paymentStatus === "failure" ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Hata Mesajı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {JSON.parse(props.refund.detail.order.paymentResult).errorMessage}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>

                                <br />
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SİPARİŞ EDİLEN ÜRÜNLER</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Resim</TableCell>
                                            <TableCell>Adet</TableCell>
                                            <TableCell>Ürün Detayı</TableCell>
                                            <TableCell>Fiyat</TableCell>
                                            <TableCell>Toplam</TableCell>
                                            <TableCell>Durum</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            props.refund.detail.order.cart.cartItems.map((item, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell component="th" scope="row">
                                                            {
                                                                searchImages(item, item.stockCard.product.stockData) !== null ?
                                                                    <img style={{ height: 75 }} src={searchImages(item, item.stockCard.product.stockData)} /> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.quantity}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.stockCard.product.productName.tr}<br />
                                                            {item.stockCard.color.colorName}<br />
                                                            {item.stockCard.size.sizeCode}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.actualHasDiscount ? item.actualDiscountedPrice.try : item.actualNormalPrice.try}{" TL"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.actualHasDiscount ? item.actualDiscountedPrice.try * item.quantity : item.actualNormalPrice.try * item.quantity}{" TL"}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Badge variant={returnStatusColor(item.state)} className="mr-1">
                                                                {item.state}
                                                            </Badge>

                                                            {
                                                                item.pendingRefundCount > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <small style={{ marginTop: 10 }}>{item.pendingRefundCount}{" adet ürün için iade talebi gönderildi"}</small>
                                                                    </> : null
                                                            }
                                                            {
                                                                item.refundedCount > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <small style={{ marginTop: 10 }}>{item.refundedCount}{" adet iade edildi"}</small>
                                                                    </> : null
                                                            }

                                                            {item.state === "refundError" ? <>
                                                                <hr />
                                                                <small>{JSON.parse(item.refundResult).errorMessage}</small>
                                                            </> : null}


                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>

                                <br />
                                <div className='separator separator-dashed my-7'></div>

                                <div className='row'>
                                    <div className='col-md-5'>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>İADE DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Talep Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(props.refund.detail.state)} className="mr-1">
                                                            {props.refund.detail.state}
                                                        </Badge>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Talep Zamanı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <small>{moment(props.refund.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                        <small>{moment(props.refund.detail.createdAt).fromNow()}</small>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        İade Nedeni
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.reason}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Diğer
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.other}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div className='col-md-7'>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ÜRÜN</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Resim</TableCell>
                                                    <TableCell>Adet</TableCell>
                                                    <TableCell>Ürün Detayı</TableCell>
                                                    <TableCell>Fiyat</TableCell>
                                                    <TableCell>Toplam</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {
                                                            searchImages(props.refund.detail.cartItem, props.refund.detail.cartItem.stockCard.product.stockData) !== null ?
                                                                <img style={{ height: 75 }} src={searchImages(props.refund.detail.cartItem, props.refund.detail.cartItem.stockCard.product.stockData)} /> : null
                                                        }
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.quantity}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.cartItem.stockCard.product.productName.tr}<br />
                                                        {props.refund.detail.cartItem.stockCard.color.colorName}<br />
                                                        {props.refund.detail.cartItem.stockCard.size.sizeCode}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.cartItem.actualHasDiscount ? props.refund.detail.cartItem.actualDiscountedPrice.try : props.refund.detail.cartItem.actualNormalPrice.try}{" TL"}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.refund.detail.cartItem.actualHasDiscount ? props.refund.detail.cartItem.actualDiscountedPrice.try * props.refund.detail.quantity : props.refund.detail.cartItem.actualNormalPrice.try * props.refund.detail.quantity}{" TL"}
                                                    </TableCell>

                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>


                                <br />

                                <>

                                    <FormControl style={{ marginLeft: 6, marginTop: 8 }} className="form-control" variant="outlined">

                                        <InputLabel htmlFor="outlined-age-simple">
                                            Talep Durumu
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            disabled={props.refund.detail.state === "pending" ? false : true}
                                            error={props.refund.refundState_error}
                                            value={props.refund.refundState}
                                            onChange={(event) => props.setValue("refundState", event.target.value)}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={100}
                                                    name="age"
                                                />
                                            }
                                        >
                                            <MenuItem value={"pending"}>Onay Bekliyor</MenuItem>
                                            <MenuItem value={"confirmed"}>Onaylandı</MenuItem>
                                            <MenuItem disabled value={"canceled"}>İptal Edildi</MenuItem>
                                            <MenuItem value={"canceledByAdmin"}>Talebi İptal Et</MenuItem>
                                        </Select>

                                    </FormControl>

                                    {
                                        props.refund.refundState === "canceledByAdmin" ?
                                            <TextField
                                                label="İptal Nedeni"
                                                style={{ marginTop: 30, marginLeft: 6 }}
                                                placeholder="İptal Nedeni"
                                                autoComplete="off"
                                                error={props.refund.deniedReason_error}
                                                value={props.refund.deniedReason}
                                                onChange={(event) => props.setValue("deniedReason", event.target.value)}
                                                helperText={props.refund.deniedReason_helpertext}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                FormHelperTextProps={{
                                                    style: {
                                                        marginLeft: 0
                                                    }
                                                }}
                                            /> : null
                                    }
                                </>


                            </div>

                        </div>
                            <br />
                            <div className='separator separator-dashed my-7'></div>
                            <div className='row'>
                                <div className="col-md-12">

                                    <>
                                        {
                                            props.refund.detail.updatedBy !== null && props.refund.detail.updatedBy ?
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>İŞLEM GEÇMİŞİ</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Chip
                                                                    style={{ marginLeft: -5 }}
                                                                    avatar={<Avatar>{String(props.refund.detail.updatedBy.name[0]).toUpperCase() + String(props.refund.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                    label={props.refund.detail.updatedBy.username}
                                                                /><br />
                                                                <small>{moment(props.refund.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                                <small>{moment(props.refund.detail.updatedAt).fromNow()}</small>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table> : null
                                        }
                                    </>
                                </div>
                            </div>
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.refund.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.refund.process)}>
                    Kapat
                </Button>
                {
                    props.refund.detail !== null && props.refund.detail.order.paymentStatus === "success" && props.refund.refundState !== "canceled" && props.refund.refundState !== props.refund.detail.state ?
                        <Button onClick={() => props.update(props.auth.user.token, props.refund.detail.id ? props.refund.detail.id : props.refund.detail._id, props.refund.refundState, props.refund.deniedReason, props.table.index)} style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.refund.isFetchingActions} variant="primary">
                            {
                                props.refund.isFetchingActions ?
                                    <span className="spinner spinner-white"></span> :
                                    "Güncelle"
                            }
                        </Button> : null
                }
            </Modal.Footer>
        </Modal>
        <DataTable
            actionsButtonHidden={true}
            handleModal={props.toggleModal}
            title="Tüm İade Talepleri"
            cardIcon="fa fa-undo"
            searchPlaceholder="Ara"
            data={props.refund.data}
            columns={columns}
            token={props.auth.user.token}
            refreshTable={props.getAll}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        refund: state.refund,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        update,
        tableActions,

    })(RefundsPage);
