import moment from "../../../app/constants/moment"

const initialState = {
    data: [],
    titles: [],
    roles: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    isBlocked: false,
    name: "",
    surname: "",
    username: "",
    email: "",
    phone: "",
    gender: "",
    role: "",
    title: "",
    dateOfBirth: "",
    isBlocked_helpertext: "",
    isBlocked_error: false,
    name_helpertext: "",
    name_error: false,
    surname_helpertext: "",
    surname_error: false,
    username_helpertext: "",
    username_error: false,
    email_helpertext: "",
    email_error: false,
    phone_helpertext: "",
    phone_error: false,
    gender_helpertext: "",
    gender_error: false,
    role_helpertext: "",
    role_error: false,
    title_helpertext: "",
    title_error: false,
    dateOfBirth_helpertext: "",
    dateOfBirth_error: false,
   
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_USERS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_USERS":
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                roles: action.roles.data,
                titles: action.titles.data
            }
        case "ERROR_ALL_USERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_USER_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                name: action.payload.process === "update" ? state.name : "",
                surname: action.payload.process === "update" ? state.surname : "",
                username: action.payload.process === "update" ? state.username : "",
                email: action.payload.process === "update" ? state.email : "",
                phone: action.payload.process === "update" ? state.phone : "",
                gender: action.payload.process === "update" ? state.gender : "",
                role: action.payload.process === "update" ? state.role : "",
                title: action.payload.process === "update" ? state.title : "",
                dateOfBirth: action.payload.process === "update" ? state.dateOfBirth : "",
                name_helpertext: "",
                name_error: false,
                surname_helpertext: "",
                surname_error: false,
                username_helpertext: "",
                username_error: false,
                email_helpertext: "",
                email_error: false,
                phone_helpertext: "",
                phone_error: false,
                gender_helpertext: "",
                gender_error: false,
                role_helpertext: "",
                role_error: false,
                title_helpertext: "",
                title_error: false,
                dateOfBirth_helpertext: "",
                dateOfBirth_error: false
            }
        case "SET_VALUE_USERS":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_USERS":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }

        case "GET_DELETE_USER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_USER":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_USER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }

        case "GET_CREATE_USER":
            return {
                ...state,
                isFetchingActions: true
            }
        case "FETCH_CREATE_USER":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetchingActions: false,
                data: data,
                showModal: false,
                error: false,
                name: "",
                surname: "",
                username: "",
                email: "",
                phone: "",
                gender: "",
                role: "",
                title: "",
                dateOfBirth: "",
                name_helpertext: "",
                name_error: false,
                surname_helpertext: "",
                surname_error: false,
                username_helpertext: "",
                username_error: false,
                email_helpertext: "",
                email_error: false,
                phone_helpertext: "",
                phone_error: false,
                gender_helpertext: "",
                gender_error: false,
                role_helpertext: "",
                role_error: false,
                title_helpertext: "",
                title_error: false,
                dateOfBirth_helpertext: "",
                dateOfBirth_error: false
            }
        case "ERROR_CREATE_USER":
            return {
                ...state,
                error: true,
                isFetchingActions: false
            }

        case "GET_UPDATE_USER":
            return {
                ...state,
                error: false,
                isFetchingActions: true
            }
        case "FETCH_UPDATE_USER":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                name: "",
                surname: "",
                username: "",
                email: "",
                phone: "",
                gender: "",
                role: "",
                title: "",
                dateOfBirth: "",
                name_helpertext: "",
                name_error: false,
                surname_helpertext: "",
                surname_error: false,
                username_helpertext: "",
                username_error: false,
                email_helpertext: "",
                email_error: false,
                phone_helpertext: "",
                phone_error: false,
                gender_helpertext: "",
                gender_error: false,
                role_helpertext: "",
                role_error: false,
                title_helpertext: "",
                title_error: false,
                dateOfBirth_helpertext: "",
                dateOfBirth_error: false
            }
        case "ERROR_UPDATE_USER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }

        case "GET_DETAIL_USER":
            return {
                ...state,
                error: false,
                isFetchingDetail: true
            }

        case "FETCH_DETAIL_USER":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                name: action.payload.data.name,
                surname: action.payload.data.surname,
                username: action.payload.data.username,
                email: action.payload.data.email,
                phone: action.payload.data.phone,
                gender: action.payload.data.gender,
                role: action.payload.data.role !== null ? action.payload.data.role.id : "",
                title: action.payload.data.title !== null ? action.payload.data.title.id : "",
                isBlocked: action.payload.data.isBlocked,
                dateOfBirth: moment(action.payload.data.dateOfBirth).format("YYYY-MM-DD")
            }

        case "ERROR_DETAIL_USER":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }


        default:
            return state
    }

}
