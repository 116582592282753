import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";

const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_PRODUCT_MODAL",
        payload: {
            show: show,
            process: process
        }
    });

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}

const addStockDataRow = (colors, stockData, sizes) => dispatch => {
    var stockObj = {
        color: "",
        color_helpertext: "",
        color_error: false,
        selectedSizes: [sizes[0].id],
        selectedSizes_helpertext: "",
        selectedSizes_error: false,
        images: []
    }


    if (colors.length > stockData.length) {
        for (let color of colors) {
            var index = stockData.findIndex(x => x.color === color.id);
            if (index < 0) {
                stockObj.color = color.id;
                color['selected'] = true;
                break;
            }
        }

        stockData.push(stockObj);
        dispatch({
            type: "ADD_STOCK_DATA_ROW",
            payload: {
                stockData: stockData,
                colors: colors
            }
        })
    }
}

const removeStockDataRow = (index) => dispatch => {
    dispatch({
        type: "REMOVE_STOCK_DATA_ROW",
        payload: index
    })
}

const setStockData = (key, field, index, value) => dispatch => {
    dispatch({
        type: "SET_STOCK_DATA",
        payload: {
            key: key,
            field: field,
            index: index,
            value: value
        }
    })
}


const setValue = (key, value, field, index, props) => dispatch => {
    switch (key) {
        case "prices":
            switch (field) {
                case "currency":
                    let exists = false;
                    for (let price of props) {
                        if (price.currency === value) {
                            exists = true;
                        }
                    }
                    if (!exists) {
                        props[index].currency = value;
                        dispatch({
                            type: "SET_PRICES",
                            payload: props,
                            key: key,
                            value: value,
                            field: field,
                            index: index
                        })
                    }

                    break;
                default:
                    props[index][field] = value;
                    dispatch({
                        type: "SET_PRICES",
                        payload: props,
                        key: key,
                        value: value,
                        field: field,
                        index: index
                    })
                    break;
            }
            break;
        case "shippingPrices":
            switch (field) {
                case "currency":
                    let exists = false;
                    for (let price of props) {
                        if (price.currency === value) {
                            exists = true;
                        }
                    }
                    if (!exists) {
                        props[index].currency = value;
                        dispatch({
                            type: "SET_SHIPPING_PRICES",
                            payload: props,
                            key: key,
                            value: value,
                            field: field,
                            index: index
                        })
                    }

                    break;
                default:
                    props[index][field] = value;
                    dispatch({
                        type: "SET_SHIPPING_PRICES",
                        payload: props,
                        key: key,
                        value: value,
                        field: field,
                        index: index
                    })
                    break;
            }
            break;
        default:
            dispatch({
                type: "SET_VALUE_PRODUCT",
                payload: {
                    key: key,
                    value: value
                }
            })
            break;
    }
}

const addPriceRow = (prices, discountType) => dispatch => {
    let currencies = ["try", "usd", "eur"];
    var obj = {
        currency: "",
        currency_helpertext: "",
        currency_error: false,
        normalPrice: 200,
        discountedPrice: 160,
        normalPrice_helpertext: "",
        normalPrice_error: false,
        discountedPrice_helpertext: "",
        discountedPrice_error: false,
        discount: discountType === "price" ? 40 : 20,
        discount_helpertext: "",
        discount_error: false,
    };
    if (prices.length < 3) {

        for (let currency of currencies) {
            var index = prices.findIndex(x => x.currency === currency);
            if (index < 0) {
                obj.currency = currency;
                break;
            }
        }

        prices.push(obj);
        dispatch({
            type: "ADD_PRICES_ROW",
            payload: prices
        })
    }
}

const addShippingPriceRow = (prices) => dispatch => {
    let currencies = ["try", "usd", "eur"];
    var obj = {
        currency: "",
        currency_helpertext: "",
        currency_error: false,
        price: 15,
        price_helpertext: "",
        price_error: false
    };
    if (prices.length < 3) {
        for (let currency of currencies) {
            var index = prices.findIndex(x => x.currency === currency);
            if (index < 0) {
                obj.currency = currency;
                break;
            }
        }
        prices.push(obj);
        dispatch({
            type: "ADD_SHIPPING_PRICES_ROW",
            payload: prices
        })
    }
}

const addDetailsRow = (field, subField) => dispatch => {
    dispatch({
        type: "ADD_DETAILS_ROW",
        payload: {
            field: field,
            subField: subField
        }
    })
}

const removeDetailsRow = (field, subField, index) => dispatch => {
    dispatch({
        type: "REMOVE_DETAILS_ROW",
        payload: {
            field: field,
            subField: subField,
            index: index
        }
    })
}

const setDetailsData = (field, subField, index, value) => dispatch => {
    dispatch({
        type: "SET_DETAILS_DATA",
        payload: {
            field: field,
            subField: subField,
            index: index,
            value: value
        }
    })
}

const removePriceRow = (index) => dispatch => {
    dispatch({
        type: "REMOVE_PRICES_ROW",
        payload: index
    })
}

const removeShippingPriceRow = (index) => dispatch => {
    dispatch({
        type: "REMOVE_SHIPPING_PRICES_ROW",
        payload: index
    })
}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_PRODUCTS"
    })
    fetch(globalURL + "/product/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (products) => {
            if (products.success) {
                let colors = await fetch(globalURL + "/color/getAll", {
                    method: 'POST',
                    headers: {
                        ...fetchApiHeaders.POST,
                        token: token
                    }
                });
                let categories = await fetch(globalURL + "/category/getAllActives", {
                    method: 'POST',
                    headers: {
                        ...fetchApiHeaders.POST,
                        token: token
                    }
                });

                let sizes = await fetch(globalURL + "/size/getAll", {
                    method: 'POST',
                    headers: {
                        ...fetchApiHeaders.POST,
                        token: token
                    }
                });

                dispatch({
                    type: "FETCH_ALL_PRODUCTS",
                    payload: products.data,
                    colors: await colors.json(),
                    categories: await categories.json(),
                    sizes: await sizes.json()
                });

            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ALL_PRODUCTS",
                    payload: products
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ALL_PRODUCTS",
                payload: error
            })
        })
}

const createNew = (token, data) => dispatch => {
    dispatch({
        type: "GET_INSERT_PRODUCTS"
    })

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // create new

        var features = {
            tr: [],
            eng: []
        };

        var materials = {
            tr: [],
            eng: []
        };

        var sizeAndFit = {
            tr: [],
            eng: []
        };

        var shippingPrice = {};
        var normalPrice = {};
        var discount = {};
        var discountedPrice = {};

        let productDetails = data[14].value;
        let productDetailsEng = data[15].value;

        let prices = data[16].value;
        let shippingPrices = data[17].value;

        // fill details

        for (let item of productDetails.features) {
            features.tr.push(item.value);
        }

        for (let item of productDetailsEng.features) {
            features.eng.push(item.value);
        }


        for (let item of productDetails.materials) {
            materials.tr.push(item.value);
        }

        for (let item of productDetailsEng.materials) {
            materials.eng.push(item.value);
        }

        for (let item of productDetails.sizeAndFit) {
            sizeAndFit.tr.push(item.value);
        }

        for (let item of productDetailsEng.sizeAndFit) {
            sizeAndFit.eng.push(item.value);
        }

        // fill shipping prices
        for (let item of shippingPrices) {
            shippingPrice[item.currency] = Number(item.price);
        }

        // fill prices
        for (let item of prices) {
            normalPrice[item.currency] = Number(item.normalPrice);
            discountedPrice[item.currency] = Number(item.discountedPrice);
            discount[item.currency] = Number(item.discount);
        }

        let stockData = data[9].value;
        let formattedStockData = [];

        for (let item of stockData) {
            var obj = {
                color: item.color,
                sizes: item.selectedSizes,
                images: item.images
            }

            formattedStockData.push(obj);
        }

        fetch(globalURL + "/product/create", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                product: {
                    productName: {
                        tr: data[0].value,
                        eng: data[4].value,
                    },
                    productTitle: {
                        tr: data[1].value,
                        eng: data[5].value,
                    },
                    description: {
                        tr: data[2].value,
                        eng: data[6].value
                    },
                    productCode: data[3].value,
                    category: data[7].value,
                    combinedWith: data[8].value,
                    stockData: formattedStockData,
                    hasDiscount: data[10].value,
                    discountType: data[11].value,
                    freeShipping: !data[12].value,
                    isOpenForSale: data[13].value,
                    features: features,
                    materials: materials,
                    sizeAndFit: sizeAndFit,
                    normalPrice: normalPrice,
                    discount: discount,
                    discountedPrice: discountedPrice,
                    shippingPrice: shippingPrice
                }
            })
        })
            .then((result) => result.json())
            .then(async (insert) => {
                if (insert.success) {
                    dispatch({
                        type: "FETCH_INSERT_PRODUCTS",
                        payload: insert
                    });
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "Ürün başarıyla sisteme eklendi.",
                            type: "success"
                        }
                    });
                } else {
                    dispatch({
                        type: "ERROR_INSERT_PRODUCTS",
                        payload: insert
                    });
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: insert.errorMessage,
                            type: "danger"
                        }
                    });
                }

            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "ERROR_INSERT_PRODUCTS",
                    payload: error
                });
            });

    } else {
        dispatch({
            type: "TOGGLE_APP_ALERT",
            payload: {
                show: true,
                message: "Lütfen Gerekli Tüm Alanları Doldurun.",
                type: "error"
            }
        });
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_PRODUCT",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        });
    }
}

const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_PRODUCT"
        })
        fetch(globalURL + "/product/getDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_PRODUCT",
                        payload: detail,
                    })
                    dispatch({
                        type: "TOGGLE_PRODUCT_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_PRODUCT",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_PRODUCT",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_PRODUCT_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const update = (token, id, data, rowIndex) => dispatch => {
    dispatch({
        type: "GET_UPDATE_PRODUCT"
    });

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // update

        var features = {
            tr: [],
            eng: []
        };

        var materials = {
            tr: [],
            eng: []
        };

        var sizeAndFit = {
            tr: [],
            eng: []
        };

        var shippingPrice = {};
        var normalPrice = {};
        var discount = {};
        var discountedPrice = {};

        let productDetails = data[14].value;
        let productDetailsEng = data[15].value;

        let prices = data[16].value;
        let shippingPrices = data[17].value;

        // fill details

        for (let item of productDetails.features) {
            features.tr.push(item.value);
        }

        for (let item of productDetailsEng.features) {
            features.eng.push(item.value);
        }


        for (let item of productDetails.materials) {
            materials.tr.push(item.value);
        }

        for (let item of productDetailsEng.materials) {
            materials.eng.push(item.value);
        }

        for (let item of productDetails.sizeAndFit) {
            sizeAndFit.tr.push(item.value);
        }

        for (let item of productDetailsEng.sizeAndFit) {
            sizeAndFit.eng.push(item.value);
        }

        // fill shipping prices
        for (let item of shippingPrices) {
            shippingPrice[item.currency] = Number(item.price);
        }

        // fill prices
        for (let item of prices) {
            normalPrice[item.currency] = Number(item.normalPrice);
            discountedPrice[item.currency] = Number(item.discountedPrice);
            discount[item.currency] = Number(item.discount);
        }

        let stockData = data[9].value;
        let formattedStockData = [];

        for (let item of stockData) {
            var obj = {
                color: item.color,
                sizes: item.selectedSizes,
                images: item.images
            }

            formattedStockData.push(obj);
        }

        fetch(globalURL + "/product/update", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                product: {
                    productName: {
                        tr: data[0].value,
                        eng: data[4].value,
                    },
                    productTitle: {
                        tr: data[1].value,
                        eng: data[5].value,
                    },
                    description: {
                        tr: data[2].value,
                        eng: data[6].value
                    },
                    productCode: data[3].value,
                    category: data[7].value,
                    combinedWith: data[8].value,
                    stockData: formattedStockData,
                    hasDiscount: data[10].value,
                    discountType: data[11].value,
                    freeShipping: !data[12].value,
                    isOpenForSale: data[13].value,
                    features: features,
                    materials: materials,
                    sizeAndFit: sizeAndFit,
                    normalPrice: normalPrice,
                    discount: discount,
                    discountedPrice: discountedPrice,
                    shippingPrice: shippingPrice,
                    newProduct : data[18].value
                }
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_PRODUCT",
                        payload: update,
                        rowIndex: rowIndex
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " ürünü başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    if (update.field) {
                        dispatch({
                            type: "SET_VALUE_ERROR_PRODUCT",
                            payload: {
                                key: update.field,
                                helpertext: update.errorMessage
                            }
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_UPDATE_PRODUCT",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_UPDATE_PRODUCT",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_PRODUCT",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const deleteEntity = (token, row, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_PRODUCT"
    })
    fetch(globalURL + "/product/delete", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id,
            name: row.productName.tr
        })
    })
        .then((result) => result.json())
        .then(async (deleteRecord) => {
            if (deleteRecord.success) {
                dispatch({
                    type: "FETCH_DELETE_PRODUCT",
                    payload: deleteRecord,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: row.productName.tr + " ürünü başarıyla silindi.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_DELETE_PRODUCT",
                    payload: deleteRecord
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_DELETE_PRODUCT",
                payload: error
            })
        })
}



export {
    toggleModal,
    setValue,
    getAll,
    createNew,
    update,
    tableActions,
    deleteEntity,
    addPriceRow,
    removePriceRow,
    addDetailsRow,
    removeDetailsRow,
    setDetailsData,
    addShippingPriceRow,
    removeShippingPriceRow,
    addStockDataRow,
    removeStockDataRow,
    setStockData
}