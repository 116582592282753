import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";
import io from 'socket.io-client';


const connectToSocket = (token) => dispatch => {
    // var socket = io(globalURL, {
    //     transportOptions: {
    //         polling: {
    //             extraHeaders: {
    //                 'Authorization': token
    //             }
    //         }
    //     }
    // });

    // socket.on('connect', async () => {
    //     console.log("socket e bağlandınız! socket-id : " + socket.id);

    //     fetch(globalURL + "/socket/connect", {
    //         method: 'POST',
    //         headers: {
    //             ...fetchApiHeaders.POST,
    //             token: token
    //         },
    //         body: JSON.stringify({
    //             socketId: socket.id
    //         })
    //     })
    //         .then((result) => result.json())
    //         .then((socketResult) => {
    //             if (socketResult.success) {
    //                 dispatch({
    //                     type: "SET_SOCKET",
    //                     payload: socket
    //                 });
    //             } else {
    //                 console.log("socketID gönderilemedi!")
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // });

    // socket.on("message", (message) => {
    //     dispatch({
    //         type: "TOGGLE_APP_ALERT",
    //         payload: {
    //             show: true,
    //             message: message,
    //             type: "info"
    //         }
    //     })
    // })
}

const userLogin = (data) => dispatch => {
    dispatch({
        type: "GET_LOGIN"
    })

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // login
        fetch(globalURL + "/auth/login", {
            method: 'POST',
            headers: fetchApiHeaders.POST,
            body: JSON.stringify({
                username: data[0].value,
                password: data[1].value
            })
        })
            .then((result) => result.json())
            .then(async (login) => {
                if (login.success) {
                    dispatch({
                        type: "FETCH_LOGIN"
                    })
                    dispatch({
                        type: "AUTH_USER",
                        payload: login.data
                    })
                } else {
                    dispatch({
                        type: "ERROR_LOGIN",
                        payload: login
                    })

                    dispatch({
                        type: "SET_VALUE_ERROR_LOGIN",
                        payload: {
                            key: "username",
                            helpertext: "Kullanıcı Adınızı Kontrol Ediniz"
                        }
                    })

                    dispatch({
                        type: "SET_VALUE_ERROR_LOGIN",
                        payload: {
                            key: "password",
                            helpertext: "Şifrenizi Kontrol Ediniz"
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: "ERROR_LOGIN",
                    payload: error
                })
            })
    } else {
        // null inputs
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_LOGIN",
                payload: {
                    ...item,
                    helpertext: item.key === "username" ? "Lütfen Kullanıcı Adınızı Giriniz" : "Lütfen Şifenizi Giriniz"
                }
            })
        })
    }
}


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_LOGIN",
        payload: {
            key: key,
            value: value
        }
    })
}


const logOut = (token) => dispatch => {
    fetch(globalURL + "/auth/logout", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
    })
        .then((result) => result.json())
        .then(async (logout) => {
            if (logout.success) {
                dispatch({
                    type: "LOG_OUT"
                })
            } else {
                dispatch({
                    type: "TOGGLE_LOGIN_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: "TOGGLE_LOGIN_ALERT",
                payload: {
                    show: true,
                    message: "Bir hata oluştu.",
                    type: "error"
                }
            });
        })

}

export {
    userLogin,
    logOut,
    setValue,
    connectToSocket
}