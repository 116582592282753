import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";


const toggleModalDistrict = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_DISTRICT_MODAL",
        payload: {
            show: show,
            process: process
        }
    })

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}

const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_ADDRESS",
        payload: {
            key: key,
            value: value
        }
    })
}

const toggleModalStreet = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_STREET_MODAL",
        payload: {
            show: show,
            process: process
        }
    })

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}

const loadLocations = (token) => dispatch => {
    dispatch({
        type: "GET_LOAD_ADDRESS"
    })
    fetch(globalURL + "/address/loadLocations", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (cities) => {
            if (cities.success) {
                let data = [];
                for (let city of cities.data) {
                    var obj = {
                        label: city.cityName,
                        value: city.id
                    }
                    data.push(obj)
                }
                dispatch({
                    type: "FETCH_LOAD_ADDRESS",
                    payload: data
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "Adres bilgileri başarıyla sisteme yüklendi.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: cities
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ADDRESS",
                payload: error
            });
        })
}

const getAllCities = (token) => dispatch => {
    dispatch({
        type: "GET_ADDRESS"
    })
    fetch(globalURL + "/address/getAllCities", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (cities) => {
            if (cities.success) {
                let data = [];
                for (let city of cities.data) {
                    var obj = {
                        label: city.cityName,
                        value: city.id
                    }
                    data.push(obj)
                }
                dispatch({
                    type: "FETCH_ADDRESS",
                    payload: data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: cities
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ADDRESS",
                payload: error
            });
        })
}

const getAllDistricts = (token, id) => dispatch => {
    dispatch({
        type: "GET_DISTRICTS"
    })
    fetch(globalURL + "/address/getAllDistricts", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: id
        })
    })
        .then((result) => result.json())
        .then(async (districts) => {
            if (districts.success) {
                dispatch({
                    type: "FETCH_DISTRICTS",
                    payload: districts
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: districts
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ADDRESS",
                payload: error
            });
        })
}

const getAllStreets = (token, row) => dispatch => {
    dispatch({
        type: "SET_VALUE_ADDRESS",
        payload: {
            key: "selectedDistrict",
            value: {
                label: row.districtName,
                value: row.id
            }
        }
    })
    dispatch({
        type: "GET_STREETS"
    })
    fetch(globalURL + "/address/getAllStreets", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id
        })
    })
        .then((result) => result.json())
        .then(async (streets) => {
            if (streets.success) {
                dispatch({
                    type: "FETCH_STREETS",
                    payload: streets
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: streets
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ADDRESS",
                payload: error
            });
        })
}

const districtActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_DISTRICT"
        })
        fetch(globalURL + "/address/getDistrictDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_DISTRICT",
                        payload: detail
                    })
                    dispatch({
                        type: "TOGGLE_DISTRICT_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    });
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_DISTRICT",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_DISTRICT",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_DISTRICT_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const streetActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_STREET"
        })
        fetch(globalURL + "/address/getStreetDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_STREET",
                        payload: detail
                    })
                    dispatch({
                        type: "TOGGLE_STREET_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_STREET",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_STREET",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_STREET_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const updateDistrict = (token, id, data, rowIndex, cityId) => dispatch => {
    dispatch({
        type: "GET_UPDATE_DISTRICT"
    });

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // update
        fetch(globalURL + "/address/updateDistrict", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                name: data[0].value,
                code: data[1].value,
                city: cityId
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_DISTRICT",
                        payload: update,
                        rowIndex: rowIndex
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " ilçesi başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    if (update.field) {
                        update.field.map((item, i) => {
                            return dispatch({
                                type: "SET_VALUE_ERROR_ADDRESS",
                                payload: {
                                    key: item.field,
                                    helpertext: item.errorMessage
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_UPDATE_ADDRESS",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_UPDATE_ADDRESS",
                    payload: error
                })
            })


    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_ADDRESS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const deleteDistrict = (token, row, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_DISTRICT"
    });

    fetch(globalURL + "/address/deleteDistrict", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id,
            name: row.districtName
        })
    })
        .then((result) => result.json())
        .then(async (deleteRecord) => {
            if (deleteRecord.success) {
                dispatch({
                    type: "FETCH_DELETE_DISTRICT",
                    payload: deleteRecord,
                    rowIndex: rowIndex,
                    id: row.id
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem başarıyla gerçekleşti.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: deleteRecord
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_ADDRESS",
                payload: error
            })
        })
}

const deleteStreet = (token, row, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_STREET"
    });

    fetch(globalURL + "/address/deleteStreet", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id,
            name: row.streetName
        })
    })
        .then((result) => result.json())
        .then(async (deleteRecord) => {
            if (deleteRecord.success) {
                dispatch({
                    type: "FETCH_DELETE_STREET",
                    payload: deleteRecord,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem başarıyla gerçekleşti.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: deleteRecord
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_ADDRESS",
                payload: error
            })
        })
}

const createNewDistrict = (token, data, cityId) => dispatch => {
    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // create
        dispatch({
            type: "GET_CREATE_DISTRICT"
        });
        fetch(globalURL + "/address/createDistrict", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                name: data[0].value,
                code: data[1].value,
                city: cityId
            })
        })
            .then((result) => result.json())
            .then(async (create) => {
                if (create.success) {
                    dispatch({
                        type: "FETCH_CREATE_DISTRICT",
                        payload: create
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " ilçesi başarıyla eklendi.",
                            type: "success"
                        }
                    });
                } else {
                    if (create.field) {
                        create.field.map((item, i) => {
                            return dispatch({
                                type: "SET_VALUE_ERROR_ADDRESS",
                                payload: {
                                    key: item.field,
                                    helpertext: item.errorMessage
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_ADDRESS",
                        payload: create
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: error
                })
            })
    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_ADDRESS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const createNewStreet = (token, data, districtId) => dispatch => {
    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // create
        dispatch({
            type: "GET_CREATE_STREET"
        });
        fetch(globalURL + "/address/createStreet", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                name: data[0].value,
                code: data[1].value,
                district: districtId
            })
        })
            .then((result) => result.json())
            .then(async (create) => {
                if (create.success) {
                    dispatch({
                        type: "FETCH_CREATE_STREET",
                        payload: create
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " mahallesi başarıyla eklendi.",
                            type: "success"
                        }
                    });
                } else {
                    if (create.field) {
                        create.field.map((item, i) => {
                            return dispatch({
                                type: "SET_VALUE_ERROR_ADDRESS",
                                payload: {
                                    key: item.field,
                                    helpertext: item.errorMessage
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_ADDRESS",
                        payload: create
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ADDRESS",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_ADDRESS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const updateStreet = (token, id, data, rowIndex, districtId) => dispatch => {
    dispatch({
        type: "GET_UPDATE_STREET"
    });

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // update
        fetch(globalURL + "/address/updateStreet", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                name: data[0].value,
                code: data[1].value,
                district: districtId
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_STREET",
                        payload: update,
                        rowIndex: rowIndex
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " mahallesi başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    if (update.field) {
                        update.field.map((item, i) => {
                            return dispatch({
                                type: "SET_VALUE_ERROR_ADDRESS",
                                payload: {
                                    key: item.field,
                                    helpertext: item.errorMessage
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_UPDATE_ADDRESS",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_UPDATE_ADDRESS",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_ADDRESS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}


export {
    getAllCities,
    loadLocations,
    toggleModalDistrict,
    toggleModalStreet,
    streetActions,
    districtActions,
    setValue,
    getAllDistricts,
    getAllStreets,
    updateDistrict,
    updateStreet,
    createNewDistrict,
    createNewStreet,
    deleteDistrict,
    deleteStreet
}