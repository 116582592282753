import React from "react";
import { connect } from 'react-redux';
import {
  setValue,
  userLogin
} from "../../../../redux/actions/loginActions";
import {
  TextField
} from "@material-ui/core";

function Login(props) {
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center">
        <h3 className="font-size-h1">
          PANEL GİRİŞ
        </h3>
      </div>
      <form className="form">
        <TextField
          onChange={(event) => props.setValue("username", event.target.value)}
          value={props.login.username}
          error={props.login.username_error}
          label="Kullanıcı Adı"
          type="text"
          name="text"
          autoComplete="off"
          margin="normal"
          variant="outlined"
          placeholder="Kullanıcı Adı"
          FormHelperTextProps={{
            style: {
              marginLeft: 0,
              marginBottom: -10
            }
          }}
          helperText={props.login.username_helpertext}
        />
        <TextField
          error={props.login.password_error}
          onChange={(event) => props.setValue("password", event.target.value)}
          value={props.login.password}
          label="Şifre"
          type="password"
          name="password"
          autoComplete="off"
          margin="normal"
          variant="outlined"
          placeholder="Şifre"
          helperText={props.login.password_helpertext}
          FormHelperTextProps={{
            style: {
              marginLeft: 0
            }
          }}
        />
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            disabled={props.login.isFetching}
            onClick={() => props.userLogin([
              {
                key: 'username',
                value: props.login.username.trim(),
                required: true
              },
              {
                key: 'password',
                value: props.login.password.trim(),
                required: true
              }
            ])}
            type="button"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Giriş Yap</span>
            {props.login.isFetching && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}



const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    login: state.login
  }
}

export default connect(mapStateToProps,
  {
    setValue,
    userLogin
  })(Login);
