const initialState = {
    data: null,
    isFetching: false,
    password: "",
    repassword: "",
    repassword_helpertext: "",
    password_helpertext: "",
    repassword_error: false,
    password_error: false,
    remember: false,
    error: false,
    userCreated: false,
    alert: {
        show: false,
        message: "",
        type: ""
    }
   
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_CREATE_PASSWORD":
            return {
                ...state,
                isFetching: true,
                userCreated: false,
                error: false
            }

        case "FETCH_CREATE_PASSWORD":
            return {
                ...state,
                isFetching: false,
                userCreated: true
            }
        case "SET_VALUE_CREATE_PASSWORD":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CREATE_PASSWORD":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helperText
            }
        case "ERROR_CREATE_PASSWORD":
            return {
                ...state,
                isFetching: false,
                userCreated: false,
                error: true
            }
        case "TOGGLE_CREATE_PASSWORD_ALERT":
            return {
                ...state,
                alert: {
                    ...state.alert,
                    ...action.payload
                }
            }
        default:
            return state
    }

}
