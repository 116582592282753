import { globalURL, fetchApiHeaders } from "../../app/constants/constants";

const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_STOCK_MANAGEMENT",
        payload: {
            key: key,
            value: value
        }
    })
}

const toggleModal = (show) => dispatch => {
    dispatch({
        type: "TOGGLE_STOCK_MANAGEMENT_MODAL",
        payload: {
            show: show
        }
    })
    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}

const tableActions = (id, token) => dispatch => {
    dispatch({
        type: "GET_DETAIL_STOCK_MANAGEMENT"
    })
    fetch(globalURL + "/stockCard/getDetail", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: id
        })
    })
        .then((result) => result.json())
        .then(async (detail) => {
            if (detail.success) {
                dispatch({
                    type: "FETCH_DETAIL_STOCK_MANAGEMENT",
                    payload: detail
                })
                dispatch({
                    type: "TOGGLE_STOCK_MANAGEMENT_MODAL",
                    payload: {
                        show: true
                    }
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_DETAIL_STOCK_MANAGEMENT",
                    payload: detail
                })
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_DETAIL_STOCK_MANAGEMENT",
                payload: error
            })
        })
}

const getAllStockCards = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_STOCK_CARDS"
    })

    Promise.all([
        fetch(globalURL + "/stockCard/getAll", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            }
        }),
        fetch(globalURL + "/stockCard/getStocksByProduct", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            }
        })

    ])
        .then(async (data) => {
            let stockCards = await data[0].json();
            let stockCardsByProduct = await data[1].json();
            if (stockCards.success) {
                dispatch({
                    type: "FETCH_ALL_STOCK_CARDS",
                    payload: stockCards.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_ALL_STOCK_CARDS",
                    payload: stockCards
                })
            }

            if (stockCardsByProduct.success) {
                dispatch({
                    type: "ALL_STOCK_CARDS_BY_PRODUCT",
                    payload: stockCardsByProduct.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_ALL_STOCK_CARDS",
                    payload: stockCardsByProduct
                })
            }

        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_ALL_STOCK_CARDS",
                payload: error
            })
        })
}

const updateStocks = (token, id, quantity, rowIndex, row) => dispatch => {
    if (Number(quantity) > 0) {
        dispatch({
            type: "GET_UPDATE_STOCKS"
        })
        fetch(globalURL + "/stock/updateStocks", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                quantity: quantity
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    let stockCardsByProduct = await fetch(globalURL + "/stockCard/getStocksByProduct", {
                        method: 'POST',
                        headers: {
                            ...fetchApiHeaders.POST,
                            token: token
                        }
                    });
                    let stockCardsByProductJson = await stockCardsByProduct.json();
                    if (stockCardsByProductJson.success) {
                        dispatch({
                            type: "ALL_STOCK_CARDS_BY_PRODUCT",
                            payload: stockCardsByProductJson.data
                        })
                    }

                    dispatch({
                        type: "FETCH_UPDATE_STOCKS",
                        payload: update,
                        rowIndex: rowIndex
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: row.stockCode + " stok kodlu ürünün stok adedi " + quantity + " olarak güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    dispatch({
                        type: "ERROR_UPDATE_STOCKS",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: "ERROR_UPDATE_STOCKS",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "SET_VALUE_ERROR_STOCK_MANAGEMENT",
            payload: {
                key: "stockQuantity",
                helpertext: "Lütfen Stok Adedi Giriniz"
            }
        })
    }
}

export {
    getAllStockCards,
    tableActions,
    toggleModal,
    setValue,
    updateStocks
}