
const initialState = {
    activity: [],
    dashboardData: null,
    isFetching: false,
    stockCount: 0,
    pendingOrders: 0,
    error: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ACTIVITY":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ACTIVITY":
            return {
                ...state,
                isFetching: false,
                activity: action.payload
            }
        case "FETCH_STOCK_COUNT":
            return {
                ...state,
                isFetching: false,
                stockCount: action.payload
            }
        case "ERROR_ACTIVITY":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "FETCH_PENDING_ORDERS":
            return {
                ...state,
                isFetching: false,
                pendingOrders: action.payload
            }
        case "ERROR_PENDING_ORDERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "FETCH_DASHBOARD":
            return {
                ...state,
                isFetching: false,
                dashboardData: action.payload
            }
        case "ERROR_DASHBOARD":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }

}
