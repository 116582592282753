
const initialState = {
    isFetching: false,
    detail: null,
    stockCards: [],
    stockCardsByProduct: [],
    showModal: false,
    isFetchingActions: false,
    stockQuantity: 0,
    stockQuantity_error: false,
    stockQuantity_helpertext: "",
   
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_DETAIL_STOCK_MANAGEMENT":
            return {
                ...state,
                error: false,
                isFetchingActions: true
            }
        case "FETCH_DETAIL_STOCK_MANAGEMENT":
            return {
                ...state,
                isFetchingActions: false,
                detail: action.payload.data[0],
                stockQuantity: action.payload.data[0].stockCount,
                stockQuantity_error: false,
                stockQuantity_helpertext: ""
            }
        case "ERROR_DETAIL_STOCK_MANAGEMENT":
            return {
                ...state,
                error: true,
                isFetchingActions: false
            }
        case "GET_ALL_STOCK_CARDS":
            return {
                ...state,
                error: false,
                isFetching: true
            }
        case "FETCH_ALL_STOCK_CARDS":
            return {
                ...state,
                isFetching: false,
                stockCards: action.payload
            }
        case "ALL_STOCK_CARDS_BY_PRODUCT":
            return {
                ...state,
                stockCardsByProduct: action.payload
            }

     
        case "TOGGLE_STOCK_MANAGEMENT_MODAL":
            return {
                ...state,
                showModal: action.payload.show
            }
        case "ERROR_ALL_STOCK_CARDS":
            return {
                ...state,
                error: true,
                isFetching: false
            }
        case "SET_VALUE_STOCK_MANAGEMENT":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_STOCK_MANAGEMENT":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }

        case "GET_UPDATE_STOCKS":
            return {
                ...state,
                error: false,
                isFetchingActions: true
            }
        case "FETCH_UPDATE_STOCKS":
            state.stockCards[action.rowIndex] = action.payload.data[0];
            return {
                ...state,
                showModal: false,
                isFetchingActions: false
            }
        case "ERROR_UPDATE_STOCKS":
            return {
                ...state,
                error: true,
                isFetchingActions: false
            }
        default:
            return state
    }

}
