import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import combinedReducers from './reducers/combineReducers';
import { persistStore, persistReducer, createTransform } from "redux-persist";
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import localforage from 'localforage';
import JSOG from 'jsog';

export const JSOGTransform = createTransform(
    (inboundState, key) => JSOG.encode(inboundState),
    (outboundState, key) => JSOG.decode(outboundState),
)

const persistConfig = {
    key: 'root',
    storage : localforage,
    transforms: [JSOGTransform]
}

const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, combinedReducers);

let store = createStore(persistedReducer, applyMiddleware(...middleware))
let persistor = persistStore(store)

export {
    store,
    persistor
}