import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
  toggleModal,
  getAll,
  setValue,
  createNew,
  update,
  deleteEntity,
  tableActions,
  
} from "../../../../../redux/actions/shippingActions";
import {
  Button,
  Modal
} from "react-bootstrap";
import {
  TextField,
  FormHelperText,
  Chip,
  Avatar,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function ShippingsPage(props) {
  useEffect(() => {
    props.getAll(props.auth.user.token);
    return () => {
      // will unMount 
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const columns = [
    {
      dataField: "shippingName",
      text: "Kargo Adı",
      sort: true,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.shipping.data.length > 0 ? "block" : "none"
        }
      }),
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "shippingCode",
      text: "Kargo Kodu",
      sort: true,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.shipping.data.length > 0 ? "block" : "none"
        }
      }),
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "action",
      text: "",
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      formatter: (cell, row) => {
        return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
      }

    }
  ];

  return <>
  <Loading show={props.shipping.isFetchingDetail} />
    <Modal backdrop="static" size="lg" show={props.shipping.showModal} onHide={() => props.toggleModal(false, "")}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i style={{ marginRight: 5 }} className={props.shipping.process === "create" ? "fa fa-plus" : props.shipping.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
          {props.shipping.process === "create" ? "Yeni Kargo" : props.shipping.process === "update" ? "Kargo Düzenle" : "Kargo Sil"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.shipping.process === "create" || props.shipping.process === "update" ?
          <div>
            <TextField
              label="Kargo Adı"
              autoComplete="off"
              style={{ margin: 8 }}
              placeholder="Kargo Adı"
              value={props.shipping.name}
              error={props.shipping.name_error}
              onChange={(event) => props.setValue("name", event.target.value)}
              helperText={props.shipping.name_helpertext}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                style: {
                  marginLeft: 0
                }
              }}
            />
            <TextField
              label="Kargo Kodu"
              style={{ margin: 8 }}
              placeholder="Kargo Kodu"
              autoComplete="off"
              error={props.shipping.code_error}
              value={props.shipping.code}
              onChange={(event) => props.setValue("code", event.target.value)}
              helperText={props.shipping.code_helpertext}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                style: {
                  marginLeft: 0
                }
              }}
            />
            {
              props.shipping.process === "update" && props.shipping.detail !== null ?
                <>
                  <div className="separator separator-dashed my-7"></div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>KARGO DETAYLARI</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        props.shipping.detail.createdBy !== null ?
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Chip
                                style={{ marginLeft: -5 }}
                                avatar={<Avatar>{String(props.shipping.detail.createdBy.name[0]).toUpperCase() + String(props.shipping.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                label={props.shipping.detail.createdBy.username}
                              /><br />
                              <small>{moment(props.shipping.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                              <small>{moment(props.shipping.detail.createdAt).fromNow()}</small>
                            </TableCell>
                          </TableRow> : null
                      }

                      {
                        props.shipping.detail.updatedBy !== undefined && props.shipping.detail.updatedBy !== null ?
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Chip
                                style={{ marginLeft: -5 }}
                                avatar={<Avatar>{String(props.shipping.detail.updatedBy.name[0]).toUpperCase() + String(props.shipping.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                label={props.shipping.detail.updatedBy.username}
                              /><br />
                              <small>{moment(props.shipping.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                              <small>{moment(props.shipping.detail.updatedAt).fromNow()}</small>
                            </TableCell>
                          </TableRow> : null
                      }
                    </TableBody>
                  </Table></> : null
            }
          </div> :
          props.table.row !== null && props.table.row.shippingName ?
            <>
              <b>{'"'}{props.table.row.shippingName}{'" '}</b> isimli kargoyu silmek üzeresiniz. İşlemi onaylıyor musunuz?
         </> : null
        }
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={props.shipping.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.shipping.process)}>
          İptal
            </Button>
        <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.shipping.isFetchingActions} onClick={() => {
          if (props.shipping.process === "create") {
            props.createNew(props.auth.user.token,
              [
                {
                  key: "name",
                  value: props.shipping.name,
                  required: true
                },
                {
                  key: "code",
                  value: props.shipping.code,
                  required: true
                }
              ])
          } else if (props.shipping.process === "update") {
            props.update(props.auth.user.token, props.table.row.id,
              [
                {
                  key: "name",
                  value: props.shipping.name,
                  required: true
                },
                {
                  key: "code",
                  value: props.shipping.code,
                  required: true
                }

              ], props.table.index);
          } else {
            props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
          }
        }} variant="primary">
          {
            props.shipping.isFetchingActions ?
              <span className="ml-3 spinner spinner-white"></span> :
              props.shipping.process === "delete" ? "Sil" : "Kaydet"
          }
        </Button>
      </Modal.Footer>
    </Modal>
    <DataTable
      handleModal={props.toggleModal}
      title="Tüm Anlaşmalı Kargolar"
      buttonTitle="Yeni Kargo"
      cardIcon="fa fa-shipping-fast"
      buttonIcon="fa fa-plus"
      searchPlaceholder="Ara"
      data={props.shipping.data}
      columns={columns}
    />
  </>
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    shipping: state.shipping,
    table: state.table
  }
}

export default connect(mapStateToProps,
  {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,
    
  })(ShippingsPage);
