import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";

const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_ORDER_MODAL",
        payload: {
            show: show,
            process: process
        }
    })

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}


const setValue = (key, value, index) => dispatch => {
    switch (key) {
        case "selectedItem":
            dispatch({
                type: "SET_VALUE_ORDER_SELECTED_ITEM",
                payload: {
                    key: key,
                    value: value,
                    index: index
                }
            })
            break;
        case "selectedQuantity":
            dispatch({
                type: "SET_VALUE_ORDER_SELECTED_QUANTITY",
                payload: {
                    key: key,
                    value: value,
                    index: index
                }
            })
            break;
        default:
            dispatch({
                type: "SET_VALUE_ORDER",
                payload: {
                    key: key,
                    value: value
                }
            })
            break;
    }

}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_ORDERS"
    })
    fetch(globalURL + "/order/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (orders) => {
            if (orders.success) {
                dispatch({
                    type: "FETCH_ALL_ORDERS",
                    payload: orders.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ALL_ORDERS",
                    payload: orders
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ALL_ORDERS",
                payload: error
            })
        })
}

const getWithState = (token, state) => dispatch => {
    dispatch({
        type: "GET_ALL_ORDERS"
    })
    fetch(globalURL + "/order/getWithState", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            state: state
        })
    })
        .then((result) => result.json())
        .then(async (orders) => {
            if (orders.success) {
                dispatch({
                    type: "FETCH_ALL_ORDERS",
                    payload: orders.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ALL_ORDERS",
                    payload: orders
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ALL_ORDERS",
                payload: error
            })
        })
}


const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_ORDER"
        })
        fetch(globalURL + "/order/getDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_ORDER",
                        payload: detail
                    });

                    dispatch({
                        type: "TOGGLE_ORDER_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_ORDER",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_ORDER",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_ORDER_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const update = (token, id, state, orderNumber, rowIndex, cancelReason) => dispatch => {
    dispatch({
        type: "GET_UPDATE_ORDER"
    });
    // update
    fetch(globalURL + "/order/updateOrderState", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: id,
            state: state,
            orderNumber: orderNumber,
            cancelReason: cancelReason
        })
    })
        .then((result) => result.json())
        .then(async (update) => {
            if (update.success) {
                dispatch({
                    type: "FETCH_UPDATE_ORDER",
                    payload: update,
                    rowIndex: rowIndex
                });

                if (state === "canceledByAdmin") {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: orderNumber + " numaralı sipariş başarıyla iptal edildi",
                            type: "success"
                        }
                    });
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: orderNumber + " numaralı sipariş başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                }

            } else {
                if (update.field) {
                    dispatch({
                        type: "SET_VALUE_ERROR_ORDER",
                        payload: {
                            key: update.field,
                            helpertext: update.errorMessage
                        }
                    });
                    dispatch({
                        type: "ERROR_UPDATE_ORDER",
                        payload: update
                    })
                } else {
                    if (update.type && state === "canceledByAdmin") {
                        switch (update.type) {
                            case "refundError":
                                dispatch({
                                    type: "FETCH_UPDATE_ORDER",
                                    payload: update,
                                    rowIndex: rowIndex
                                });
                                dispatch({
                                    type: "TOGGLE_APP_ALERT",
                                    payload: {
                                        show: true,
                                        message: "Sipariş tutarı iade edilemedi. Sipariş detayına bakınız.",
                                        type: "error"
                                    }
                                });
                                break;
                            case "bankError":
                                dispatch({
                                    type: "TOGGLE_APP_ALERT",
                                    payload: {
                                        show: true,
                                        message: update.errorMessage,
                                        type: "error"
                                    }
                                });

                                dispatch({
                                    type: "ERROR_UPDATE_ORDER",
                                    payload: update
                                })
                                break;
                        }

                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });

                        dispatch({
                            type: "ERROR_UPDATE_ORDER",
                            payload: update
                        })
                    }
                }

            }
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_UPDATE_ORDER",
                payload: error
            })
        })
}

const partialRefund = (token, id, items, orderNumber, rowIndex, cancelReason) => dispatch => {
    dispatch({
        type: "GET_UPDATE_ORDER"
    });

    let data = [];
    let errors = [];
    for (let item of items) {
        if (item.canRefund) {
            if (item.selected === true && item.selectedQuantity !== undefined) {
                if (Number(item.selectedQuantity) <= Number(item.maxRefundCount)) {
                    var obj = {
                        id: item.id ? item.id : item._id,
                        selectedQuantity: Number(item.selectedQuantity)
                    };
                    data.push(obj);
                }
            }
        }
    }

    if (errors.length === 0 && data.length > 0) {
        // update
        fetch(globalURL + "/order/partialRefund", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                orderNumber: orderNumber,
                cartItems: data,
                cancelReason
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_ORDER",
                        payload: update,
                        rowIndex: rowIndex
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: orderNumber + " numaralı siparişte seçilen ürünler başarıyla iptal edildi",
                            type: "success"
                        }
                    });

                } else {
                    if (update.field) {
                        dispatch({
                            type: "SET_VALUE_ERROR_ORDER",
                            payload: {
                                key: update.field,
                                helpertext: update.errorMessage
                            }
                        });
                        dispatch({
                            type: "ERROR_UPDATE_ORDER",
                            payload: update
                        })
                    } else {
                        switch (update.type) {
                            case "refundError":
                                dispatch({
                                    type: "FETCH_UPDATE_ORDER",
                                    payload: update,
                                    rowIndex: rowIndex
                                });
                                dispatch({
                                    type: "TOGGLE_APP_ALERT",
                                    payload: {
                                        show: true,
                                        message: "Sipariş tutarı iade edilemedi. Sipariş detayına bakınız.",
                                        type: "error"
                                    }
                                });
                                break;
                            case "bankError":
                                dispatch({
                                    type: "TOGGLE_APP_ALERT",
                                    payload: {
                                        show: true,
                                        message: update.errorMessage,
                                        type: "error"
                                    }
                                });

                                dispatch({
                                    type: "ERROR_UPDATE_ORDER",
                                    payload: update
                                })
                                break;
                            default:
                                dispatch({
                                    type: "ERROR_UPDATE_ORDER",
                                    payload: update
                                })
                                break;
                        }

                    }

                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_UPDATE_ORDER",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_APP_ALERT",
            payload: {
                show: true,
                message: "Lütfen iade için seçimlerinizi kontrol ediniz",
                type: "error"
            }
        });

        dispatch({
            type: "ERROR_UPDATE_ORDER",
            payload: {
                errors: errors,
                data: data
            }
        })
    }
}



export {
    toggleModal,
    setValue,
    getAll,
    update,
    tableActions,
    getWithState,
    partialRefund
}