import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,

} from "../../../../../redux/actions/categoryActions";
import {
    Button,
    Modal,
    Badge
} from "react-bootstrap";
import {
    TextField,
    FormControlLabel,
    FormHelperText,
    Switch,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import Loading from "../../../../helpers/loading/Loading";
import FileUpload from "../../../../helpers/fileupload/FileUpload";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';
import { globalURL } from "../../../../constants/constants";

function CategoriesPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "image.name",
            text: "RESİM",
            sort: false,
            filter: false,
            formatter: (cell) => {
                return cell ? <img alt={cell} src={globalURL + "/images/" + cell} style={{ height: 50, width: 90 }} /> :
                    <div style={{ height: 50, width: 90, display: "flex", justifyContent: "center", alignItems: "center" }}>Resimsiz Kategori</div>
            },
        },
        {
            dataField: "categoryName.tr",
            text: "Kategori Adı (TR)",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.category.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "categoryCode",
            text: "Kategori Kodu",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.category.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "isActive",
            text: "Durum",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.category.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses,
            filterValue: (cell, row) => {
                return cell === true ? "aktif" : "pasif";
            },
            formatter: (cell) => {
                return cell === true ? <Badge variant="success" className="mr-1">
                    aktif
                </Badge> : <Badge variant="warning" className="mr-1">
                    pasif
                </Badge>
            },
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.category.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.category.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.category.process === "create" ? "fa fa-plus" : props.category.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.category.process === "create" ? "Yeni Kategori" : props.category.process === "update" ? "Kategori Detay / Düzenle" : "Kategori Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.category.process === "create" || props.category.process === "update" ?
                    <div>
                        <TextField
                            label="Kategori Adı"
                            placeholder="Kategori Adı"
                            autoComplete="off"
                            error={props.category.name_error}
                            value={props.category.name}
                            onChange={(event) => props.setValue("name", event.target.value)}
                            helperText={props.category.name_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Category Name"
                            placeholder="Category Name"
                            autoComplete="off"
                            error={props.category.name_eng_error}
                            value={props.category.name_eng}
                            onChange={(event) => props.setValue("name_eng", event.target.value)}
                            helperText={props.category.name_eng_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Kategori Kodu"
                            autoComplete="off"
                            placeholder="Kategori Kodu"
                            error={props.category.code_error}
                            value={props.category.code}
                            onChange={(event) => props.setValue("code", event.target.value)}
                            helperText={props.category.code_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <div style={{ marginTop: 15 }}>
                            <FileUpload
                                {...props}
                                multiple={false}
                                dropzoneText={"Kategori İçin Resim Seçiniz"}
                                stateKey={"images"}
                                uploadedImages={props.category.images}
                            />
                        </div>
                        {
                            props.category.process === "update" && props.category.detail !== null ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>KATEGORİ DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <FormHelperText>Kategori Durumu</FormHelperText>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        style={{ marginLeft: -12 }}
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                checked={props.category.isActive}
                                                                onChange={(event) => props.setValue("isActive", event.target.checked)}
                                                                value={props.category.isActive}
                                                            />
                                                        }
                                                        label={props.category.isActive ? "AKTİF" : "PASİF"}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {
                                                props.category.detail.createdBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.category.detail.createdBy.name[0]).toUpperCase() + String(props.category.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.category.detail.createdBy.username}
                                                            />
                                                            <br />
                                                            <small>{moment(props.category.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.category.detail.createdAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null

                                            }

                                            {
                                                props.category.detail.updatedBy !== undefined && props.category.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.category.detail.updatedBy.name[0]).toUpperCase() + String(props.category.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.category.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.category.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.category.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }

                    </div> : props.table.row !== null && props.table.row.categoryName ?
                        <>
                            <b>{'"'}{props.table.row.categoryName.tr}{'" '}</b> isimli kategoriyi silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.category.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.category.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.category.isFetchingActions} onClick={() => {
                    if (props.category.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "name",
                                    value: props.category.name,
                                    required: true
                                },
                                {
                                    key: "code",
                                    value: props.category.code,
                                    required: true
                                },
                                {
                                    key: "images",
                                    value: props.category.images,
                                    required: false
                                },
                                {
                                    key: "name_eng",
                                    value: props.category.name_eng,
                                    required: true
                                }
                            ]);
                    } else if (props.category.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "name",
                                    value: props.category.name,
                                    required: true
                                },
                                {
                                    key: "code",
                                    value: props.category.code,
                                    required: true
                                },
                                {
                                    key: "isActive",
                                    value: props.category.isActive,
                                    required: false
                                },
                                {
                                    key: "images",
                                    value: props.category.images,
                                    required: false
                                },
                                {
                                    key: "name_eng",
                                    value: props.category.name_eng,
                                    required: true
                                }

                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.category.isFetchingActions ?
                            <span className="spinner spinner-white"></span> :
                            props.category.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Kategorilar"
            buttonTitle="Yeni Kategori"
            cardIcon="fa fa-list-ol"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.category.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        category: state.category,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        tableActions,
        update,
        deleteEntity,

    })(CategoriesPage);
