import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    images: [],
    images_helpertext: "",
    images_error: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_IMAGE_SLIDERS":
            return {
                ...state,
                isFetching: true,
                showModal: false,
                process: "",
                error: false
            }
        case "FETCH_ALL_IMAGE_SLIDERS":
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: action.payload
            }
        case "ERROR_ALL_IMAGE_SLIDERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_IMAGE_SLIDER_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                images_helpertext: "",
                images_error: false,
                images: action.payload.process === "create" ? [] : state.images
            }
        case "SET_VALUE_IMAGE_SLIDER":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_IMAGE_SLIDER":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_INSERT_IMAGE_SLIDERS":
            return {
                ...state,
                isFetching: true,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_INSERT_IMAGE_SLIDERS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                showModal: false,
                data: data
            }
        case "ERROR_INSERT_IMAGE_SLIDERS":
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                error: true
            }
        case "GET_DETAIL_IMAGE_SLIDER":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_IMAGE_SLIDER":
            let imagesArray = [];
            for (let image of action.payload.data.images) {
                imagesArray.push(globalURL + "/images/" + image.name)
            }
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                images: imagesArray
            }
        case "ERROR_DETAIL_IMAGE_SLIDER":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_IMAGE_SLIDER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_IMAGE_SLIDER":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_UPDATE_IMAGE_SLIDER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_IMAGE_SLIDER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_IMAGE_SLIDER":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_IMAGE_SLIDER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
