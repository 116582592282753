import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    name: "",
    name_eng: "",
    code: "",
    isActive: false,
    images: [],
    images_helpertext: "",
    images_error: "",
    name_helpertext: "",
    name_eng_helpertext: "",
    code_helpertext: "",
    name_error: false,
    name_eng_error: false,
    isActive_helpertext: "",
    isActive_error: false,
    code_error: false,


}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_CATEGORYS":
            return {
                ...state,
                isFetching: true,
                showModal : false,
                process : "",
                error: false
            }
        case "FETCH_ALL_CATEGORYS":
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: action.payload
            }
        case "ERROR_ALL_CATEGORYS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_CATEGORY_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                name: action.payload.process === "update" ? state.name : "",
                name_eng: action.payload.process === "update" ? state.name_eng : "",
                code: action.payload.process === "update" ? state.code : "",
                name_helpertext: "",
                name_eng_helpertext: "",
                code_helpertext: "",
                name_error: false,
                name_eng_error: false,
                code_error: false,
                images: action.payload.process === "create" ? [] : state.images
            }
        case "SET_VALUE_CATEGORY":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CATEGORY":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_INSERT_CATEGORYS":
            return {
                ...state,
                isFetching: true,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_INSERT_CATEGORYS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                showModal: false,
                data: data,
                name: "",
                name_eng: "",
                code: ""
            }
        case "ERROR_INSERT_CATEGORYS":
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                error: true
            }
        case "GET_DETAIL_CATEGORY":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_CATEGORY":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                name: action.payload.data.categoryName.tr,
                name_eng: action.payload.data.categoryName.eng,
                code: action.payload.data.categoryCode,
                isActive: action.payload.data.isActive,
                images: action.payload.data.image !== null ? [globalURL + "/images/" + action.payload.data.image.name] : []
            }
        case "ERROR_DETAIL_CATEGORY":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_CATEGORY":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_CATEGORY":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                name: "",
                name_eng: "",
                code: "",
                isActive: false,
                name_helpertext: "",
                name_eng_helpertext: "",
                code_helpertext: "",
                name_error: false,
                name_eng_error: false,
                isActive_helpertext: "",
                isActive_error: false,
                code_error: false
            }
        case "ERROR_UPDATE_CATEGORY":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_CATEGORY":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_CATEGORY":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_CATEGORY":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
