const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    title: "",
    content: "",
    type: "",
    title_helpertext: "",
    content_helpertext: "",
    type_helpertext: "",
    title_error: false,
    content_error: false,
    type_error: false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_TEMPLATES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_TEMPLATES":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_TEMPLATES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_TEMPLATES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_TEMPLATES":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data,
                title: "",
                content: "",
                type: ""
            }
        case "ERROR_INSERT_TEMPLATES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_TEMPLATE_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                title: action.payload.process === "update" ? state.title : "",
                content: action.payload.process === "update" ? state.content : "",
                type: action.payload.process === "update" ? state.type : "",
                title_helpertext: "",
                content_helpertext: "",
                type_helpertext: "",
                title_error: false,
                content_error: false,
                type_error: false
            }
        case "SET_VALUE_TEMPLATE":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_TEMPLATE":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_TEMPLATE":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_TEMPLATE":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                title: action.payload.data.title,
                content: action.payload.data.content,
                type: action.payload.data.type
            }
        case "ERROR_DETAIL_TEMPLATE":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_TEMPLATE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_TEMPLATE":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                title: "",
                content: "",
                type: "",
                title_helpertext: "",
                content_helpertext: "",
                type_helpertext: "",
                title_error: false,
                content_error: false,
                type_error: false
            }
        case "ERROR_UPDATE_TEMPLATE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_TEMPLATE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_TEMPLATE":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_TEMPLATE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
