import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions
} from "../../../../../redux/actions/bannerActions";
import {
    Button,
    Modal,
    Badge,
    Tabs,
    Tab
} from "react-bootstrap";
import {
    TextField,
    FormControlLabel,
    FormHelperText,
    Switch,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import { globalURL } from "../../../../constants/constants";
import Loading from "../../../../helpers/loading/Loading";
import FileUpload from "../../../../helpers/fileupload/FileUpload";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';


function BannersPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "image.name",
            text: "Resim",
            sort: false,
            filter: null,
            formatter: (cell) => {
                return cell ? <img alt={cell} src={globalURL + "/images/" + cell} style={{ height: 50, width: 90 }} /> :
                    <div style={{ height: 50, width: 90, display: "flex", justifyContent: "center", alignItems: "center" }}>Resimsiz Banner</div>
            }
        },
        {
            dataField: "title.tr",
            text: "Başlık (TR)",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.banner.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "description.tr",
            text: "Açıklama (TR)",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.banner.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "order",
            text: "Sıra",
            sort: true,
            style: {
                width: "100px",
            },
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.banner.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "isActive",
            text: "Durum",
            sort: true,
            style: {
                width: "100px",
            },
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.banner.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses,
            filterValue: (cell, row) => {
                return cell === true ? "aktif" : "pasif";
            },
            formatter: (cell) => {
                return cell === true ? <Badge variant="success" className="mr-1">
                    aktif
                </Badge> : <Badge variant="warning" className="mr-1">
                    pasif
                </Badge>
            },
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.banner.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.banner.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.banner.process === "create" ? "fa fa-plus" : props.banner.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.banner.process === "create" ? "Yeni Banner" : props.banner.process === "update" ? "Banner Detay / Düzenle" : "Banner Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.banner.process === "create" || props.banner.process === "update" ?
                    <div>
                        <Tabs defaultActiveKey="tr" id="uncontrolled-tab-example">
                            <Tab eventKey="tr" title="Türkçe">
                                <TextField
                                    label="Başlık"
                                    placeholder="Başlık"
                                    autoComplete="off"
                                    error={props.banner.title_error}
                                    value={props.banner.title}
                                    onChange={(event) => props.setValue("title", event.target.value)}
                                    helperText={props.banner.title_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Açıklama"
                                    autoComplete="off"
                                    placeholder="Açıklama"
                                    error={props.banner.description_error}
                                    value={props.banner.description}
                                    onChange={(event) => props.setValue("description", event.target.value)}
                                    helperText={props.banner.description_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Alt Text"
                                    autoComplete="off"
                                    placeholder="Alt Text"
                                    error={props.banner.text_error}
                                    value={props.banner.text}
                                    onChange={(event) => props.setValue("text", event.target.value)}
                                    helperText={props.banner.text_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                            </Tab>
                            <Tab eventKey="eng" title="English">
                                <TextField
                                    label="Title"
                                    placeholder="Title"
                                    autoComplete="off"
                                    error={props.banner.title_eng_error}
                                    value={props.banner.title_eng}
                                    onChange={(event) => props.setValue("title_eng", event.target.value)}
                                    helperText={props.banner.title_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Description"
                                    autoComplete="off"
                                    placeholder="Description"
                                    error={props.banner.description_eng_error}
                                    value={props.banner.description_eng}
                                    onChange={(event) => props.setValue("description_eng", event.target.value)}
                                    helperText={props.banner.description_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label="Sub Text"
                                    autoComplete="off"
                                    placeholder="Sub Text"
                                    error={props.banner.text_eng_error}
                                    value={props.banner.text_eng}
                                    onChange={(event) => props.setValue("text_eng", event.target.value)}
                                    helperText={props.banner.text_eng_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                            </Tab>
                        </Tabs>

                        <TextField
                            disabled={props.banner.process === "create" || props.banner.data.length === 1 ? true : false}
                            label="Sıralama"
                            autoComplete="off"
                            placeholder="Sıralama"
                            error={props.banner.order_error}
                            value={props.banner.order}
                            onChange={(event) => props.setValue("order", event.target.value, props.banner.data)}
                            helperText={props.banner.order_helpertext}
                            fullWidth
                            type="number"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <div style={{ marginTop: 15 }}>
                            <FileUpload
                                {...props}
                                multiple={false}
                                dropzoneText={"Banner Arka Plan İçin Resim Seçiniz 1920*800"}
                                stateKey={"images"}
                                uploadedImages={props.banner.images}
                            />
                        </div>
                        {
                            props.banner.process === "update" && props.banner.detail !== null ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>BANNER DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <FormHelperText>Banner Durumu</FormHelperText>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        style={{ marginLeft: -12 }}
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                checked={props.banner.isActive}
                                                                onChange={(event) => props.setValue("isActive", event.target.checked)}
                                                                value={props.banner.isActive}
                                                            />
                                                        }
                                                        label={props.banner.isActive ? "AKTİF" : "PASİF"}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {
                                                props.banner.detail.createdBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.banner.detail.createdBy.name[0]).toUpperCase() + String(props.banner.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.banner.detail.createdBy.username}
                                                            />
                                                            <br />
                                                            <small>{moment(props.banner.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.banner.detail.createdAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null

                                            }

                                            {
                                                props.banner.detail.updatedBy !== undefined && props.banner.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.banner.detail.updatedBy.name[0]).toUpperCase() + String(props.banner.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.banner.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.banner.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.banner.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }

                    </div> : props.table.row !== null && props.table.row.title ?
                        <>
                            <b>{'"'}{props.table.row.title.tr}{'" '}</b> başlıklı bannerı silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.banner.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.banner.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.banner.isFetchingActions} onClick={() => {
                    if (props.banner.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "title",
                                    value: props.banner.title,
                                    required: false
                                },
                                {
                                    key: "description",
                                    value: props.banner.description,
                                    required: false
                                },
                                {
                                    key: "images",
                                    value: props.banner.images,
                                    required: false
                                },
                                {
                                    key: "order",
                                    value: Number(props.banner.order),
                                    required: true,
                                    type: "number"
                                },
                                {
                                    key: "title_eng",
                                    value: props.banner.title_eng,
                                    required: false
                                },
                                {
                                    key: "description_eng",
                                    value: props.banner.description_eng,
                                    required: false
                                },
                                {
                                    key: "text",
                                    value: props.banner.text
                                },
                                {
                                    key: "text_eng",
                                    value: props.banner.text_eng
                                }
                            ]);
                    } else if (props.banner.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "title",
                                    value: props.banner.title,
                                    required: false
                                },
                                {
                                    key: "description",
                                    value: props.banner.description,
                                    required: false
                                },
                                {
                                    key: "isActive",
                                    value: props.banner.isActive,
                                    required: false
                                },
                                {
                                    key: "images",
                                    value: props.banner.images,
                                    required: false
                                },
                                {
                                    key: "order",
                                    value: Number(props.banner.order),
                                    required: true,
                                    type: "number"
                                },
                                {
                                    key: "title_eng",
                                    value: props.banner.title_eng,
                                    required: false
                                },
                                {
                                    key: "description_eng",
                                    value: props.banner.description_eng,
                                    required: false
                                },
                                {
                                    key: "text",
                                    value: props.banner.text
                                },
                                {
                                    key: "text_eng",
                                    value: props.banner.text_eng
                                }

                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.banner.isFetchingActions ?
                            <span className="spinner spinner-white"></span> :
                            props.banner.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Bannerlar"
            buttonTitle="Yeni Banner"
            cardIcon="fa fa-list-ol"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.banner.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        banner: state.banner,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        tableActions,
        update,
        deleteEntity,

    })(BannersPage);
