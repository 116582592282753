import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";

const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_TEMPLATE_MODAL",
        payload: {
            show: show,
            process: process
        }
    });

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_TEMPLATE",
        payload: {
            key: key,
            value: value
        }
    })
}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_TEMPLATES"
    })
    fetch(globalURL + "/template/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (templates) => {
            if (templates.success) {
                dispatch({
                    type: "FETCH_ALL_TEMPLATES",
                    payload: templates.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ALL_TEMPLATES",
                    payload: templates
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ALL_TEMPLATES",
                payload: error
            })
        })
}

const createNew = (token, data) => dispatch => {
    dispatch({
        type: "GET_INSERT_TEMPLATES"
    })

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        fetch(globalURL + "/template/create", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                title: data[0].value,
                content: data[1].value,
                type: data[2].value
            })
        })
            .then((result) => result.json())
            .then(async (insert) => {
                if (insert.success) {
                    dispatch({
                        type: "FETCH_INSERT_TEMPLATES",
                        payload: insert
                    });
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " şablonu başarıyla oluşturuldu",
                            type: "success"
                        }
                    });
                } else {
                    if (insert.field) {
                        dispatch({
                            type: "SET_VALUE_ERROR_TEMPLATE",
                            payload: {
                                key: insert.field,
                                helpertext: insert.errorMessage
                            }
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        })
                    }
                    dispatch({
                        type: "ERROR_INSERT_TEMPLATES",
                        payload: insert
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                })
                dispatch({
                    type: "ERROR_INSERT_TEMPLATES",
                    payload: error
                })
            })
    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_TEMPLATE",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_TEMPLATE"
        })
        fetch(globalURL + "/template/getDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_TEMPLATE",
                        payload: detail
                    })
                    dispatch({
                        type: "TOGGLE_TEMPLATE_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_TEMPLATE",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_TEMPLATE",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_TEMPLATE_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const update = (token, id, data, rowIndex) => dispatch => {
    dispatch({
        type: "GET_UPDATE_TEMPLATE"
    });

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // update
        fetch(globalURL + "/template/update", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                title: data[0].value,
                content: data[1].value,
                type: data[2].value
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_TEMPLATE",
                        payload: update,
                        rowIndex: rowIndex
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " şablonu başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    if (update.field) {
                        dispatch({
                            type: "SET_VALUE_ERROR_TEMPLATE",
                            payload: {
                                key: update.field,
                                helpertext: update.errorMessage
                            }
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_UPDATE_TEMPLATE",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_UPDATE_TEMPLATE",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_TEMPLATE",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const deleteEntity = (token, row, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_TEMPLATE"
    })
    fetch(globalURL + "/template/delete", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id,
            title: row.title
        })
    })
        .then((result) => result.json())
        .then(async (deleteRecord) => {
            if (deleteRecord.success) {
                dispatch({
                    type: "FETCH_DELETE_TEMPLATE",
                    payload: deleteRecord,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem başarıyla gerçekleşti.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_DELETE_TEMPLATE",
                    payload: deleteRecord
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_DELETE_TEMPLATE",
                payload: error
            })
        })
}



export {
    toggleModal,
    setValue,
    getAll,
    createNew,
    update,
    tableActions,
    deleteEntity
}