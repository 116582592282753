const initialState = {
    data: null,
    isFetching: false,
    username: "",
    password: "",
    username_helpertext: "",
    password_helpertext: "",
    username_error: false,
    password_error: false,
    error: false
}

export default function (state = initialState, action) {
    console.log(action)
    switch (action.type) {
        case "GET_LOGIN":
            return {
                ...state,
                isFetching: true,
                error: false
            }

        case "FETCH_LOGIN":
            return {
                ...state,
                isFetching: false,
                username: "",
                password: "",
                username_helpertext: "",
                password_helpertext: "",
                username_error: false,
                password_error: false

            }
        case "SET_VALUE_LOGIN":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_LOGIN":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "ERROR_LOGIN":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }

}
