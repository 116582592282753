import DashboardPage from "./all/dashboard/DashboardPage";

//operation
import Users from "./all/operation/all/Users";
import Configuration from "./all/operation/all/Configuration";
import Language from "./all/operation/all/Language";
import Address from "./all/operation/all/Address";
import Currency from "./all/operation/all/Currency";
import Campaign from "./all/operation/all/Campaign";
import Shipping from "./all/operation/all/Shipping";
import Roles from "./all/operation/all/Roles";
import Titles from "./all/operation/all/Titles";
import Banners from "./all/operation/all/Banner";
import ImageSlider from "./all/operation/all/ImageSlider";

//product
import AllProducts from "./all/product/all/AllProducts";
import Size from "./all/product/all/Size";
import Color from "./all/product/all/Color";
import Category from "./all/product/all/Category";

//stok
import StockManagement from "./all/warehouse/all/StockManagement";
import SearchStock from "./all/warehouse/all/SearchStock";

//order
import AllOrders from "./all/order/all/AllOrders";
import PendingOrders from "./all/order/all/PendingOrders";
import ShippedOrders from "./all/order/all/ShippedOrders";
import ApprovedOrders from "./all/order/all/ApprovedOrders";
import CanceledOrders from "./all/order/all/CanceledOrders";
import DeliveredOrders from "./all/order/all/DeliveredOrders";
import SearchOrder from "./all/order/all/SearchOrder";

// refund
import Refunds from "./all/refund/all/Refunds";

//orderCancelLog
import CanceledOrdersLog from "./all/cancel/all/CanceledOrdersLog";

// CRM
import AllCustomers from "./all/crm/all/AllCustomers";
import SearchCustomer from "./all/crm/all/SearchCustomer";
import BlackList from "./all/crm/all/BlackList";
import CustomerDemands from "./all/crm/all/CustomerDemands";

// Mailing
import MailingFullList from "./all/mailing/all/MailingFullList";
import MailingBlackList from "./all/mailing/all/MailingBlackList";
import MailingQuitList from "./all/mailing/all/MailingQuitList";
import SpamList from "./all/mailing/all/SpamList";
import Templates from "./all/mailing/all/Templates";


// Notification
import NotificationList from "./all/notification/all/NotificationList";
import NotificationQuitList from "./all/notification/all/NotificationQuitList";

export {
    DashboardPage,
    Users,
    Configuration,
    Language,
    Address,
    Currency,
    Campaign,
    Shipping,
    AllProducts,
    Size,
    StockManagement,
    SearchStock,
    AllOrders,
    ApprovedOrders,
    CanceledOrders,
    DeliveredOrders,
    SearchOrder,
    AllCustomers,
    SearchCustomer,
    BlackList,
    CustomerDemands,
    MailingFullList,
    MailingBlackList,
    MailingQuitList,
    SpamList,
    NotificationList,
    NotificationQuitList,
    Roles,
    Titles,
    Color,
    Category,
    PendingOrders,
    ShippedOrders,
    Banners,
    ImageSlider,
    Templates,
    CanceledOrdersLog,
    Refunds
}