const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_ORDER_CANCEL_LOGS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_ORDER_CANCEL_LOGS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_ORDER_CANCEL_LOGS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_ORDER_CANCEL_LOG_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show
            }
        case "SET_VALUE_ORDER_CANCEL_LOG":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_ORDER_CANCEL_LOG":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_ORDER_CANCEL_LOG":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_ORDER_CANCEL_LOG":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data
            }
        case "ERROR_DETAIL_ORDER_CANCEL_LOG":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }

        case "GET_DELETE_ORDER_CANCEL_LOG":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_ORDER_CANCEL_LOG":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_ORDER_CANCEL_LOG":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
