import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
  toggleModal,
  getAll,
  setValue,
  createNewUser,
  tableActions,
  updateUser,
  deleteUser,
  
} from "../../../../../redux/actions/userActions";
import {
  Button,
  Modal,
  Badge
} from "react-bootstrap";
import {
  TextField,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  Chip,
  Avatar,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import Loading from "../../../../helpers/loading/Loading";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function UsersPage(props) {
  useEffect(() => {
    props.getAll(props.auth.user.token);
    return () => {
      // will unMount 
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      dataField: "name",
      text: "Adı",
      sort: true,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.users.data.length > 0 ? "block" : "none"
        }
      }),
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "surname",
      text: "Soyadı",
      sort: true,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.users.data.length > 0 ? "block" : "none"
        }
      }),
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "username",
      text: "Kullanıcı Adı",
      sort: true,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.users.data.length > 0 ? "block" : "none"
        }
      }),
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "isOnline",
      text: "DURUM",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.users.data.length > 0 ? "block" : "none",
          width : 100
        }
      }),
      filterValue: (cell, row) => {
        return cell === true ? "çevrimiçi" : "çevrimdışı";
      },
      formatter: (cell) => {
        return cell === true ? <Badge variant="success" className="mr-1">
          çevrimiçi
      </Badge> : <Badge variant="warning" className="mr-1">
          çevrimdışı
                </Badge>
      },
    },
    {
      dataField: "lastSeenAt",
      text: "Son Görülme",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell) => {
        return <small>{moment(cell).fromNow()}</small>
      }
    },
    {
      dataField: "action",
      text: "",
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      formatter: (cell, row) => {
        return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
      }

    }
  ];

  return <>
    <Loading show={props.users.isFetchingDetail} />
    <Modal backdrop="static" size="lg" show={props.users.showModal} onHide={() => props.toggleModal(false, "")}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i style={{ marginRight: 5 }} className={props.users.process === "create" ? "fa fa-user-plus" : props.users.process === "update" ? "fa fa-user-edit" : "fa fa-user-slash"}></i>
          {props.users.process === "create" ? "Yeni Kullanıcı" : props.users.process === "update" ? "Kullanıcı Düzenle" : "Kullanıcı Sil"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{
        props.users.process === "create" || props.users.process === "update" ?
          <div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="İsim"
                  style={{ margin: 8 }}
                  placeholder="İsim"
                  autoCorrect="off"
                  autoComplete="off"
                  fullWidth
                  error={props.users.name_error}
                  value={props.users.name}
                  onChange={(event) => props.setValue("name", event.target.value)}
                  helperText={props.users.name_helpertext}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="Soyisim"
                  style={{ margin: 8 }}
                  placeholder="Soyisim"
                  autoCorrect="off"
                  autoComplete="off"
                  error={props.users.surname_error}
                  value={props.users.surname}
                  onChange={(event) => props.setValue("surname", event.target.value)}
                  helperText={props.users.surname_helpertext}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  autoCorrect="off"
                  autoComplete="off"
                  label="Kullanıcı Adı"
                  style={{ margin: 8 }}
                  placeholder="Kullanıcı Adı"
                  error={props.users.username_error}
                  value={props.users.username}
                  onChange={(event) => props.setValue("username", event.target.value)}
                  helperText={props.users.username_helpertext}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  autoCorrect="off"
                  autoComplete="off"
                  label="Email"
                  style={{ margin: 8 }}
                  placeholder="Email"
                  error={props.users.email_error}
                  value={props.users.email}
                  onChange={(event) => props.setValue("email", event.target.value)}
                  helperText={props.users.email_helpertext}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      type="date"
                      autoCorrect="off"
                      autoComplete="off"
                      label="Doğum Tarihi"
                      error={props.users.dateOfBirth_error}
                      value={props.users.dateOfBirth}
                      onChange={(event) => props.setValue("dateOfBirth", event.target.value)}
                      helperText={props.users.dateOfBirth_helpertext}
                      style={{ margin: 8 }}
                      placeholder="Doğum Tarihi"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: 0
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormControl style={{ marginLeft: 6, marginTop: 8 }} className="form-control" variant="outlined">
                      <InputLabel htmlFor="outlined-age-simple">
                        Cinsiyet
                </InputLabel>
                      <Select
                        fullWidth
                        error={props.users.gender_error}
                        value={props.users.gender}
                        onChange={(event) => props.setValue("gender", event.target.value)}
                        input={
                          <OutlinedInput
                            labelWidth={50}
                            name="age"
                          />
                        }
                      >
                        <MenuItem disabled value={""}>Cinsiyet Seçiniz</MenuItem>
                        <MenuItem value={"unspecified"}>Belirtme</MenuItem>
                        <MenuItem value={"male"}>Erkek</MenuItem>
                        <MenuItem value={"female"}>Kadın</MenuItem>
                      </Select>
                      <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{props.users.gender_helpertext}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <TextField
                  autoCorrect="off"
                  autoComplete="off"
                  label="Telefon"
                  style={{ margin: 8 }}
                  placeholder="Telefon"
                  error={props.users.phone_error}
                  value={props.users.phone}
                  onChange={(event) => props.setValue("phone", event.target.value)}
                  helperText={props.users.phone_helpertext}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0
                    }
                  }}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: props.users.role_error || props.users.title_error ? 3 : 8 }}>
              <div className="col-md-6">
                <FormControl style={{ marginLeft: 6 }} className="form-control" variant="outlined">
                  <InputLabel htmlFor="outlined-age-simple">
                    Yetki
                </InputLabel>
                  <Select
                    fullWidth
                    error={props.users.role_error}
                    value={props.users.role}
                    onChange={(event) => props.setValue("role", event.target.value)}
                    input={
                      <OutlinedInput
                        labelWidth={38}
                      />
                    }
                  >
                    <MenuItem disabled value={""}>Rol Seçiniz</MenuItem>
                    {
                      props.users.roles.map((item, i) => {
                        return <MenuItem key={i} value={item.id}>{item.roleName}</MenuItem>
                      })
                    }
                  </Select>
                  <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{props.users.role_helpertext}</FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl style={{ marginLeft: 6 }} className="form-control" variant="outlined">
                  <InputLabel htmlFor="outlined-age-simple">
                    Görev
                </InputLabel>
                  <Select
                    fullWidth
                    error={props.users.title_error}
                    value={props.users.title}
                    onChange={(event) => props.setValue("title", event.target.value)}
                    input={
                      <OutlinedInput
                        labelWidth={38}
                      />
                    }
                  >
                    <MenuItem disabled value={""}>Görev Seçiniz</MenuItem>
                    {
                      props.users.titles.map((item, i) => {
                        return <MenuItem key={i} value={item.id}>{item.titleName}</MenuItem>
                      })
                    }
                  </Select>
                  <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{props.users.title_helpertext}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <br />
            {
              props.users.process === "update" && props.users.detail !== null ?
                <>
                  <div className="separator separator-dashed my-7"></div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>KULLANICI DETAYLARI</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <FormHelperText>Kullanıcıyı Engelle</FormHelperText>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <FormControlLabel
                            style={{ marginLeft: -12 }}
                            control={
                              <Switch
                                color="primary"
                                checked={props.users.isBlocked}
                                onChange={(event) => props.setValue("isBlocked", event.target.checked)}
                                value={props.users.isBlocked}
                              />
                            }
                            label={props.users.isBlocked ? "BLOKE" : "AKTİF"}
                          />
                        </TableCell>
                      </TableRow>
                      {
                        props.users.detail.createdBy !== null ?
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Chip
                                style={{ marginLeft: -5 }}
                                avatar={<Avatar>{String(props.users.detail.createdBy.name[0]).toUpperCase() + String(props.users.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                label={props.users.detail.createdBy.username}
                              /><br />
                              <small>{moment(props.users.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                              <small>{moment(props.users.detail.createdAt).fromNow()}</small>
                            </TableCell>
                          </TableRow> : null
                      }

                      {
                        props.users.detail.updatedBy !== undefined && props.users.detail.updatedBy !== null ?
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Chip
                                style={{ marginLeft: -5 }}
                                avatar={<Avatar>{String(props.users.detail.updatedBy.name[0]).toUpperCase() + String(props.users.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                label={props.users.detail.updatedBy.username}
                              /><br />
                              <small>{moment(props.users.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                              <small>{moment(props.users.detail.updatedAt).fromNow()}</small>
                            </TableCell>
                          </TableRow> : null
                      }
                    </TableBody>
                  </Table></> : null
            }

          </div> : props.table.row !== null && props.table.row.username ?
            <>
              <b>{'"'}{props.table.row.username}{'" '}</b> kullanıcısını silmek üzeresiniz. İşlemi onaylıyor musunuz?
          </> : null
      }</Modal.Body>
      <Modal.Footer>
        <Button disabled={props.users.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.users.process)}>
          İptal
            </Button>
        <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.users.isFetchingActions} onClick={() => {
          if (props.users.process === "create") {
            props.createNewUser(props.auth.user.token, [
              {
                key: "name",
                value: props.users.name,
                required: true
              },
              {
                key: "surname",
                value: props.users.surname,
                required: true
              },
              {
                key: "email",
                value: props.users.email,
                required: true
              }, {
                key: "phone",
                value: props.users.phone,
                required: false
              },
              {
                key: "username",
                value: props.users.username,
                required: true
              },
              {
                key: "role",
                value: props.users.role,
                required: true
              },
              {
                key: "title",
                value: props.users.title,
                required: true
              },
              {
                key: "gender",
                value: props.users.gender,
                required: false
              },
              {
                key: "dateOfBirth",
                value: props.users.dateOfBirth,
                required: false
              },
              {
                key: "isBlocked",
                value: props.users.isBlocked,
                required: false
              }
            ])
          } else if (props.users.process === "update") {
            props.updateUser(props.users.detail.id, props.auth.user.token, [
              {
                key: "name",
                value: props.users.name,
                required: true
              },
              {
                key: "surname",
                value: props.users.surname,
                required: true
              },
              {
                key: "email",
                value: props.users.email,
                required: true
              }, {
                key: "phone",
                value: props.users.phone,
                required: false
              },
              {
                key: "username",
                value: props.users.username,
                required: true
              },
              {
                key: "role",
                value: props.users.role,
                required: true
              },
              {
                key: "title",
                value: props.users.title,
                required: true
              },
              {
                key: "gender",
                value: props.users.gender,
                required: false
              },
              {
                key: "dateOfBirth",
                value: props.users.dateOfBirth,
                required: false
              },
              {
                key: "isBlocked",
                value: props.users.isBlocked,
                required: false
              }
            ], props.table.index, props.auth.user)
          } else {
            props.deleteUser(props.table.row, props.auth.user.token, props.table.index);
          }
        }} variant="primary">
          {
            props.users.isFetchingActions ?
              <span className="ml-3 spinner spinner-white"></span> :
              props.users.process === "delete" ? "Sil" : "Kaydet"
          }
        </Button>
      </Modal.Footer>
    </Modal>
 
    <DataTable
      handleModal={props.toggleModal}
      title="Tüm Kullanıcılar"
      buttonTitle="Yeni Kullanıcı"
      cardIcon="fa fa-user-friends"
      buttonIcon="fa fa-user-plus"
      searchPlaceholder="Kullanıcı Ara"
      data={props.users.data}
      columns={columns}
    />
  </>
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    users: state.users,
    table: state.table
  }
}

export default connect(mapStateToProps,
  {
    toggleModal,
    getAll,
    setValue,
    createNewUser,
    tableActions,
    updateUser,
    deleteUser,
    
  })(UsersPage);
