const onTableClick = (e, row, rowIndex, currentIndex, sizePerPage, page) => dispatch => {
    dispatch({
        type: "SET_TABLE_ROW_DATA",
        payload: {
            e: e,
            row: row,
            rowIndex: rowIndex,
            currentIndex: currentIndex,
            sizePerPage: sizePerPage,
            page: page,
            index : (page - 1) * sizePerPage + rowIndex
        }
    })
}

const setCurrentPageOverWrite = (currentPage) => dispatch => {
    dispatch({
        type: "SET_TABLE_CURRENT_PAGE",
        payload: currentPage
    })
}


export {
    onTableClick,
    setCurrentPageOverWrite
}