const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    refundState: "",
    refundState_error: false,
    deniedReason: "",
    deniedReason_error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_REFUNDS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_REFUNDS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_REFUNDS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_REFUNDS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_REFUNDS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data
            }
        case "ERROR_INSERT_REFUNDS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_REFUND_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                refundState: action.payload.show ? state.refundState : "",
                refundState_error: false
            }
        case "SET_VALUE_REFUND":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_REFUND":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_REFUND":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_REFUND":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                refundState: action.payload.data.state,
                deniedReason: action.payload.data.deniedReason ? action.payload.data.deniedReason : ""
            }
        case "ERROR_DETAIL_REFUND":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_REFUND":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_REFUND":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_UPDATE_REFUND":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_REFUND":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_REFUND":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_REFUND":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
