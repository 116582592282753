import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getAllCities,
    loadLocations,
    toggleModalDistrict,
    toggleModalStreet,
    streetActions,
    districtActions,
    setValue,
    getAllDistricts,
    getAllStreets,
    updateDistrict,
    updateStreet,
    createNewDistrict,
    createNewStreet,
    deleteDistrict,
    deleteStreet
} from "../../../../../redux/actions/addressActions";
import {
    Breadcrumbs,
    Typography,
    TextField
} from "@material-ui/core";
import {
    Button,
    Modal
} from "react-bootstrap";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import SearchBox from "../../../../helpers/searchbox/SearchBox";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';


function AddressPage(props) {
    useEffect(() => {
        props.getAllCities(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const columnsDistricts = [
        {
            dataField: "districtName",
            text: "İlçe",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.address.districts.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "120px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.districtActions, props.auth.user.token)
            }
        }
    ];

    const columnsStreets = [
        {
            dataField: "streetName",
            text: "Mahalle",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.address.streets.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "120px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.streetActions, props.auth.user.token)
            }

        }
    ];


    return <div>
        <Loading show={props.address.isFetchingDetail || props.address.isRefreshing} />
        <Modal backdrop="static" size="lg" show={props.address.showDistrictModal} onHide={() => props.toggleModalDistrict(false, props.address.process)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.address.process === "create" ? "fa fa-plus" : props.address.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.address.process === "create" ? "Yeni İlçe" : props.address.process === "update" ? "İlçe Düzenle" : "İlçe Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.address.process === "create" || props.address.process === "update" ?
                    <div>
                        <TextField
                            label="İlçe Adı"
                            style={{ margin: 8 }}
                            placeholder="İlçe Adı"
                            autoComplete="off"
                            error={props.address.nameDistrict_error}
                            value={props.address.nameDistrict}
                            onChange={(event) => props.setValue("nameDistrict", event.target.value)}
                            helperText={props.address.nameDistrict_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="İlçe Kodu"

                            style={{ margin: 8 }}
                            placeholder="İlçe Kodu"
                            autoComplete="off"
                            error={props.address.codeDistrict_error}
                            value={props.address.codeDistrict}
                            onChange={(event) => props.setValue("codeDistrict", event.target.value)}
                            helperText={props.address.codeDistrict_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                    </div> :
                    props.table.row !== null && props.table.row.districtName ?
                        <>
                            <b>{'"'}{props.table.row.districtName}{'" '}</b> isimli ilçeyi silmek üzeresiniz. İşlemi onaylıyor musunuz?
                  </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.address.isFetchingActionsDistrict} variant="secondary" onClick={() => props.toggleModalDistrict(false, props.address.process)}>
                    İptal
            </Button>
                <Button onClick={() => {
                    if (props.address.process === "create") {
                        props.createNewDistrict(props.auth.user.token,
                            [
                                {
                                    key: "nameDistrict",
                                    value: props.address.nameDistrict,
                                    required: true
                                },
                                {
                                    key: "codeDistrict",
                                    value: props.address.codeDistrict,
                                    required: true
                                }
                            ], props.address.selectedCity.value)
                    } else if (props.address.process === "update") {
                        props.updateDistrict(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "nameDistrict",
                                    value: props.address.nameDistrict,
                                    required: true
                                },
                                {
                                    key: "codeDistrict",
                                    value: props.address.codeDistrict,
                                    required: true
                                }

                            ], props.table.index, props.address.selectedCity.value);
                    } else {
                        props.deleteDistrict(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.address.isFetchingActionsDistrict} variant="primary">
                    {
                        props.address.isFetchingActionsDistrict ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.address.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal backdrop="static" size="lg" show={props.address.showStreetModal} onHide={() => props.toggleModalStreet(false, props.address.process)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.address.process === "create" ? "fa fa-plus" : props.address.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.address.process === "create" ? "Yeni Mahalle" : props.address.process === "update" ? "Mahalle Düzenle" : "Mahalle Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.address.process === "create" || props.address.process === "update" ?
                    <div>
                        <TextField
                            label="Mahalle Adı"
                            style={{ margin: 8 }}
                            placeholder="Mahalle Adı"
                            autoComplete="off"
                            error={props.address.nameStreet_error}
                            value={props.address.nameStreet}
                            onChange={(event) => props.setValue("nameStreet", event.target.value)}
                            helperText={props.address.nameStreet_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Mahalle Kodu"

                            style={{ margin: 8 }}
                            placeholder="Mahalle Kodu"
                            autoComplete="off"
                            error={props.address.codeStreet_error}
                            value={props.address.codeStreet}
                            onChange={(event) => props.setValue("codeStreet", event.target.value)}
                            helperText={props.address.codeStreet_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                    </div> :
                    props.table.row !== null && props.table.row.streetName ?
                        <>
                            <b>{'"'}{props.table.row.streetName}{'" '}</b> isimli mahalleyi silmek üzeresiniz. İşlemi onaylıyor musunuz?
                  </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.address.isFetchingActionsStreet} variant="secondary" onClick={() => props.toggleModalStreet(false, props.address.process)}>
                    İptal
            </Button>
                <Button
                    onClick={() => {
                        if (props.address.process === "create") {
                            props.createNewStreet(props.auth.user.token,
                                [
                                    {
                                        key: "nameStreet",
                                        value: props.address.nameStreet,
                                        required: true
                                    },
                                    {
                                        key: "codeStreet",
                                        value: props.address.codeStreet,
                                        required: true
                                    }
                                ], props.address.selectedDistrict.value, props.address.selectedDistrict.value)
                        } else if (props.address.process === "update") {
                            props.updateStreet(props.auth.user.token, props.table.row.id,
                                [
                                    {
                                        key: "nameStreet",
                                        value: props.address.nameStreet,
                                        required: true
                                    },
                                    {
                                        key: "codeStreet",
                                        value: props.address.codeStreet,
                                        required: true
                                    }

                                ], props.table.index, props.address.selectedDistrict.value);
                        } else {
                            props.deleteStreet(props.auth.user.token, props.table.row, props.table.index);
                        }
                    }}

                    style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.address.isFetchingActionsStreet} variant="primary">
                    {
                        props.address.isFetchingActionsStreet ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.address.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <br />
        <div className="row">
            <div className="col-md-6">
                <SearchBox
                    label="Şehir"
                    placeholder="Şehir Seçiniz"
                    {...props}
                />
                {
                    props.address.cities.length > 0 ? null :
                        <>
                            <br />
                            <Button disabled={props.address.isRefreshing} onClick={() => props.loadLocations(props.auth.user.token)} block>
                                {
                                    props.address.isRefreshing ? "Adres Bilgileri Yükleniyor..." :
                                        "Tüm Adres  Bilgilerini Yükle"
                                }
                            </Button>
                        </>
                }
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }} className="col-md-6">
                {
                    props.address.selectedCity !== null ?
                        <Breadcrumbs aria-label="Breadcrumb">
                            <Typography color="textPrimary">{props.address.selectedCity.label}</Typography>
                            {
                                props.address.selectedDistrict !== null ?
                                    <Typography color="textPrimary">{props.address.selectedDistrict.label}</Typography> : null
                            }
                        </Breadcrumbs> : null
                }

            </div>
        </div>
        <div style={{ backgroundColor: "#bbbbbb" }} className="separator separator-dashed my-7"></div>
        <div className="row">
            <div className="col-md-6">
                <DataTable
                    selectRow={{
                        mode: 'radio',
                        style: {
                            backgroundColor: "#e6e6ff",
                            borderRadius: 5
                        },
                        hideSelectAll: true,
                        onSelect: (row, isSelect, rowIndex, e) => {
                            props.getAllStreets(props.auth.user.token, row)
                        }
                    }}
                    disabledButton={props.address.districts.length > 0 ? false : true}
                    handleModal={props.toggleModalDistrict}
                    title="İlçeler"
                    buttonTitle="Yeni İlçe Ekle"
                    cardIcon="fa fa-city"
                    buttonIcon="fa fa-plus"
                    searchPlaceholder="Ara"
                    data={props.address.districts}
                    columns={columnsDistricts}
                />

            </div>
            <div className="col-md-6">

                <DataTable
                    disabledButton={props.address.streets.length > 0 ? false : true}
                    handleModal={props.toggleModalStreet}
                    title="Mahalle"
                    buttonTitle="Yeni Mahalle Ekle"
                    cardIcon="fa fa-road"
                    buttonIcon="fa fa-plus"
                    searchPlaceholder="Ara"
                    data={props.address.streets}
                    columns={columnsStreets}
                />

            </div>
        </div>

    </div>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        address: state.address,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        getAllCities,
        loadLocations,
        toggleModalStreet,
        toggleModalDistrict,
        streetActions,
        districtActions,
        setValue,
        getAllStreets,
        getAllDistricts,
        updateDistrict,
        updateStreet,
        createNewDistrict,
        createNewStreet,
        deleteDistrict,
        deleteStreet
    })(AddressPage);
