import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";

const toggleAlert = (alert) => dispatch => {
    dispatch({
        type: "TOGGLE_CREATE_PASSWORD_ALERT",
        payload: alert
    })
}


const userCreatePassword = (data, token) => dispatch => {
    dispatch({
        type: "GET_CREATE_PASSWORD"
    })

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // CREATE_PASSWORD
        if (data[0].value === data[1].value) {
            fetch(globalURL + "/auth/setUserPassword", {
                method: 'POST',
                headers: {
                    ...fetchApiHeaders.POST,
                    token: token
                },
                body: JSON.stringify({
                    password: data[0].value
                })
            })
                .then((result) => result.json())
                .then(async (createPassword) => {
                    if (createPassword.success) {
                        dispatch({
                            type: "FETCH_CREATE_PASSWORD"
                        })
                    } else {
                        dispatch({
                            type: "ERROR_CREATE_PASSWORD",
                            payload: createPassword
                        });

                        dispatch({
                            type: "TOGGLE_CREATE_PASSWORD_ALERT",
                            payload: {
                                show: true,
                                message: createPassword.errorMessage,
                                type: "error"
                            }
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "ERROR_CREATE_PASSWORD",
                        payload: error
                    })
                })
        } else {
            data.map((item, index) => {
                return dispatch({
                    type: "SET_VALUE_ERROR_CREATE_PASSWORD",
                    payload: {
                        ...item,
                        helperText: "Parolar Eşleşmiyor"
                    }
                })
            })
        }
    } else {
        // null inputs
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_CREATE_PASSWORD",
                payload: item
            })
        })

        checkForm.invalidFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_CREATE_PASSWORD",
                payload: item
            })
        })
    }
}


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_CREATE_PASSWORD",
        payload: {
            key: key,
            value: value
        }
    })
}



export {
    userCreatePassword,
    setValue,
    toggleAlert
}