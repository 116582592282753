import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,

} from "../../../../../redux/actions/templateActions";
import {
    Button,
    Modal,
    Tab,
    Tabs
} from "react-bootstrap";
import {
    TextField,
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    TextareaAutosize,
    FormControl,
    Select,
    InputLabel,
    OutlinedInput,
    MenuItem
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function SizesPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const window = (new JSDOM('')).window
    const DOMPurify = createDOMPurify(window);

    const columns = [
        {
            dataField: "title",
            text: "Başlık",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.template.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "type",
            text: "Şablon Tipi",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.template.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.template.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.template.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.template.process === "create" ? "fa fa-plus" : props.template.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.template.process === "create" ? "Yeni Şablon" : props.template.process === "update" ? "Şablon Düzenle" : "Şablon Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.template.process === "create" || props.template.process === "update" ?
                    <Tabs defaultActiveKey="create" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="create" title="Şablon Düzenle">
                            <div>
                                <TextField
                                    label="Başlık"
                                    style={{ margin: 8 }}
                                    placeholder="Başlık"
                                    autoComplete="off"
                                    error={props.template.title_error}
                                    value={props.template.title}
                                    onChange={(event) => props.setValue("title", event.target.value)}
                                    helperText={props.template.title_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                <FormControl style={{ marginLeft: 6, marginTop: 8 }} className="form-control" variant="outlined">
                                    <InputLabel htmlFor="outlined-age-simple">
                                        Şablon Tipi
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        error={props.template.type_error}
                                        value={props.template.type}
                                        onChange={(event) => props.setValue("type", event.target.value)}
                                        input={
                                            <OutlinedInput
                                                labelWidth={75}
                                                name="age"
                                            />
                                        }
                                    >

                                        <MenuItem value={"other"}>Diğer</MenuItem>
                                        <MenuItem value={"aggrement"}>Mesafeli Satış Sözleşmesi</MenuItem>
                                        <MenuItem value={"membershipAggrement"}>Üyelik Sözleşmesi</MenuItem>
                                        <MenuItem value={"privacyAndSecurity"}>Gizlilik ve Güvenlik Politikası</MenuItem>
                                        <MenuItem value={"cancellationAndReturn"}>İptal ve İade Koşulları</MenuItem>
                                        <MenuItem value={"consentText"}>Rıza Metni</MenuItem>
                                        <MenuItem value={"confirm"}>Onay</MenuItem>
                                        <MenuItem value={"forgotpassword"}>Şifre Unuttum</MenuItem>
                                        <MenuItem value={"ordercompleted"}>Sipariş Tamamlandı</MenuItem>
                                        <MenuItem value={"ordershipment"}>Sipariş Kargoya Verildi</MenuItem>
                                        <MenuItem value={"reminder"}>Hatırlatma</MenuItem>
                                        <MenuItem value={"notification"}>Bildirim</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ marginLeft: 0, color: "#e83e8c" }}>{props.template.type_helpertext}</FormHelperText>
                                </FormControl>

                                <TextareaAutosize
                                    label="İçerik"
                                   style={{ borderColor: !props.template.content_error ? "#bbbbbb" : "#f018a6", borderRadius:5, marginLeft:6, marginTop: props.template.type_error ? 50 : 30, width: "100%" }}
                                    rowsMin={10}
                                    rowsMax={15}
                                    placeholder="İçerik"
                                    autoComplete="off"
                                    error={props.template.content_error}
                                    value={props.template.content}
                                    onChange={(event) => props.setValue("content", event.target.value)}
                                    helperText={props.template.content_helpertext}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                                {
                                   props.template.content_error ? <FormHelperText style={{ marginLeft: 6, color: "#f018a6" }}>{props.template.content_helpertext}</FormHelperText> : null
                                }
                                {
                                    props.template.process === "update" && props.template.detail !== null ?
                                        <>
                                            <div className="separator separator-dashed my-7"></div>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ŞABLON DETAYLARI</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        props.template.detail.createdBy !== null ?
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Chip
                                                                        style={{ marginLeft: -5 }}
                                                                        avatar={<Avatar>{String(props.template.detail.createdBy.name[0]).toUpperCase() + String(props.template.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                        label={props.template.detail.createdBy.username}
                                                                    /><br />
                                                                    <small>{moment(props.template.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                                    <small>{moment(props.template.detail.createdAt).fromNow()}</small>
                                                                </TableCell>
                                                            </TableRow> : null
                                                    }

                                                    {
                                                        props.template.detail.updatedBy !== undefined && props.template.detail.updatedBy !== null ?
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <Chip
                                                                        style={{ marginLeft: -5 }}
                                                                        avatar={<Avatar>{String(props.template.detail.updatedBy.name[0]).toUpperCase() + String(props.template.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                        label={props.template.detail.updatedBy.username}
                                                                    /><br />
                                                                    <small>{moment(props.template.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                                    <small>{moment(props.template.detail.updatedAt).fromNow()}</small>
                                                                </TableCell>
                                                            </TableRow> : null
                                                    }
                                                </TableBody>
                                            </Table></> : null
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="view" title="Önizleme">
                          
                            { <div style={{ backgroundColor:"transparent"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.template.content) }} /> }
                          
                        </Tab>
                    </Tabs>

                    :
                    props.table.row !== null && props.table.row.title ?
                        <>
                            <b>{'"'}{props.table.row.title}{'" '}</b> başlıklı şablonu silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.template.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.template.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.template.isFetchingActions} onClick={() => {
                    if (props.template.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "title",
                                    value: props.template.title.trim(),
                                    required: true
                                },
                                {
                                    key: "content",
                                    value: props.template.content.trim(),
                                    required: true
                                },
                                {
                                    key: "type",
                                    value: props.template.type,
                                    required: true
                                }
                            ])
                    } else if (props.template.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "title",
                                    value: props.template.title,
                                    required: true
                                },
                                {
                                    key: "content",
                                    value: props.template.content,
                                    required: true
                                },
                                {
                                    key: "type",
                                    value: props.template.type,
                                    required: true
                                }

                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.template.isFetchingActions ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.template.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Şablonlar"
            buttonTitle="Yeni Şablon"
            cardIcon="fa fa-envelope-open-text"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.template.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        template: state.template,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        update,
        deleteEntity,
        tableActions,

    })(SizesPage);
