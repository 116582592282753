const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    orderState: "",
    orderState_error: false,
    canPartialRefund: false,
    cancelReason: "",
    cancelReason_helpertext: "",
    cancelReason_error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_ORDERS":
            return {
                ...state,
                isFetching: true,
                isFetchingDetail: false,
                isFetchingActions: false,
                error: false
            }
        case "FETCH_ALL_ORDERS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_ORDERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_ORDERS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_ORDERS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data
            }
        case "ERROR_INSERT_ORDERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_ORDER_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                orderState: action.payload.show ? state.orderState : "",
                orderState_error: false
            }
        case "SET_VALUE_ORDER":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_ORDER":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_ORDER":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_ORDER":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                orderState: action.payload.data.state,
                cancelReason: action.payload.data.cancelReason ? action.payload.data.cancelReason : ""
            }
        case "ERROR_DETAIL_ORDER":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_ORDER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_ORDER":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_UPDATE_ORDER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_ORDER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_ORDER":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_ORDER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "SET_VALUE_ORDER_SELECTED_ITEM":
            var cartItem = state.detail.cart.cartItems[action.payload.index];
            state.detail.cart.cartItems[action.payload.index]['selected'] = action.payload.value;

            var options = [];
            for (var i = 0; i < cartItem.maxRefundCount; i++) {
                var oObj = {
                    text: String(i + 1),
                    value: String(i + 1)
                };
                options.push(oObj);
            }

            state.detail.cart.cartItems[action.payload.index]['options'] = options;
            if (action.payload.value === true) {
                state.detail.cart.cartItems[action.payload.index]['selectedQuantity'] = "1";
            } else {
                state.detail.cart.cartItems[action.payload.index]['selectedQuantity'] = "1";
            }
            return {
                ...state
            }
        case "SET_VALUE_ORDER_SELECTED_QUANTITY":
            state.detail.cart.cartItems[action.payload.index]['selectedQuantity'] = action.payload.value;
            return {
                ...state
            }
        default:
            return state
    }

}
