import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    getLastCurrency,
    refreshCurrency
} from "../../../../../redux/actions/currencyActions";
import {
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell
} from "@material-ui/core";
import {
    Button
} from "react-bootstrap";

import Loading from "../../../../helpers/loading/Loading";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";


function CurrencyPage(props) {
    useEffect(() => {
        props.getLastCurrency(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <Loading show={props.currency.isRefreshing} />
        <div className="row">
            <div className="col-md-5">
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Bülten No</TableCell>
                                <TableCell>Tarih</TableCell>
                                <TableCell>Son Otomatik Güncelleme</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.currency.data !== null ?
                                    <TableRow>
                                        <TableCell component="th" scope="row">{props.currency.data.currency['Tarih_Date']['$']['Bulten_No']}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {props.currency.data.currency['Tarih_Date']['$']['Tarih']}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {
                                                props.currency.data.updatedAt ?
                                                    moment(props.currency.data.updatedAt).fromNow() :
                                                    moment(props.currency.data.createdAt).fromNow()
                                            }

                                        </TableCell>
                                    </TableRow> : null
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <br />
                <Button disabled={props.currency.isRefreshing} onClick={() => props.refreshCurrency(props.auth.user.token)} block>
                    {
                        props.currency.isRefreshing ? "Güncelleniyor..." :
                            "Kurları Manuel Güncelle"
                    }
                </Button>
            </div>
            <div className="col-md-7">
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Alış (TL)</TableCell>
                                <TableCell>Satış (TL)</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            props.currency.data !== null ?
                                <TableBody>
                                    {
                                        props.currency.data.currency['Tarih_Date']['Currency'].map((item, i) => {
                                            return item['BanknoteBuying'][0].trim() !== "" ? (
                                                <TableRow key={i}>
                                                    <TableCell style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: -1 }} component="th" scope="row">
                                                        <span className="symbol symbol-20 mr-3">
                                                            <img alt={item['$']['CurrencyCode']} src={toAbsoluteUrl("/media/svg/flags/" + item['$']['CurrencyCode'] + ".svg")} />
                                                        </span>
                                                        {item['Isim'][0]}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">{item['BanknoteBuying'][0]}</TableCell>
                                                    <TableCell component="th" scope="row">{item['BanknoteSelling'][0]}</TableCell>
                                                </TableRow>
                                            ) : null
                                        })
                                    }
                                </TableBody> : null
                        }
                    </Table>

                </Paper>
            </div>
        </div>

    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        currency: state.currency
    }
}

export default connect(mapStateToProps,
    {
        getLastCurrency,
        refreshCurrency
    })(CurrencyPage);
