import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";


const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_USER_MODAL",
        payload: {
            show: show,
            process: process
        }
    })

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_USERS",
        payload: {
            key: key,
            value: value
        }
    })
}


const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_USERS"
    })
    fetch(globalURL + "/auth/getAllUsers", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (users) => {
            if (users.success) {
                let roles = await fetch(globalURL + "/role/getAll", {
                    method: 'POST',
                    headers: {
                        ...fetchApiHeaders.POST,
                        token: token
                    }
                });
                let titles = await fetch(globalURL + "/title/getAll", {
                    method: 'POST',
                    headers: {
                        ...fetchApiHeaders.POST,
                        token: token
                    }
                });
                dispatch({
                    type: "FETCH_ALL_USERS",
                    payload: users.data,
                    roles: await roles.json(),
                    titles: await titles.json()
                })

            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_ALL_USERS",
                    payload: users
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_ALL_USERS",
                payload: error
            })
        })
}

const createNewUser = (token, data) => dispatch => {
    var checkForm = checkFormFields(data, true);
    if (!checkForm.result) {
        // create
        dispatch({
            type: "GET_CREATE_USER"
        })
        fetch(globalURL + "/auth/createNewUser", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                createdUser: {
                    name: data[0].value,
                    surname: data[1].value,
                    email: data[2].value,
                    phone: data[3].value,
                    username: data[4].value,
                    role: data[5].value,
                    title: data[6].value,
                    gender: data[7].value !== "" ? data[7].value : "unspecified",
                    dateOfBirth: data[8].value,
                    isBlocked: data[9].value
                }
            })
        })
            .then((result) => result.json())
            .then(async (newUser) => {
                if (newUser.success) {
                    dispatch({
                        type: "FETCH_CREATE_USER",
                        payload: newUser
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "Kullanıcı başarıyla oluşturuldu ve " + data[2].value + " adresine bir şifre oluşturma erişim linki gönderildi.",
                            type: "success"
                        }
                    });
                } else {
                    if (newUser.fields) {
                        newUser.fields.map((item, i) => {
                            return dispatch({
                                type: "SET_VALUE_ERROR_USERS",
                                payload: {
                                    key: item.field,
                                    helpertext: item.errorMessage
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }

                    dispatch({
                        type: "ERROR_CREATE_USER",
                        payload: newUser
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_CREATE_USER",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_USERS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })

        checkForm.invalidFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_USERS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })


    }
}

const deleteUser = (user, token, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_USER"
    })
    fetch(globalURL + "/auth/deleteUser", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: user.id,
            username: user.username
        })
    })
        .then((result) => result.json())
        .then(async (deleteUser) => {
            if (deleteUser.success) {
                dispatch({
                    type: "FETCH_DELETE_USER",
                    payload: deleteUser,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "Kullanıcı başarıyla silindi.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DELETE_USER",
                    payload: deleteUser
                })
            }

        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_DELETE_USER",
                payload: error
            })
        })
}

const updateUser = (id, token, data, rowIndex, authUser) => dispatch => {
    var checkForm = checkFormFields(data, true);
    if (!checkForm.result) {
        // create
        dispatch({
            type: "GET_UPDATE_USER"
        })
        fetch(globalURL + "/auth/updateUser", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                updatedUser: {
                    name: data[0].value,
                    surname: data[1].value,
                    email: data[2].value,
                    phone: data[3].value,
                    username: data[4].value,
                    role: data[5].value,
                    title: data[6].value,
                    gender: data[7].value !== "" ? data[7].value : "unspecified",
                    dateOfBirth: data[8].value,
                    isBlocked: data[9].value
                }
            })
        })
            .then((result) => result.json())
            .then(async (updateUser) => {
                if (updateUser.success) {
                    if (authUser.id === id) {
                        // me
                        updateUser.data['token'] = authUser.token;
                        updateUser.data['expiresIn'] = authUser.expiresIn;
                        dispatch({
                            type: "AUTH_USER",
                            payload: updateUser.data
                        })

                    }
                    dispatch({
                        type: "FETCH_UPDATE_USER",
                        payload: updateUser,
                        rowIndex: rowIndex
                    });
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[4].value + " kullanıcı bilgileri başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    dispatch({
                        type: "ERROR_UPDATE_USER",
                        payload: updateUser
                    });

                    if (updateUser.fields) {
                        updateUser.fields.map((item, i) => {
                            return dispatch({
                                type: "SET_VALUE_ERROR_USERS",
                                payload: {
                                    key: item.field,
                                    helpertext: item.errorMessage
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });

                    }
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_UPDATE_USER",
                    payload: error
                })
            })
    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_USERS",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        })
    }
}

const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        // get user detail
        dispatch({
            type: "GET_DETAIL_USER"
        })
        fetch(globalURL + "/auth/getUserDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_USER",
                        payload: detail
                    });
                    dispatch({
                        type: "TOGGLE_USER_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    });

                } else {
                    dispatch({
                        type: "ERROR_DETAIL_USER",
                        payload: detail
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_USER",
                    payload: error
                });
            })
    } else {
        // delete alert
        dispatch({
            type: "TOGGLE_USER_MODAL",
            payload: {
                show: true,
                process: process
            }
        });
    }
}


export {
    toggleModal,
    getAll,
    setValue,
    createNewUser,
    tableActions,
    updateUser,
    deleteUser
}