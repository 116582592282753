import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,

} from "../../../../../redux/actions/imageSliderActions";
import {
    Button,
    Modal
} from "react-bootstrap";
import {
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";
import Loading from "../../../../helpers/loading/Loading";
import FileUpload from "../../../../helpers/fileupload/FileUpload";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
// import { globalURL } from "../../../../constants/constants";

function ImageSlidersPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: false,
            filter: null
        },
        {
            dataField: "createdAt",
            text: "Oluşturulma",
            sort: false,
            filter: null,
            formatter: (cell) => {
                console.log(cell)
                return moment(cell).fromNow()
            },
        },
        {
            dataField: "updatedAt",
            text: "Son Değişiklik",
            sort: false,
            filter: null,
            formatter: (cell) => {
                return cell !== null && cell !== undefined ? moment(cell).fromNow() : "Değişiklik Yapılmadı"
            },
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.imageSlider.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.imageSlider.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.imageSlider.process === "create" ? "fa fa-plus" : props.imageSlider.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.imageSlider.process === "create" ? "Resimleri Ekle" : props.imageSlider.process === "update" ? "Detay / Düzenle" : "Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.imageSlider.process === "create" || props.imageSlider.process === "update" ?
                    <div>
                        <div>
                            <FileUpload
                                {...props}
                                multiple={true}
                                dropzoneText={"Lütfen Eklenecek Resimleri Seçiniz 384x323"}
                                stateKey={"images"}
                                uploadedImages={props.imageSlider.images}
                            />
                        </div>
                        {
                            props.imageSlider.process === "update" && props.imageSlider.detail !== null ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>RESİM SLIDER DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {
                                                props.imageSlider.detail.createdBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.imageSlider.detail.createdBy.name[0]).toUpperCase() + String(props.imageSlider.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.imageSlider.detail.createdBy.username}
                                                            />
                                                            <br />
                                                            <small>{moment(props.imageSlider.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.imageSlider.detail.createdAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null

                                            }

                                            {
                                                props.imageSlider.detail.updatedBy !== undefined && props.imageSlider.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.imageSlider.detail.updatedBy.name[0]).toUpperCase() + String(props.imageSlider.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.imageSlider.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.imageSlider.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.imageSlider.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }

                    </div> : props.table.row !== null ?
                        <>
                            Resimleri silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.imageSlider.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.imageSlider.process)}>
                    İptal
                </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.imageSlider.isFetchingActions} onClick={() => {
                    if (props.imageSlider.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "images",
                                    value: props.imageSlider.images,
                                    type: "array",
                                    required: true
                                }

                            ]);
                    } else if (props.imageSlider.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "images",
                                    value: props.imageSlider.images,
                                    type: "array",
                                    required: true
                                }
                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.imageSlider.isFetchingActions ?
                            <span className="spinner spinner-white"></span> :
                            props.imageSlider.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>

        <DataTable
            actionsButtonHidden={props.imageSlider.data.length > 0 ? true : false}
            handleModal={props.toggleModal}
            title="Resim Slayt"
            buttonTitle="Resimleri Ekle"
            cardIcon="fa fa-list-ol"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.imageSlider.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        imageSlider: state.imageSlider,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        tableActions,
        update,
        deleteEntity,

    })(ImageSlidersPage);
