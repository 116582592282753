import React from "react";
import { css } from "@emotion/react";
import Loader from "react-spinners/PuffLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  left : calc(50% - 50px);
  top : calc(50% - 50px);
`;

function App(props) {
    return (
        <div style={{ position: "fixed", top: 0, left: 0, zIndex: 1050, width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.5)", display: props.show === true ? "block" : "none" }}>
            <Loader color={"#4a4a4a"} loading={props.show ? props.show : false} css={override} size={100} />
        </div>
    );
}

export default App;