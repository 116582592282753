import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    deleteEntity,
    tableActions
} from "../../../../../redux/actions/orderCancelLogActions";
import {
    Button,
    Modal,
    Badge
} from "react-bootstrap";
import {
    TextField,
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';
import {
    globalURL
} from "../../../../constants/constants";
import { returnStatusColor } from "../../../../helpers/functions";


function searchImages(item, array) {
    var result = array.find(x => String(x.color) === String(item.stockCard.color.id));
    if (result) {
        return globalURL + "/images/" + result.images[0].name;
    } else {
        return null
    }
}

function CanceledOrdersLogsPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const columns = [
        {
            dataField: "order.orderNumber",
            text: "SİPARİŞ NO",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.orderCancelLog.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "user.email",
            text: "EMAİL",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.orderCancelLog.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "order.paidPrice",
            text: "TUTAR",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.orderCancelLog.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "result",
            text: "İŞLEM DURUMU",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.orderCancelLog.data.length > 0 ? "block" : "none"
                }
            }),
            formatter: (cell) => {
                return <Badge variant={returnStatusColor(cell)} className="mr-1">
                    {cell}
                </Badge>
            },
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "createdAt",
            text: "İPTAL ZAMANI",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: {
                width: 150
            },
            formatter: (cell) => {
                return <div style={{ display: "flex", flexDirection: "column" }}>
                    <small>{moment(cell).format('DD.MM.YYYY HH:mm:ss')}</small>
                    <small>{moment(cell).fromNow()}</small>
                </div>
            }
        },
        {
            dataField: "action",
            text: "",

            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.orderCancelLog.isFetchingDetail || props.orderCancelLog.isFetching} />
        <Modal backdrop="static" size="lg" show={props.orderCancelLog.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.orderCancelLog.process === "create" ? "fa fa-plus" : props.orderCancelLog.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.orderCancelLog.process === "create" ? "Yeni İptal Talebi" : props.orderCancelLog.process === "update" ? "İptal Detayı" : "İptal Talebi Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.orderCancelLog.process === "create" || props.orderCancelLog.process === "update" ?
                    <div>
                        {
                            props.orderCancelLog.detail !== null ?
                                <div className="row">
                                    <div className="col-md-7">
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SİPARİŞ DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Numarası
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.order.orderNumber}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Zamanı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <small>{moment(props.orderCancelLog.detail.order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                        <small>{moment(props.orderCancelLog.detail.order.createdAt).fromNow()}</small>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Sipariş Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(props.orderCancelLog.detail.order.state)} className="mr-1">
                                                            {props.orderCancelLog.detail.order.state}
                                                        </Badge>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <br />
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Resim</TableCell>
                                                    <TableCell>Adet</TableCell>
                                                    <TableCell>Ürün Detayı</TableCell>
                                                    <TableCell>Fiyat</TableCell>
                                                    <TableCell>Toplam</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    props.orderCancelLog.detail.order.cart.cartItems.map((item, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                <TableCell component="th" scope="row">
                                                                    {
                                                                        searchImages(item, item.stockCard.product.stockData) !== null ?
                                                                            <img style={{ height: 75 }} src={searchImages(item, item.stockCard.product.stockData)} /> : null
                                                                    }
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.quantity}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.stockCard.product.productName.tr}<br />
                                                                    {item.stockCard.color.colorName}<br />
                                                                    {item.stockCard.size.sizeCode}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.actualHasDiscount ? item.actualDiscountedPrice.try : item.actualNormalPrice.try}{" TL"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.actualHasDiscount ? item.actualDiscountedPrice.try * item.quantity : item.actualNormalPrice.try * item.quantity}{" TL"}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>

                                    </div>
                                    <div className="col-md-5">
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>KULLANICI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ad Soyad
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.user.name + " " + props.orderCancelLog.detail.user.surname}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Email
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.user.email}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <br />
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ÖDEME DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    props.orderCancelLog.detail.order.bankName ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Banka
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.orderCancelLog.detail.order.bankName}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                                {
                                                    props.orderCancelLog.detail.order.cardFamilyName ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Adı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.orderCancelLog.detail.order.cardFamilyName}
                                                            </TableCell>
                                                        </TableRow> : null

                                                }
                                                {
                                                    props.orderCancelLog.detail.order.cardAssociation ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Şirketi
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.orderCancelLog.detail.order.cardAssociation}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }

                                                {
                                                    props.orderCancelLog.detail.order.cardType ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Kart Tipi
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {props.orderCancelLog.detail.order.cardType}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }


                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Tutar
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.order.price}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Toplam
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.order.paidPrice}{" TL"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Taksit Sayısı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.order.installment}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        3D Ödeme
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {props.orderCancelLog.detail.order.used3D === true ? "Evet" : "Hayır"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ödeme Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(props.orderCancelLog.detail.order.paymentStatus)} className="mr-1">
                                                            {props.orderCancelLog.detail.order.paymentStatus}
                                                        </Badge>

                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    props.orderCancelLog.detail.order.paymentStatus === "failure" ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Hata Mesajı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {JSON.parse(props.orderCancelLog.detail.order.paymentResult).errorMessage}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                            </TableBody>
                                        </Table>
                                        <br />

                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>İŞLEM DETAYI</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        İptal Durumu
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Badge variant={returnStatusColor(props.orderCancelLog.detail.result)} className="mr-1">
                                                            {props.orderCancelLog.detail.result}
                                                        </Badge>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        İptal Zamanı
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <small>{moment(props.orderCancelLog.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                        <small>{moment(props.orderCancelLog.detail.createdAt).fromNow()}</small>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    props.orderCancelLog.detail.result === "failure" ?
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                Hata Detayı
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {JSON.parse(props.orderCancelLog.detail.cancelResult).errorMessage}
                                                            </TableCell>
                                                        </TableRow> : null
                                                }
                                            </TableBody>
                                        </Table>

                                    </div>
                                </div> : null
                        }

                    </div> :
                    props.table.row !== null && props.table.row.order && props.table.row.order.orderNumber ?
                        <>
                            <b>{'"'}{props.table.row.order.orderNumber}{'" '}</b> sipariş numaralı kayıtı silmek üzeresiniz. İşlemi onaylıyor musunuz?
                        </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.orderCancelLog.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.orderCancelLog.process)}>
                    İptal
                </Button>

                {props.orderCancelLog.process === "delete" ? <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.orderCancelLog.isFetchingActions} onClick={() => {
                    props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                }} variant="primary">
                    {
                        props.orderCancelLog.isFetchingActions ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.orderCancelLog.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button> : null}
            </Modal.Footer>
        </Modal>
        <DataTable
            actionsButtonHidden={true}
            handleModal={props.toggleModal}
            title="Kullanıcı Sipariş İptal Logları"
            buttonTitle="Yeni İptal Talebi"
            cardIcon="fa fa-window-close"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.orderCancelLog.data}
            columns={columns}
            token={props.auth.user.token}
            refreshTable={props.getAll}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        orderCancelLog: state.orderCancelLog,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        deleteEntity,
        tableActions,

    })(CanceledOrdersLogsPage);
