import React from "react";
import {
    Snackbar,
    SnackbarContent,
    IconButton
} from "@material-ui/core";

function getCustomColor(alertType) {
    let color = "";
    switch (alertType) {
        case "success":
            color = "#28a745";
            break;
        case "warning":
            color = "#ffa800";
            break;
        case "info":
            color = "#8950fc";
            break;
        case "error":
            color = "#dc3545";
            break;
        default:
            color = "#28a745";
            break;
    }

    return color;
}

export default function CustomAlert(props) {
    const { show, message, type } = props.alertProps;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={show}
            autoHideDuration={3000}
            onClose={() => {
                props.toggleAlert({
                    show: false
                })
            }}
        >
            <SnackbarContent
                style={{ backgroundColor: getCustomColor(type) }}
                message={
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <i style={{ color: "white", marginRight: 5 }} className={type === "success" ? "fa fa-check-circle" : type === "info" ? "fa fa-bell" : "fa fa-exclamation-circle"}></i>
                        <span style={{ color: "white" }}>
                            {message}
                        </span>
                    </div>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() =>
                            props.toggleAlert({
                                show: false
                            })}
                    >
                        <i style={{ color: "white" }} className="fa fa-times"></i>
                    </IconButton>
                ]}
            />
        </Snackbar>
    )
}