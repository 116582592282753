import { globalURL, fetchApiHeaders } from "../../app/constants/constants";


const getActivity = (token) => dispatch => {
    dispatch({
        type: "GET_ACTIVITY"
    })
    Promise.all([
        fetch(globalURL + "/authorizedActivity/getAll", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            }
        }),
        fetch(globalURL + "/stock/getStockCount", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            }
        }),
        fetch(globalURL + "/order/getPendingOrdersCount", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            }
        }),
        fetch(globalURL + "/user/getDashboardData", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            }
        })
    ])
        .then(async (data) => {
            let activity = await data[0].json();
            let stockCount = await data[1].json();
            let pendingOrders = await data[2].json();
            let dashboard = await data[3].json();

            if (activity.success) {
                dispatch({
                    type: "FETCH_ACTIVITY",
                    payload: activity.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ACTIVITY",
                    payload: activity
                })
            }

            if (stockCount.success) {
                dispatch({
                    type: "FETCH_STOCK_COUNT",
                    payload: stockCount.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_STOCK_COUNT",
                    payload: stockCount
                })
            }

            if (pendingOrders.success) {
                dispatch({
                    type: "FETCH_PENDING_ORDERS",
                    payload: pendingOrders.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_PENDING_ORDERS",
                    payload: pendingOrders
                })
            }

            if (dashboard.success) {
                dispatch({
                    type: "FETCH_DASHBOARD",
                    payload: dashboard.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_DASHBOARD",
                    payload: dashboard
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ACTIVITY",
                payload: error
            });
        })
}


export {
    getActivity
}