import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    colors: [],
    categories: [],
    sizes: [],
    discountType: "price",
    freeShipping: false,
    newProduct : false,
    hasDiscount: false,
    isOpenForSale: false,
    productDetails: {
        features: [

        ],
        materials: [

        ],
        sizeAndFit: [

        ]
    },
    productDetailsEng: {
        features: [

        ],
        materials: [

        ],
        sizeAndFit: [

        ]
    },
    combinedWith: [],
    selectedSizes: [],
    selectedSizes_helpertext: "",
    selectedSizes_error: false,
    color: [],
    color_helpertext: "",
    color_error: false,
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    category: [],
    category_helpertext: "",
    category_error: false,
    combinedWith_helpertext: "",
    combinedWith_error: false,
    productName: "",
    productName_helpertext: "",
    productName_error: false,
    productCode: "",
    productCode_helpertext: "",
    productCode_error: false,
    productTitle: "",
    productTitle_helpertext: "",
    productTitle_error: false,
    description: "",
    description_helpertext: "",
    description_error: false,
    productNameEng: "",
    productNameEng_helpertext: "",
    productNameEng_error: false,
    productTitleEng: "",
    productTitleEng_helpertext: "",
    productTitleEng_error: false,
    descriptionEng: "",
    descriptionEng_helpertext: "",
    descriptionEng_error: false,
    shippingPrices: [
        {
            currency: "try",
            currency_helpertext: "",
            currency_error: false,
            price: 15,
            price_helpertext: "",
            price_error: false
        }
    ],
    prices: [
        {
            currency: "try",
            currency_helpertext: "",
            currency_error: false,
            normalPrice: 200,
            discountedPrice: 160,
            normalPrice_helpertext: "",
            normalPrice_error: false,
            discountedPrice_helpertext: "",
            discountedPrice_error: false,
            discount: 40,
            discount_helpertext: "",
            discount_error: false,
        }
    ],
    stockData: [
        {
            color: "",
            color_helpertext: "",
            color_error: false,
            selectedSizes: [],
            selectedSizes_helpertext: "",
            selectedSizes_error: false,
            images: []
        }
    ],

}

let obj = {};
let prices = [];
let data = [];
let id, index;

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_PRODUCTS":
            return {
                ...state,
                isFetching: true,
                isFetchingDetail: false,
                isFetchingActions: false,
                showModal: false,
                error: false
            }
        case "FETCH_ALL_PRODUCTS":
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                colors: action.colors.data,
                categories: action.categories.data,
                sizes: action.sizes.data
            }
        case "ERROR_ALL_PRODUCTS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_PRODUCTS":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_INSERT_PRODUCTS":
            data = state.data;
            data.push(action.payload.data);
            for (let color of state.colors) {
                color['selected'] = false
            }
            return {
                ...state,
                isFetchingActions: false,
                showModal: false,
                data: data,
                detail: action.payload.data,
                discountType: "price",
                freeShipping: false,
                hasDiscount: false,
                isOpenForSale: false,
                productDetails: {
                    features: [

                    ],
                    materials: [

                    ],
                    sizeAndFit: [

                    ]
                },
                productDetailsEng: {
                    features: [

                    ],
                    materials: [

                    ],
                    sizeAndFit: [

                    ]
                },
                combinedWith: [],
                category: [],
                category_helpertext: "",
                category_error: false,
                stockData: [
                    {
                        color: "",
                        color_helpertext: "",
                        color_error: false,
                        selectedSizes: [],
                        selectedSizes_helpertext: "",
                        selectedSizes_error: false,
                        images: []
                    }
                ],
                combinedWith_helpertext: "",
                combinedWith_error: false,
                productName: "",
                productName_helpertext: "",
                productName_error: false,
                productCode: "",
                productCode_helpertext: "",
                productCode_error: false,
                productTitle: "",
                productTitle_helpertext: "",
                productTitle_error: false,
                description: "",
                description_helpertext: "",
                description_error: false,
                productNameEng: "",
                productNameEng_helpertext: "",
                productNameEng_error: false,
                productTitleEng: "",
                productTitleEng_helpertext: "",
                productTitleEng_error: false,
                descriptionEng: "",
                descriptionEng_helpertext: "",
                descriptionEng_error: false,
                shippingPrices: [
                    {
                        currency: "try",
                        currency_helpertext: "",
                        currency_error: false,
                        price: 15,
                        price_helpertext: "",
                        price_error: false
                    }
                ],
                prices: [
                    {
                        currency: "try",
                        currency_helpertext: "",
                        currency_error: false,
                        normalPrice: 200,
                        discountedPrice: 160,
                        normalPrice_helpertext: "",
                        normalPrice_error: false,
                        discountedPrice_helpertext: "",
                        discountedPrice_error: false,
                        discount: 40,
                        discount_helpertext: "",
                        discount_error: false,
                    }
                ]

            }
        case "ERROR_INSERT_PRODUCTS":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "TOGGLE_PRODUCT_MODAL":
            for (let color of state.colors) {
                color['selected'] = false
            }
            if (action.payload.process === "create") {
                return {
                    ...state,
                    process: action.payload.process,
                    showModal: action.payload.show,
                    discountType: "price",
                    freeShipping: false,
                    hasDiscount: false,
                    isOpenForSale: false,
                    productDetails: {
                        features: [

                        ],
                        materials: [

                        ],
                        sizeAndFit: [

                        ]
                    },
                    productDetailsEng: {
                        features: [

                        ],
                        materials: [

                        ],
                        sizeAndFit: [

                        ]
                    },
                    combinedWith: [],
                    category: state.categories.length > 0 ? [state.categories[0].id] : [],
                    category_helpertext: "",
                    category_error: false,
                    stockData: [
                        {
                            color: state.colors.length > 0 ? state.colors[0].id : "",
                            color_helpertext: "",
                            color_error: false,
                            selectedSizes: state.sizes.length > 0 ? [state.sizes[0].id] : [],
                            selectedSizes_helpertext: "",
                            selectedSizes_error: false,
                            images: []
                        }
                    ],
                    combinedWith_helpertext: "",
                    combinedWith_error: false,
                    productName: "",
                    productName_helpertext: "",
                    productName_error: false,
                    productCode: "",
                    productCode_helpertext: "",
                    productCode_error: false,
                    productTitle: "",
                    productTitle_helpertext: "",
                    productTitle_error: false,
                    description: "",
                    description_helpertext: "",
                    description_error: false,
                    productNameEng: "",
                    productNameEng_helpertext: "",
                    productNameEng_error: false,
                    productTitleEng: "",
                    productTitleEng_helpertext: "",
                    productTitleEng_error: false,
                    descriptionEng: "",
                    descriptionEng_helpertext: "",
                    descriptionEng_error: false,
                    shippingPrices: [
                        {
                            currency: "try",
                            currency_helpertext: "",
                            currency_error: false,
                            price: 15,
                            price_helpertext: "",
                            price_error: false
                        }
                    ],
                    prices: [
                        {
                            currency: "try",
                            currency_helpertext: "",
                            currency_error: false,
                            normalPrice: 200,
                            discountedPrice: 160,
                            normalPrice_helpertext: "",
                            normalPrice_error: false,
                            discountedPrice_helpertext: "",
                            discountedPrice_error: false,
                            discount: 40,
                            discount_helpertext: "",
                            discount_error: false,
                        }
                    ]
                }
            } else {
                return {
                    ...state,
                    process: action.payload.process,
                    showModal: action.payload.show
                }
            }


        case "SET_VALUE_PRODUCT":
            if (action.payload.key === "discountType") {
                if (action.payload.value === "price") {
                    state.prices.map((item, i) => {
                        return state.prices[i].discount = state.prices[i].normalPrice - state.prices[i].discountedPrice;
                    })
                } else {
                    state.prices.map((item, i) => {
                        state.prices[i].discount = 20;
                        return state.prices[i].discountedPrice = state.prices[i].normalPrice - (state.prices[i].normalPrice * state.prices[i].discount / 100);
                    })
                }
            }
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_PRODUCT":
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_PRODUCT":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_PRODUCT":
            state.productDetails.features = [];
            state.productDetails.materials = [];
            state.productDetails.sizeAndFit = [];

            state.productDetailsEng.features = [];
            state.productDetailsEng.materials = [];
            state.productDetailsEng.sizeAndFit = [];

            state.combinedWith = [];

            var stockData = [];
            for (let item of action.payload.data.stockData) {
                let imgs = [];
                obj = {};
                obj = {
                    color: "",
                    color_helpertext: "",
                    color_error: false,
                    selectedSizes: [],
                    selectedSizes_helpertext: "",
                    selectedSizes_error: false,
                    images: []
                };

                obj.color = item.color;
                obj.selectedSizes = item.sizes;

                item.images.map((image, i) => {
                    return imgs.push(globalURL + "/images/" + image.name);
                });

                obj.images = imgs;
                stockData.push(obj);
            }

            for (let item of action.payload.data.features.tr) {
                obj = {};
                obj = {
                    value: "",
                    value_helpertext: "",
                    value_error: false
                };

                obj.value = item;
                state.productDetails.features.push(obj);
            }


            for (let item of action.payload.data.features.eng) {
                obj = {};
                obj = {
                    value: "",
                    value_helpertext: "",
                    value_error: false
                };

                obj.value = item;
                state.productDetailsEng.features.push(obj);
            }

            for (let item of action.payload.data.materials.tr) {
                obj = {};
                obj = {
                    value: "",
                    value_helpertext: "",
                    value_error: false
                };
                obj.value = item;
                state.productDetails.materials.push(obj);
            }

            for (let item of action.payload.data.materials.eng) {
                obj = {};
                obj = {
                    value: "",
                    value_helpertext: "",
                    value_error: false
                };
                obj.value = item;
                state.productDetailsEng.materials.push(obj);
            }

            for (let item of action.payload.data.sizeAndFit.tr) {
                obj = {};
                obj = {
                    value: "",
                    value_helpertext: "",
                    value_error: false
                };
                obj.value = item;
                state.productDetails.sizeAndFit.push(obj);
            }

            for (let item of action.payload.data.sizeAndFit.eng) {
                obj = {};
                obj = {
                    value: "",
                    value_helpertext: "",
                    value_error: false
                };
                obj.value = item;
                state.productDetailsEng.sizeAndFit.push(obj);
            }

            if (action.payload.data.shippingPrice !== null) {
                state.shippingPrices = [];
                for (let item of Object.keys(action.payload.data.shippingPrice)) {
                    var shippingPrice = {
                        currency: "",
                        currency_helpertext: "",
                        currency_error: false,
                        price: 0,
                        price_helpertext: "",
                        price_error: false
                    };

                    shippingPrice.currency = item;
                    shippingPrice.price = action.payload.data.shippingPrice[item];
                    state.shippingPrices.push(shippingPrice);
                }
            } else {
                state.shippingPrices = [];
                shippingPrice = {
                    currency: "try",
                    currency_helpertext: "",
                    currency_error: false,
                    price: 15,
                    price_helpertext: "",
                    price_error: false
                };
                state.shippingPrices.push(shippingPrice);
            }

            if (action.payload.data.normalPrice !== null) {
                state.prices = [];
                for (let item of Object.keys(action.payload.data.normalPrice)) {
                    var price = {
                        currency: "",
                        currency_helpertext: "",
                        currency_error: false,
                        normalPrice: 0,
                        discountedPrice: 0,
                        normalPrice_helpertext: "",
                        normalPrice_error: false,
                        discountedPrice_helpertext: "",
                        discountedPrice_error: false,
                        discount: 0,
                        discount_helpertext: "",
                        discount_error: false,
                    };

                    price.currency = item;
                    price.normalPrice = action.payload.data.normalPrice[item];
                    if(action.payload.data.discountedPrice !== null){
                        price.discountedPrice = action.payload.data.discountedPrice[item];
                    }
                    if(action.payload.data.discount !== null){
                        price.discount = action.payload.data.discount[item];
                    }
                    state.prices.push(price);
                }
            } else {
                state.prices = [];
                price = {
                    currency: "try",
                    currency_helpertext: "",
                    currency_error: false,
                    normalPrice: 200,
                    discountedPrice: 160,
                    normalPrice_helpertext: "",
                    normalPrice_error: false,
                    discountedPrice_helpertext: "",
                    discountedPrice_error: false,
                    discount: 40,
                    discount_helpertext: "",
                    discount_error: false,
                };
                state.prices.push(price);
            }

            action.payload.data.combinedWith.map((product, i) => {
                return state.combinedWith.push(product.id);
            });

            return {
                ...state,
                isFetchingDetail: false,
                stockData: stockData,
                newProduct : action.payload.data.newProduct,
                detail: action.payload.data,
                category: action.payload.data.category,
                freeShipping: !action.payload.data.freeShipping,
                hasDiscount: action.payload.data.hasDiscount,
                discountType : action.payload.data.discountType,
                isOpenForSale: action.payload.data.isOpenForSale,
                productName: action.payload.data.productName.tr,
                productNameEng: action.payload.data.productName.eng,
                productCode: action.payload.data.productCode,
                productTitle: action.payload.data.productTitle.tr,
                productTitleEng: action.payload.data.productTitle.eng,
                description: action.payload.data.description.tr,
                descriptionEng: action.payload.data.description.eng
            }
        case "ERROR_DETAIL_PRODUCT":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_PRODUCT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_PRODUCT":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_UPDATE_PRODUCT":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_PRODUCT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_PRODUCT":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_PRODUCT":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }

        case "SET_SHIPPING_PRICES":
            return {
                ...state,
                shippingPrices: action.payload
            }
        case "SET_PRICES":
            prices = action.payload;
            switch (action.field) {
                case "normalPrice":
                    if (state.discountType === "percent") {
                        prices[action.index].discountedPrice = prices[action.index].normalPrice - (prices[action.index].normalPrice * prices[action.index].discount / 100);
                    } else {
                        if (prices[action.index].discountedPrice >= prices[action.index].normalPrice) {
                            prices[action.index].discountedPrice = 0;
                        }

                        prices[action.index].discount = Number(prices[action.index].normalPrice - prices[action.index].discountedPrice).toFixed(2);
                    }
                    break;
                case "discount":
                    if (state.discountType === "percent") {
                        prices[action.index].discountedPrice = Number(prices[action.index].normalPrice - (prices[action.index].normalPrice * prices[action.index].discount / 100)).toFixed(2);
                    }
                    break;
                case "discountedPrice":
                    if (prices[action.index].discountedPrice >= prices[action.index].normalPrice) {
                        prices[action.index].discountedPrice = prices[action.index].normalPrice - 10;
                    } else if (prices[action.index].discountedPrice < 0) {
                        prices[action.index].discountedPrice = 0;
                    }

                    prices[action.index].discount = prices[action.index].normalPrice - prices[action.index].discountedPrice;
                    break;
                default:
                    break;
            }
            return {
                ...state,
                prices: prices
            }
        case "ADD_PRICES_ROW":
            return {
                ...state,
                prices: action.payload
            }

        case "ADD_SHIPPING_PRICES_ROW":
            return {
                ...state,
                shippingPrices: action.payload
            }
        case "REMOVE_PRICES_ROW":
            prices = state.prices;
            prices.splice(action.payload, 1);
            return {
                ...state,
                prices: prices
            }
        case "REMOVE_SHIPPING_PRICES_ROW":
            prices = state.shippingPrices;
            prices.splice(action.payload, 1);
            return {
                ...state,
                shippingPrices: prices
            }
        case "ADD_DETAILS_ROW":
            obj = {};
            obj = {
                value: "",
                value_helpertext: "",
                value_error: false
            };
            data = state[action.payload.field];
            data[action.payload.subField].push(obj);
            return {
                ...state,
                [action.payload.field]: data
            }
        case "REMOVE_DETAILS_ROW":
            data = state[action.payload.field];
            data[action.payload.subField].splice(action.payload.index, 1);
            return {
                ...state,
                [action.payload.field]: data
            }
        case "SET_DETAILS_DATA":
            data = state[action.payload.field];
            data[action.payload.subField][action.payload.index].value = action.payload.value;
            return {
                ...state,
                [action.payload.field]: data
            }
        case "ADD_STOCK_DATA_ROW":
            return {
                ...state,
                colors: action.payload.colors,
                stockData: action.payload.stockData
            }
        case "REMOVE_STOCK_DATA_ROW":
            id = state.stockData[action.payload].color;
            index = state.colors.findIndex(x => x.id === id);
            state.colors[index].selected = false;

            data = state.stockData;
            data.splice(action.payload, 1);

            return {
                ...state,
                stockData: data

            }
        case "SET_STOCK_DATA":
            data = state[action.payload.field];
            var exValue = data[action.payload.index][action.payload.key];
            data[action.payload.index][action.payload.key] = action.payload.value;

            if (action.payload.key === "color") {
                id = action.payload.value;
                index = state.colors.findIndex(x => x.id === id);
                if (exValue !== "") {
                    var indexEx = state.colors.findIndex(x => x.id === exValue);
                    state.colors[indexEx].selected = false;
                }
                state.colors[index].selected = true;
            }

            return {
                ...state,
                stockData: data
            }
        default:
            return state
    }

}
