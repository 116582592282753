
const initialState = {
    cities: [],
    districts: [],
    streets: [],
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    isRefreshing: false,
    error: false,
    process: "",
    selected: "",
    selectedCity: null,
    selectedDistrict: null,
    processDistrict: "",
    processStreet: "",
    showDistrictModal: false,
    showStreetModal: false,
    nameDistrict: "",
    codeDistrict: "",
    nameDistrict_helpertext: "",
    codeDistrict_helpertext: "",
    nameDistrict_error: false,
    codeDistrict_error: false,
    nameStreet: "",
    codeStreet: "",
    nameStreet_helpertext: "",
    codeStreet_helpertext: "",
    nameStreet_error: false,
    codeStreet_error: false,
    isFetchingActionsDistrict: false,
    isFetchingActionsStreet: false,
    detailDistrict: null,
    detailStreet: null,
   
}

let data;

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ADDRESS":
            return {
                ...state,
                selectedCity: null,
                selectedDistrict: null,
                isFetching: true,
                error: false
            }
        case "FETCH_ADDRESS":
            return {
                ...state,
                isFetching: false,
                cities: action.payload,
                districts: [],
                streets: []
            }
        case "GET_DISTRICTS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_DISTRICTS":
            return {
                ...state,
                isFetching: false,
                districts: action.payload.data,
                streets: []
            }
        case "GET_STREETS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_STREETS":
            return {
                ...state,
                isFetching: false,
                streets: action.payload.data
            }
        case "GET_LOAD_ADDRESS":
            return {
                ...state,
                isRefreshing: true,
                cities: [],
                districts: [],
                streets: [],
                error: false
            }
        case "FETCH_LOAD_ADDRESS":
            return {
                ...state,
                isRefreshing: false,
                cities: action.payload,
                districts: [],
                streets: []
            }
        case "ERROR_ADDRESS":
            return {
                ...state,
                isFetching: false,
                isRefreshing: false,
                error: true
            }
        case "TOGGLE_DISTRICT_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showDistrictModal: action.payload.show,
                nameDistrict: action.payload.show ? state.nameDistrict : "",
                codeDistrict: action.payload.show ? state.codeDistrict : "",
                nameDistrict_helpertext: "",
                codeDistrict_helpertext: "",
                nameDistrict_error: false,
                codeDistrict_error: false,
            }
        case "TOGGLE_STREET_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showStreetModal: action.payload.show,
                nameStreet: action.payload.show ? state.nameStreet : "",
                codeStreet: action.payload.show ? state.codeStreet : "",
                nameStreet_helpertext: "",
                codeStreet_helpertext: "",
                nameStreet_error: false,
                codeStreet_error: false,
            }
        case "SET_VALUE_ADDRESS":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_ADDRESS":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_DISTRICT":
            return {
                ...state,
                isFetchingDetail: true,
                error: false

            }
        case "FETCH_DETAIL_DISTRICT":
            return {
                ...state,
                isFetchingDetail: false,
                nameDistrict: action.payload.data.districtName,
                codeDistrict: action.payload.data.districtCode,
                nameDistrict_helpertext: "",
                codeDistrict_helpertext: "",
                nameDistrict_error: false,
                codeDistrict_error: false
            }
        case "GET_DETAIL_STREET":
            return {
                ...state,
                isFetchingDetail: true,
                error: false

            }
        case "FETCH_DETAIL_STREET":
            return {
                ...state,
                isFetchingDetail: false,
                nameStreet: action.payload.data.streetName,
                codeStreet: action.payload.data.streetCode,
                nameStreet_helpertext: "",
                codeStreet_helpertext: "",
                nameStreet_error: false,
                codeStreet_error: false
            }
        case "GET_UPDATE_DISTRICT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_DISTRICT":
            state.districts[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                showDistrictModal: false
            }
        case "GET_UPDATE_STREET":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_STREET":
            state.streets[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                showStreetModal: false
            }
        case "GET_DELETE_DISTRICT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_DISTRICT":
            state.districts.splice(action.rowIndex, 1);
            if (state.selectedDistrict.value === action.id) {
                state.streets = []
            }
            return {
                ...state,
                isFetchingActions: false,
                showDistrictModal: false
            }
        case "GET_DELETE_STREET":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_STREET":
            state.streets.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                showStreetModal: false
            }
        case "GET_CREATE_STREET":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_CREATE_STREET":
            data = state.streets;
            data.push(action.payload.data)
            return {
                ...state,
                isFetchingActions: false,
                showStreetModal: false,
                streets: data
            }
        case "GET_CREATE_DISTRICT":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_CREATE_DISTRICT":
            data = state.districts;
            data.push(action.payload.data)
            return {
                ...state,
                isFetchingActions: false,
                showDistrictModal: false,
                districts: data
            }
        default:
            return state
    }

}
