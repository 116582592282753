import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../constants/moment';
import Widget from "../../../helpers/widget/Dashboard";
import {
  getActivity,

} from "../../../../redux/actions/dashboardActions";
import {
  Paper
} from "@material-ui/core"

function getBadgeColor(action) {
  var className = "";
  switch (action) {
    case "create":
      className = "text-success";
      break;
    case "delete":
      className = "text-danger";
      break;
    case "update":
      className = "text-warning";
      break;
    default:
      className = "";
      break;
  }
  return className;
}


function DashboardPage(props) {
  useEffect(() => {
    props.getActivity(props.auth.user.token);
    return () => {
      // will unMount 
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return <>
    <div className="row">
      <div className="col-md-8">
        <Widget {...props.dashboard} />
      </div>
      <div className="col-md-4">
        <Paper>
          {
            props.dashboard.activity.length > 0 ?
              <div className={`card card-custom`}>
                <div className="card-body pt-4">
                  <div className="timeline timeline-6 mt-3">
                    {
                      props.dashboard.activity.map((item, i) => {
                        return (
                          <div key={i} className="timeline-item align-items-start">
                            <div className="timeline-label font-weight-bolder text-dark-75">
                              {moment(item.createdAt).format('HH:mm')}
                            </div>
                            <div className="timeline-badge">
                              <i className={`fa fa-genderless ${getBadgeColor(item.actionType)} icon-xl`}></i>
                            </div>
                            {
                              item.type === "action" ?
                                <div className="font-weight-mormal timeline-content pl-3">
                                  <span>{item.description}</span><br />
                                  <small className={item.isSystemLog ? "text-info font-weight-bold" : "font-weight-bold"}>{item.isSystemLog ? "sistem" : item.user !== null ? item.user.username.toLowerCase() : ""}</small><br />
                                  <small className="text-muted" >{moment(item.createdAt).fromNow()}</small>
                                </div>
                                : <div className="font-weight-mormal timeline-content pl-3">
                                  {item.description.toLowerCase()}<br />
                                  <small className="font-weight-bold">{item.user !== null ? item.user.username.toLowerCase() : ""}</small><br />
                                  <small className="text-muted">{moment(item.createdAt).fromNow()}</small>
                                </div>
                            }
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
              </div> :
              <small>Kayıtlı Aktivite Bulunamadı.</small>
          }
        </Paper>
      </div>
    </div>
  </>
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps,
  {
    getActivity,

  })(DashboardPage);