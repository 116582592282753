import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import {
    setValue,
    userCreatePassword,
    toggleAlert
} from "../../../../redux/actions/createPasswordActions";
import {
    TextField
} from "@material-ui/core";
import CustomAlert from "../../../helpers/alert/CustomAlert";

function CreatePassword(props) {
    return (
        <>
            <CustomAlert
                toggleAlert={props.toggleAlert}
                alertProps={props.createPassword.alert}
            />
            {
                props.createPassword.userCreated ?
                    <Redirect to="/auth/login" /> : null
            }
            <div className="login-form login-signin" id="kt_login_signin_form">
                <div className="text-center">
                    <h3 className="font-size-h1">
                        Parola Oluştur
                     </h3>
                </div>
                <form className="form">
                    <TextField
                        onChange={(event) => props.setValue("password", event.target.value)}
                        value={props.createPassword.password}
                        error={props.createPassword.password_error}
                        label="Şifre"
                        type="password"
                        name="text"
                        autoComplete="off"
                        margin="normal"
                        variant="outlined"
                        placeholder="Şifre"
                        FormHelperTextProps={{
                            style: {
                                marginLeft: 0,
                                marginBottom: -10
                            }
                        }}
                        helperText={props.createPassword.password_helpertext}
                    />
                    <TextField
                        error={props.createPassword.repassword_error}
                        onChange={(event) => props.setValue("repassword", event.target.value)}
                        value={props.createPassword.repassword}
                        label="Şifre Tekrar"
                        type="password"
                        name="password"
                        autoComplete="off"
                        margin="normal"
                        variant="outlined"
                        placeholder="Şifre Tekrar"
                        helperText={props.createPassword.repassword_helpertext}
                        FormHelperTextProps={{
                            style: {
                                marginLeft: 0
                            }
                        }}
                    />
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            disabled={props.createPassword.isFetching}
                            onClick={() => props.userCreatePassword([
                                {
                                    key: 'password',
                                    value: props.createPassword.password.trim(),
                                    required: true
                                },
                                {
                                    key: 'repassword',
                                    value: props.createPassword.repassword.trim(),
                                    required: true
                                }
                            ], props.match.params.token)}
                            type="button"
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>Kaydet</span>
                            {props.createPassword.isFetching && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </form>
                {/*end::Form*/}
            </div>
        </>
    );
}



const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        createPassword: state.createPassword
    }
}

export default connect(mapStateToProps,
    {
        setValue,
        userCreatePassword,
        toggleAlert
    })(CreatePassword);
