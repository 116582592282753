import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";

const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_TITLE_MODAL",
        payload: {
            show: show,
            process: process
        }
    })

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_TITLE",
        payload: {
            key: key,
            value: value
        }
    })
}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_TITLES"
    })
    fetch(globalURL + "/title/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (titles) => {
            if (titles.success) {
                dispatch({
                    type: "FETCH_ALL_TITLES",
                    payload: titles.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_ALL_TITLES",
                    payload: titles
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_ALL_TITLES",
                payload: error
            })
        })
}

const createNew = (token, data) => dispatch => {
    dispatch({
        type: "GET_INSERT_TITLES"
    })
    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        fetch(globalURL + "/title/create", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                name: data[0].value,
                code: data[1].value
            })
        })
            .then((result) => result.json())
            .then(async (insert) => {
                if (insert.success) {
                    dispatch({
                        type: "FETCH_INSERT_TITLES",
                        payload: insert
                    });
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " görevi başarıyla oluşturuldu",
                            type: "success"
                        }
                    });
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });
                    dispatch({
                        type: "ERROR_INSERT_TITLES",
                        payload: insert
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_INSERT_TITLES",
                    payload: error
                })
            })
    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_TITLE",
                payload: {
                    ...item,
                    helpertext: item.key === "name" ? "Lütfen Görev Adı Giriniz" : "Lütfen Görev Kodu Giriniz"
                }
            })
        })
    }

}

const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_TITLE"
        })
        fetch(globalURL + "/title/getDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_TITLE",
                        payload: detail
                    })
                    dispatch({
                        type: "TOGGLE_TITLE_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });
                    dispatch({
                        type: "ERROR_DETAIL_TITLE",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_TITLE",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_TITLE_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}

const update = (token, id, data, rowIndex) => dispatch => {
    dispatch({
        type: "GET_UPDATE_TITLE"
    });

    var checkForm = checkFormFields(data, false);
    if (!checkForm.result) {
        // update
        fetch(globalURL + "/title/update", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                name: data[0].value,
                code: data[1].value
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_TITLE",
                        payload: update,
                        rowIndex: rowIndex
                    });
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: data[0].value + " görevi başarıyla güncellendi.",
                            type: "success"
                        }
                    });
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });
                    dispatch({
                        type: "ERROR_UPDATE_TITLE",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_UPDATE_TITLE",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_TITLE",
                payload: {
                    ...item,
                    helpertext: item.key === "name" ? "Lütfen Görev Adı Giriniz" : "Lütfen Lütfen Görev Kodu Giriniz"
                }
            })
        })
    }
}

const deleteEntity = (token, row, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_TITLE"
    })
    fetch(globalURL + "/title/delete", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id,
            name: row.titleName
        })
    })
        .then((result) => result.json())
        .then(async (deleteRecord) => {
            if (deleteRecord.success) {
                dispatch({
                    type: "FETCH_DELETE_TITLE",
                    payload: deleteRecord,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "Görev başarıyla silindi.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DELETE_TITLE",
                    payload: deleteRecord
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_DELETE_TITLE",
                payload: error
            })
        })
}


export {
    toggleModal,
    setValue,
    getAll,
    createNew,
    tableActions,
    update,
    deleteEntity
}