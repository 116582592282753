const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    name: "",
    code: "",
    name_helpertext: "",
    code_helpertext: "",
    name_error: false,
    code_error: false,
   
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_SIZES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_SIZES":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_SIZES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_INSERT_SIZES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_SIZES":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data,
                name: "",
                code: ""
            }
        case "ERROR_INSERT_SIZES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_SIZE_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                name: action.payload.show ? state.name : "",
                code: action.payload.show ? state.code : "",
                name_helpertext: "",
                code_helpertext: "",
                name_error: false,
                code_error: false,
            }
        case "SET_VALUE_SIZE":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_SIZE":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_SIZE":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_SIZE":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                name: action.payload.data.sizeName,
                code: action.payload.data.sizeCode,
                isActive: action.payload.data.isActive
            }
        case "ERROR_DETAIL_SIZE":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_SIZE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_SIZE":
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                name: "",
                code: "",
                name_helpertext: "",
                code_helpertext: "",
                name_error: false,
                code_error: false
            }
        case "ERROR_UPDATE_SIZE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_SIZE":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_SIZE":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_SIZE":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
