import React, { useEffect } from "react";
import {

    toggleModal,
    getAllStockCards,
    setValue,
    tableActions,
    updateStocks
} from "../../../../../redux/actions/stockManagementActions";
import { connect } from 'react-redux';
import DataTable from "../../../../helpers/datatable/DataTable";
import moment from '../../../../constants/moment';
import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import { textFilter } from 'react-bootstrap-table2-filter';
import {
    Button,
    Modal,
    Tab,
    Tabs
} from "react-bootstrap";
import {
    TextField,
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import {
    Card,
    CardBody
} from "../../../../../_metronic/_partials/controls";
import actionsFormatter from "../../../../helpers/datatable/formatters/stockFormatter";
import {
    globalURL
} from "../../../../constants/constants";

function StockManagementPage(props) {
    useEffect(() => {
        props.getAllStockCards(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.stockCount >= 10) {
            style.backgroundColor = '#ffffff';
        } else if (row.stockCount < 10 && row.stockCount > 5) {
            style.backgroundColor = '#ffffcc';
        } else if (row.stockCount <= 5 && row.stockCount > 0) {
            style.backgroundColor = '#ffe6e6';
        } else {
            style.backgroundColor = '#f2f2f2';
        }

        return style;
    };

    const columns = [
        {
            dataField: "image.name",
            text: "RESİM",
            sort: false,
            filter: null,
            formatter: (item) => {
                return item ? <img src={globalURL + "/images/" + item} style={{ height: 90, width: 60 }} /> : null
            }
        },
        {
            dataField: "stockCode",
            text: "Stok Kodu",
            sort: true,
            editable: false,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCards.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "product.productName.tr",
            text: "Ürün Adı",
            sort: true,
            editable: false,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCards.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "color.colorName",
            text: "Renk",
            sort: true,
            editable: false,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCards.length > 0 ? "block" : "none",
                    width: 75
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "size.sizeName",
            text: "BEDEN",
            sort: true,
            editable: false,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCards.length > 0 ? "block" : "none",
                    width: 75
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "stockCount",
            text: "Adet",
            editable: true,
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCards.length > 0 ? "block" : "none",
                    width: 75
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                width: 50,
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    const columnsByProduct = [
        {
            dataField: "_id.productName.tr",
            text: "Ürün Adı (TR)",
            sort: true,
            editable: false,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCardsByProduct.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "_id.productCode",
            text: "ÜRÜN KODU",
            sort: true,
            editable: false,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCardsByProduct.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "stockCount",
            text: "TOPLAM STOK ADET",
            editable: true,
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.stockManagement.stockCardsByProduct.length > 0 ? "block" : "none",
                    width: 75
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        }
    ];

    return <>
        <Loading show={props.stockManagement.isFetchingActions || props.stockManagement.isFetching} />
        <Modal backdrop="static" size="lg" show={props.stockManagement.showModal} onHide={() => props.toggleModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={"fa fa-edit"}></i>
                    Stok Gir / Güncelle <br />
                    <h6 style={{ fontWeight: "300" }}>{props.stockManagement.detail !== null ? props.stockManagement.detail.stockCode + " / " + props.stockManagement.detail.product.productName.tr : ""}</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    label="Adet"
                    style={{ margin: 8 }}
                    placeholder="Adet"
                    autoComplete="off"
                    error={props.stockManagement.stockQuantity_error}
                    value={props.stockManagement.stockQuantity}
                    onChange={(event) => props.setValue("stockQuantity", event.target.value)}
                    helperText={props.stockManagement.stockQuantity_helpertext}
                    fullWidth
                    type="number"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    FormHelperTextProps={{
                        style: {
                            marginLeft: 0
                        }
                    }}
                />
                <br />
                {
                    props.stockManagement.detail !== null ?
                        <>
                            <div className="separator separator-dashed my-7"></div>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>İŞLEM GEÇMİŞİ</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.stockManagement.detail.createdBy !== null ?
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Chip
                                                        style={{ marginLeft: -5 }}
                                                        avatar={<Avatar>{String(props.stockManagement.detail.createdBy.name[0]).toUpperCase() + String(props.stockManagement.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                        label={props.stockManagement.detail.createdBy.username}
                                                    /><br />
                                                    <small>{moment(props.stockManagement.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                    <small>{moment(props.stockManagement.detail.createdAt).fromNow()}</small>
                                                </TableCell>
                                            </TableRow> : null
                                    }

                                    {
                                        props.stockManagement.detail.updatedBy !== undefined && props.stockManagement.detail.updatedBy !== null ?
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Chip
                                                        style={{ marginLeft: -5 }}
                                                        avatar={<Avatar>{String(props.stockManagement.detail.updatedBy.name[0]).toUpperCase() + String(props.stockManagement.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                        label={props.stockManagement.detail.updatedBy.username}
                                                    /><br />
                                                    <small>{moment(props.stockManagement.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                    <small>{moment(props.stockManagement.detail.updatedAt).fromNow()}</small>
                                                </TableCell>
                                            </TableRow> : null
                                    }
                                </TableBody>
                            </Table></> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.stockManagement.isFetchingActions} onClick={() => props.toggleModal(false)} variant="secondary">
                    İptal
                </Button>
                <Button disabled={props.stockManagement.isFetchingActions} onClick={() => props.updateStocks(props.auth.user.token, props.table.row._id, props.stockManagement.stockQuantity, props.table.index, props.table.row)} style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} variant="primary">
                    {
                        props.stockManagement.isFetchingActions ?
                            "İşleniyor..." :
                            "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="row">
            <div className="col-md-12">
                <Card>
                    <CardBody>
                        <Tabs defaultActiveKey="stock" transition={false}>
                            <Tab eventKey="stock" title="Stok Kartları">
                                <DataTable
                                    removeTopBorder
                                    token={props.auth.user.token}
                                    refreshTable={props.getAllStockCards}
                                    actionsButtonHidden={true}
                                    title="Stok Kartları"
                                    buttonTitle="Yeni Ürün"
                                    cardIcon="fa fa-wallet"
                                    buttonIcon="fa fa-plus"
                                    searchPlaceholder="Ara"
                                    data={props.stockManagement.stockCards}
                                    columns={columns}
                                    tableKey="_id"
                                    rowStyle={rowStyle}
                                />
                            </Tab>
                            <Tab eventKey="dashboard" title="Ürün Bazlı Stoklar">
                                <DataTable
                                    removeTopBorder
                                    token={props.auth.user.token}
                                    refreshTable={props.getAllStockCards}
                                    actionsButtonHidden={true}
                                    title="Ürün Bazlı Stoklar"
                                    buttonTitle="Yeni Ürün"
                                    cardIcon="fa fa-wallet"
                                    buttonIcon="fa fa-plus"
                                    searchPlaceholder="Ara"
                                    data={props.stockManagement.stockCardsByProduct}
                                    columns={columnsByProduct}
                                    tableKey="_id.id"
                                    rowStyle={rowStyle}
                                />
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card>
            </div>
        </div>
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        stockManagement: state.stockManagement,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {

        getAllStockCards,
        toggleModal,
        setValue,
        tableActions,
        updateStocks
    })(StockManagementPage);