import { globalURL, fetchApiHeaders } from "../../app/constants/constants";

const refreshCurrency = (token) => dispatch => {
    dispatch({
        type: "GET_REFRESH_CURRENCY"
    })
    fetch(globalURL + "/currency/refresh", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (currencies) => {
            if (currencies.success) {
                dispatch({
                    type: "FETCH_REFRESH_CURRENCY",
                    payload: currencies.data
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: " Tüm döviz kurları başarıyla güncellendi.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_CURRENCY",
                    payload: currencies
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_CURRENCY",
                payload: error
            });
        })
}


const getLastCurrency = (token) => dispatch => {
    dispatch({
        type: "GET_CURRENCY"
    })
    fetch(globalURL + "/currency/getCurrent", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (currencies) => {
            if (currencies.success) {
                dispatch({
                    type: "FETCH_CURRENCY",
                    payload: currencies.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_CURRENCY",
                    payload: currencies
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_CURRENCY",
                payload: error
            });
        })
}


export {
    getLastCurrency,
    refreshCurrency
}