import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
  setValue,
  getAll,
  update
} from "../../../../../redux/actions/configurationActions";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Avatar,
  TextField,
  Switch,
} from "@material-ui/core";
import {
  Button
} from "react-bootstrap";

import Loading from "../../../../helpers/loading/Loading";

function Configuration(props) {
  useEffect(() => {
    props.getAll(props.auth.user.token);
    return () => {
      // will unMount 
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return !props.configuration.isFetching ? <>
    <div className="row">
      <div className="col-md-6">
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>PARAMETRE</TableCell>
                <TableCell>DEĞER</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">Minimum Sepet Tutarı (TRY)</TableCell>
                <TableCell component="th" scope="row">
                  {props.configuration.minCartTotal}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Vergi (%)
                </TableCell>
                <TableCell component="th" scope="row">{props.configuration.tax}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Ortalama Poşet Gideri / Adet (EUR)</TableCell>
                <TableCell component="th" scope="row">{props.configuration.avaragePackageCost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Ortalama Kart Gideri / Adet (EUR)</TableCell>
                <TableCell component="th" scope="row">{props.configuration.avarageCardCost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Ortalama Kargo Gideri / Ürün (TRY)</TableCell>
                <TableCell component="th" scope="row">{props.configuration.avarageShippingCost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Aylık Max. Sipariş İptali Hakkı</TableCell>
                <TableCell component="th" scope="row">{props.configuration.maxCancelPerMonth}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Aylık Max. Sipariş İade Hakkı</TableCell>
                <TableCell component="th" scope="row">{props.configuration.maxRefundPerMonth}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Satışa Açık / Kapalılık Durumu </TableCell>
                <TableCell component="th" scope="row">{props.configuration.isOpenForSale === true ? "Açık" : "Kapalı"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Yurtdışı Satışa Açık</TableCell>
                <TableCell component="th" scope="row">{props.configuration.isOpenForAbroad === true ? "Açık" : "Kapalı"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Dövizle İşleme Açık</TableCell>
                <TableCell component="th" scope="row">{props.configuration.isOpenCurrency === true ? "Açık" : "Kapalı"}</TableCell>
              </TableRow>

              {
                props.configuration.data.createdBy !== null ?
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Oluşturan Kullanıcı / Zamanı
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        style={{ marginLeft: -5 }}
                        avatar={<Avatar>{String(props.configuration.data.createdBy.name[0]).toUpperCase() + String(props.configuration.data.createdBy.surname[0]).toUpperCase()}</Avatar>}
                        label={props.configuration.data.createdBy.username}
                      />
                      <br />
                      <small>{moment(props.configuration.data.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                      <small>{moment(props.configuration.data.createdAt).fromNow()}</small>
                    </TableCell>
                  </TableRow> : null
              }
              {
                props.configuration.data.updatedBy ?
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Son Güncelleyen Kullanıcı / Zamanı
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        style={{ marginLeft: -5 }}
                        avatar={<Avatar>{String(props.configuration.data.updatedBy.name[0]).toUpperCase() + String(props.configuration.data.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                        label={props.configuration.data.updatedBy.username}
                      />
                      <br />
                      <small>{moment(props.configuration.data.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                      <small>{moment(props.configuration.data.updatedAt).fromNow()}</small>
                    </TableCell>
                  </TableRow> : null
              }
            </TableBody>
          </Table>

        </Paper>
      </div>
      <div className="col-md-6">
        <Paper>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <br />
              <TextField
                label="Minimum Sepet Tutarı (TRY)"
                placeholder="Minimum Sepet Tutarı (TRY)"
                type="number"
                autoComplete="off"
                error={props.configuration.minCartTotal_error}
                value={props.configuration.minCartTotal}
                onChange={(event) => props.setValue("minCartTotal", event.target.value)}
                helperText={props.configuration.minCartTotal_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />
              <TextField
                label="Vergi (%)"
                placeholder="Vergi (%)"
                type="number"
                autoComplete="off"
                error={props.configuration.tax_error}
                value={props.configuration.tax}
                onChange={(event) => props.setValue("tax", event.target.value)}
                helperText={props.configuration.tax_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />
              <TextField
                label="Ortalama Poşet Gideri / Adet (EUR)"
                placeholder="Ortalama Poşet Gideri / Adet (EUR)"
                type="number"
                autoComplete="off"
                error={props.configuration.avaragePackageCost_error}
                value={props.configuration.avaragePackageCost}
                onChange={(event) => props.setValue("avaragePackageCost", event.target.value)}
                helperText={props.configuration.avaragePackageCost_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />
              <TextField
                label="Ortalama Kart Gideri / Adet (EUR)"
                placeholder="Ortalama Kart Gideri / Adet (EUR)"
                type="number"
                autoComplete="off"
                error={props.configuration.avarageCardCost_error}
                value={props.configuration.avarageCardCost}
                onChange={(event) => props.setValue("avarageCardCost", event.target.value)}
                helperText={props.configuration.avarageCardCost_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />

              <TextField
                label="Ortalama Kargo Gideri / Ürün (TRY)"
                placeholder="Ortalama Kargo Gideri / Ürün (TRY)"
                type="number"
                autoComplete="off"
                error={props.configuration.avarageShippingCost_error}
                value={props.configuration.avarageShippingCost}
                onChange={(event) => props.setValue("avarageShippingCost", event.target.value)}
                helperText={props.configuration.avarageShippingCost_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />

              <TextField
                label="Aylık Max. Sipariş İptal Hakkı"
                placeholder="Aylık Max. Sipariş İptal Hakkı"
                type="number"
                autoComplete="off"
                error={props.configuration.maxCancelPerMonth_error}
                value={props.configuration.maxCancelPerMonth}
                onChange={(event) => props.setValue("maxCancelPerMonth", event.target.value)}
                helperText={props.configuration.maxCancelPerMonth_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />

              <TextField
                label="Aylık Max. Sipariş İade Hakkı"
                placeholder="Aylık Max. Sipariş İade Hakkı"
                type="number"
                autoComplete="off"
                error={props.configuration.maxRefundPerMonth_error}
                value={props.configuration.maxRefundPerMonth}
                onChange={(event) => props.setValue("maxRefundPerMonth", event.target.value)}
                helperText={props.configuration.maxRefundPerMonth_helpertext}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0
                  }
                }}
              />

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Satışa Açık / Kapalılık Durumu
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Switch
                        color="primary"
                        checked={props.configuration.isOpenForSale}
                        onChange={(event) => props.setValue("isOpenForSale", event.target.checked)}
                        value={props.configuration.isOpenForSale}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Yurtdışı Satışa Açık
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Switch
                        color="primary"
                        checked={props.configuration.isOpenForAbroad}
                        onChange={(event) => props.setValue("isOpenForAbroad", event.target.checked)}
                        value={props.configuration.isOpenForAbroad}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Dövizle İşleme Açık
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Switch
                        color="primary"
                        checked={props.configuration.isOpenCurrency}
                        onChange={(event) => props.setValue("isOpenCurrency", event.target.checked)}
                        value={props.configuration.isOpenCurrency}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Button onClick={() => {
                props.update(props.configuration.data.id, props.auth.user.token,
                  [
                    {
                      key: "minCartTotal",
                      value: props.configuration.minCartTotal,
                      required: true,
                      type: "number"
                    },
                    {
                      key: "tax",
                      value: props.configuration.tax,
                      required: true,
                      type: "number"
                    },
                    {
                      key: "avaragePackageCost",
                      value: props.configuration.avaragePackageCost,
                      required: true,
                      type: "number"
                    },
                    {
                      key: "avarageCardCost",
                      value: props.configuration.avarageCardCost,
                      required: true,
                      type: "number"
                    },
                    {
                      key: "avarageShippingCost",
                      value: props.configuration.avarageShippingCost,
                      required: true,
                      type: "number"
                    },
                    {
                      key: "isOpenForSale",
                      value: props.configuration.isOpenForSale
                    },
                    {
                      key: "isOpenForAbroad",
                      value: props.configuration.isOpenForAbroad
                    },
                    {
                      key: "isOpenCurrency",
                      value: props.configuration.isOpenCurrency
                    },
                    {
                      key: "maxCancelPerMonth",
                      value: props.configuration.maxCancelPerMonth,
                      required: true,
                      type: "number"
                    },
                    {
                      key: "maxRefundPerMonth",
                      value: props.configuration.maxRefundPerMonth,
                      required: true,
                      type: "number"
                    },
                  ])
              }} style={{ float: "right", marginTop: 15, marginBottom: 15 }}>Kaydet</Button>
            </div>
            <div className="col-md-1"></div>
          </div>
        </Paper>
      </div>
    </div>

  </> : <Loading show={props.configuration.isFetching} />
}


const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    configuration: state.configuration,
    table: state.table
  }
}

export default connect(mapStateToProps,
  {
    getAll,
    setValue,
    update
  })(Configuration);