import React, { Component } from "react";
import { Redirect } from "react-router-dom"
import { connect } from "react-redux";
import { logOut } from "../../../../redux/actions/loginActions";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
class Logout extends Component {

  componentDidMount() {
    this.props.logOut(this.props.auth.user.token);
  }

  render() {
    const { user } = this.props.auth;
    return user !== null ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps,
  {
    logOut
  })(Logout);
