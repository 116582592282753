function emailIsValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

const checkFormFields = (data, checkContent) => {

    var nullFields = [];
    var invalidFields = [];

    for (let item of data) {
        if (item.required) {
            switch (item.type) {
                case "bool":
                    if (item.value === false) {
                        nullFields.push(item);
                    }
                    break;
                default:
                    if (item.value.length === 0) {
                        item["helperText"] = "Lütfen Bu Alanı Doldurun";
                        nullFields.push(item);
                    } else {
                        if (checkContent === true) {
                            if (item.type === "number") {
                                if (item.value <= 0) {
                                    item["helperText"] = "Lütfen 0 dan büyük bir değer giriniz";
                                    invalidFields.push(item);
                                }
                            }
                            switch (item.key.trim()) {
                                case "password":
                                    if (item.value.length < 6) {
                                        item["helperText"] = "Lütfen 6 Karakterden Daha Uzun Bir Şifre Seçiniz";
                                        invalidFields.push(item);
                                    }
                                    break;
                                case "email":
                                    if (!emailIsValid(item.value)) {
                                        item["helperText"] = "Lütfen Geçerli Bir Email Adresi Giriniz";
                                        invalidFields.push(item);
                                    }
                                    break;
                                default:
                                    break;

                            }
                        }
                    }
                    break;
            }
        }
    }

    return {
        result: nullFields.length > 0 || invalidFields.length > 0 ? true : false,
        nullFields: nullFields,
        invalidFields: invalidFields
    };
}


function return3DErrors(mdStatus) {
    var error = "";
    switch (mdStatus) {
        case "0":
            error = "3-D Secure imzası geçersiz veya doğrulama";
            break;
        case "2":
            error = "Kart sahibi veya bankası sisteme kayıtlı değil";
            break;
        case "3":
            error = "Kartın bankası sisteme kayıtlı değil";
            break;
        case "4":
            error = "Doğrulama denemesi, kart sahibi sisteme daha sonra kayıt olmayı seçmiş";
            break;
        case "5":
            error = "Doğrulama yapılamıyor";
            break;
        case "6":
            error = "3-D Secure hatası";
            break;
        case "7":
            error = "Sistem hatası";
            break;
        case "8":
            error = "Bilinmeyen kart no";
            break;
    }

    return error;
}


function returnStatusColor(status) {
    var color = "";
    switch (status) {
        case "preorder":
            color = "secondary";
            break;

        case "pending3DAction":
        case "pending":
            color = "warning";
            break;
        case "approved":
            color = "success";
            break;
        case "refundError":
        case "failure":
            color = "danger";
            break;
        case "success":
            color = "success";
            break;
        case "canceledByAdmin":
        case "canceled":
        case "refund":
        case "partialRefund":
            color = "dark";
            break;
        case "sendedRefundRequest":
            color = "light";
            break;
        default:
            color = "info";
            break;
    }

    return color;
}

export {
    checkFormFields,
    emailIsValid,
    return3DErrors,
    returnStatusColor
}