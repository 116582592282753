const initialState = {
    alert: {
        show: false,
        message: "",
        type: ""
    }

}

export default function (state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_APP_ALERT":
            return {
                ...state,
                alert: {
                    ...state.alert,
                    ...action.payload
                }
            }
        default:
            return state
    }

}
