const initialState = {
    user: null,
    socket: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "AUTH_USER":
            return {
                ...state,
                user: action.payload
            }
        case "SET_SOCKET":
            return {
                ...state,
                socket: action.payload
            }
        case "LOG_OUT":
            return {
                ...state,
                user: null
            }
        default:
            return state
    }

}
