import React, { useMemo, useEffect } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../_metronic/layout";


export default function MixedWidget1(props) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseDanger: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById("kt_mixed_widget_1_chart");
        if (!element) {
            return;
        }

        const options = getChartOptions(layoutProps);

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);

    return (
        <div className={"card card-custom bg-gray-100"}>
            {/* Header */}
            <div className="card-header border-0 bg-dark py-5">
                <h3 className="card-title font-weight-bolder text-white">Satış İstatistikleri</h3>
            </div>
            {/* Body */}
            <div className="card-body p-0 position-relative overflow-hidden">
                {/* Chart */}
                <div
                    id="kt_mixed_widget_1_chart"
                    className="card-rounded-bottom bg-dark"
                    style={{ height: "200px" }}
                ></div>

                {/* Stat */}
                <div className="card-spacer mt-n25">
                    <div className="row m-0">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
                                    ></SVG>
                                </span>
                                <a
                                    href="/stock/stock-dashboard"
                                    className="text-dark font-weight-bold font-size-h6">
                                    Stoktaki Ürünler
                                </a>
                                <br />
                                <label className="text-dark font-weight-bold font-size-h1">{props.stockCount}</label>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                                    ></SVG>
                                </span>
                                <a
                                    href="/stock/stock-dashboard"
                                    className="text-dark font-weight-bold font-size-h6">
                                    Satılan Ürün
                                </a>
                                <br />
                                <label className="text-dark font-weight-bold font-size-h1">
                                    {
                                        props.dashboardData !== null ?
                                            props.dashboardData.soldStocksCount : ""
                                    }
                                </label>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="col bg-white px-6 py-8 rounded-xl mb-7">
                            <div style={{ display: "flex", flexDirection: "column" }}><span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Add-user.svg"
                                    )}
                                ></SVG>
                            </span>
                                <a
                                    href="/crm/customers"
                                    className="text-dark font-weight-bold font-size-h6 mt-2"
                                >
                                    Yeni Kullanıcı
                                </a>
                                <br />
                                <label className="text-dark font-weight-bold font-size-h1">{
                                    props.dashboardData !== null ?
                                        props.dashboardData.newCustomersCount : ""
                                }</label></div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Group.svg"
                                        )}
                                    ></SVG>
                                </span>
                                <a
                                    href="/crm/customers"
                                    className="text-dark font-weight-bold font-size-h6 mt-2"
                                >
                                    Toplam Kullanıcı
                                </a>
                                <br />
                                <label className="text-dark font-weight-bold font-size-h1">{
                                    props.dashboardData !== null ?
                                        props.dashboardData.allCustomersCount : ""
                                }</label>
                            </div>

                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                            <span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Thumbtack.svg")}
                                ></SVG>
                            </span>
                            <a
                                href="/order/pending-list"
                                className="text-dark font-weight-bold font-size-h6 mt-2"
                            >
                                Bekleyen Siparişler
                            </a>
                            <br />
                            <label className="text-dark font-weight-bold font-size-h1">{props.pendingOrders}</label>
                        </div>
                        <div className="col bg-white px-6 py-8 rounded-xl">
                            <span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Shopping/Cart1.svg"
                                    )}
                                ></SVG>
                            </span>
                            <a
                                href="/order/list"
                                className="text-dark font-weight-bold font-size-h6 mt-2"
                            >
                                Toplam Sipariş
                            </a>
                            <br />
                            <label className="text-dark font-weight-bold font-size-h1">
                                {
                                    props.dashboardData !== null ?
                                        props.dashboardData.allOrdersCount : ""
                                }
                            </label>
                        </div>
                    </div>
                </div>

                {/* Resize */}
                <div className="resize-triggers">
                    <div className="expand-trigger">
                        <div style={{ width: "411px", height: "461px" }} />
                    </div>
                    <div className="contract-trigger" />
                </div>
            </div>
        </div>
    );
}

function getChartOptions(layoutProps) {
    const strokeColor = "#D13647";

    const options = {
        series: [
            {
                name: "Net Profit",
                data: [30, 45, 32, 70, 40, 40, 40]
            }
        ],
        chart: {
            type: "area",
            height: 200,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            sparkline: {
                enabled: true
            },
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 5,
                left: 0,
                blur: 3,
                color: strokeColor,
                opacity: 0.5
            }
        },
        plotOptions: {},
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: "solid",
            opacity: 0
        },
        stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            colors: [strokeColor]
        },
        xaxis: {
            categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            },
            crosshairs: {
                show: false,
                position: "front",
                stroke: {
                    color: layoutProps.colorsGrayGray300,
                    width: 1,
                    dashArray: 3
                }
            }
        },
        yaxis: {
            min: 0,
            max: 80,
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily
            },
            y: {
                formatter: function (val) {
                    return "$" + val + " thousands";
                }
            },
            marker: {
                show: false
            }
        },
        colors: ["transparent"],
        markers: {
            colors: layoutProps.colorsThemeBaseDanger,
            strokeColor: [strokeColor],
            strokeWidth: 3
        }
    };
    return options;
}
