import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { store } from "../../../redux/store";

const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};


const Uploader = props => {
  async function onChange(files) {
    let images = [];
    for (let img of files) {
      var base64Image = await getBase64(img);
      images.push(base64Image);
    }
    if (props.stock) {
      props.setStockData("images", "stockData", props.order, images);
    } else {
      props.setValue(props.stateKey, images);
    }
  };
  return (
    <DropzoneArea
      {...props}
      onChange={onChange}
      maxFileSize={10485760}
      showAlerts={false}
      showPreviews
      clearOnUnmount={false}
      initialFiles={props.uploadedImages}
      fileObjects={props.uploadedImages}
      acceptedFiles={['image/jpeg', 'image/png']}
      getFileLimitExceedMessage={(filesLimit) => {
        store.dispatch({
          type: "TOGGLE_APP_ALERT",
          payload: {
            show: true,
            type: "error",
            message: "Maksimum Resim Ekleme Sınırı " + filesLimit + " Olarak Belirlenmiştir!"
          }
        });
      }}

      getDropRejectMessage={(file, acceptedFiles, maxFileSize) => {
        store.dispatch({
          type: "TOGGLE_APP_ALERT",
          payload: {
            show: true,
            type: "error",
            message: file.path + ' dosyası yüklenemedi! Dosya desteklenmiyor veya boyutu çok yüksek. Desteklenen formatlar : ".jpeg" ve ".png"'
          }
        });
      }}

      showPreviewsInDropzone={false}
      filesLimit={props.multiple === false ? 1 : 10}
      dropzoneClass="custom-dropzone-area"
      previewText=""
      Icon={() => {
        return null
      }}
      dropzoneText={props.dropzoneText ? props.dropzoneText : "Resimleri Sürükleyin veya Yüklemek İçin Tıklayın"}
      previewGridProps={{
        item: {
          xs: 4
        },
        container: {
          spacing: 2
        }
      }}
    />
  );
};

export default Uploader;