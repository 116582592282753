import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../constants/moment';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,
    
} from "../../../../../redux/actions/sizeActions";
import {
    Button,
    Modal
} from "react-bootstrap";
import {
    TextField,
    FormHelperText,
    Chip,
    Avatar,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow
} from "@material-ui/core";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function SizesPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    const columns = [
        {
            dataField: "sizeName",
            text: "Beden Tanımı",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.size.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "sizeCode",
            text: "Beden Kodu",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.size.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",
            
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.size.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.size.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i style={{ marginRight: 5 }} className={props.size.process === "create" ? "fa fa-plus" : props.size.process === "update" ? "fa fa-edit" : "fa fa-trash-alt"}></i>
                    {props.size.process === "create" ? "Yeni Beden" : props.size.process === "update" ? "Beden Düzenle" : "Beden Sil"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.size.process === "create" || props.size.process === "update" ?
                    <div>
                        <TextField
                            label="Beden Adı"
                            
                            style={{ margin: 8 }}
                            placeholder="Beden Adı"
                            autoComplete="off"
                            error={props.size.name_error}
                            value={props.size.name}
                            onChange={(event) => props.setValue("name", event.target.value)}
                            helperText={props.size.name_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        <TextField
                            label="Beden Kodu"
                            
                            style={{ margin: 8 }}
                            placeholder="Beden Kodu"
                            autoComplete="off"
                            error={props.size.code_error}
                            value={props.size.code}
                            onChange={(event) => props.setValue("code", event.target.value)}
                            helperText={props.size.code_helpertext}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    marginLeft: 0
                                }
                            }}
                        />
                        {
                            props.size.process === "update" && props.size.detail !== null ?
                                <>
                                    <div className="separator separator-dashed my-7"></div>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>BEDEN DETAYLARI</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.size.detail.createdBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Oluşturan Kullanıcı / Zamanı</FormHelperText><br />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.size.detail.createdBy.name[0]).toUpperCase() + String(props.size.detail.createdBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.size.detail.createdBy.username}
                                                            /><br />
                                                            <small>{moment(props.size.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.size.detail.createdAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }

                                            {
                                                props.size.detail.updatedBy !== undefined && props.size.detail.updatedBy !== null ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Chip
                                                                style={{ marginLeft: -5 }}
                                                                avatar={<Avatar>{String(props.size.detail.updatedBy.name[0]).toUpperCase() + String(props.size.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                                                                label={props.size.detail.updatedBy.username}
                                                            /><br />
                                                            <small>{moment(props.size.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                                                            <small>{moment(props.size.detail.updatedAt).fromNow()}</small>
                                                        </TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableBody>
                                    </Table></> : null
                        }
                    </div> :
                    props.table.row !== null && props.table.row.sizeName ?
                        <>
                            <b>{'"'}{props.table.row.sizeName}{'" '}</b> isimli bedeni silmek üzeresiniz. İşlemi onaylıyor musunuz?
                  </> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.size.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.size.process)}>
                    İptal
            </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.size.isFetchingActions} onClick={() => {
                    if (props.size.process === "create") {
                        props.createNew(props.auth.user.token,
                            [
                                {
                                    key: "name",
                                    value: props.size.name,
                                    required: true
                                },
                                {
                                    key: "code",
                                    value: props.size.code,
                                    required: true
                                }
                            ])
                    } else if (props.size.process === "update") {
                        props.update(props.auth.user.token, props.table.row.id,
                            [
                                {
                                    key: "name",
                                    value: props.size.name,
                                    required: true
                                },
                                {
                                    key: "code",
                                    value: props.size.code,
                                    required: true
                                }

                            ], props.table.index);
                    } else {
                        props.deleteEntity(props.auth.user.token, props.table.row, props.table.index);
                    }
                }} variant="primary">
                    {
                        props.size.isFetchingActions ?
                            <span className="ml-3 spinner spinner-white"></span> :
                            props.size.process === "delete" ? "Sil" : "Kaydet"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            handleModal={props.toggleModal}
            title="Tüm Bedenler"
            buttonTitle="Yeni Beden"
            cardIcon="fa fa-tools"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.size.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        size: state.size,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        update,
        deleteEntity,
        tableActions,
        
    })(SizesPage);
