
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ActionsColumnFormatter(cell, row, tableActions, token) {
    return (
        <>
            <span
                title="Stok Gir"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => tableActions(row._id, token)}
            >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                    />
                </span>
            </span>
            <> </>
        </>
    );
}
