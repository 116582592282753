import { globalURL } from "../../../app/constants/constants";

const initialState = {
    data: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    title: "",
    title_eng: "",
    text: "",
    text_eng: "",
    description: "",
    description_eng: "",
    order: 1,
    isActive: false,
    images: [],
    text_eng_helpertext: "",
    text_helpertext: "",
    images_helpertext: "",
    order_helpertext: "",
    images_error: "",
    title_helpertext: "",
    title_eng_helpertext: "",
    description_helpertext: "",
    description_eng_helpertext: "",
    text_error: false,
    text_eng_error: false,
    title_error: false,
    title_eng_error: false,
    order_error: false,
    isActive_helpertext: "",
    isActive_error: false,
    description_error: false,
    description_eng_error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_BANNERS":
            return {
                ...state,
                isFetching: true,
                showModal: false,
                isFetchingActions: false,
                isFetchingDetail: false,
                error: false
            }
        case "FETCH_ALL_BANNERS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ALL_BANNERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_BANNER_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show,
                title: action.payload.process !== "create" ? state.title : "",
                description: action.payload.process !== "create" ? state.description : "",
                title_eng: action.payload.process !== "create" ? state.title_eng : "",
                text: action.payload.process !== "create" ? state.text : "",
                text_eng: action.payload.process !== "create" ? state.text_eng : "",
                description_eng: action.payload.process !== "create" ? state.description_eng : "",
                order: action.payload.process === "create" ? state.data.length + 1 : state.order,
                title_helpertext: "",
                title_eng_helpertext: "",
                description_helpertext: "",
                description_eng_helpertext: "",
                title_error: false,
                description_error: false,
                title_eng_error: false,
                description_eng_error: false,
                order_error: false,
                order_helpertext: "",
                images: action.payload.process === "create" ? [] : state.images
            }
        case "SET_VALUE_BANNER":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_BANNER":
            return {
                ...state,
                isFetching: false,
                isFetchingActions: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_INSERT_BANNERS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_INSERT_BANNERS":
            var data = state.data;
            data.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                showModal: false,
                data: data,
                title: "",
                description: ""
            }
        case "ERROR_INSERT_BANNERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_DETAIL_BANNER":
            return {
                ...state,
                isFetchingDetail: true,
                isFetchingActions: false,
                error: false
            }
        case "FETCH_DETAIL_BANNER":
            return {
                ...state,
                isFetchingDetail: false,
                isFetchingActions: false,
                detail: action.payload.data,
                title: action.payload.data.title.tr,
                text: action.payload.data.text.tr,
                text_eng: action.payload.data.text.eng,
                title_eng: action.payload.data.title.eng,
                description: action.payload.data.description.tr,
                description_eng: action.payload.data.description.eng,
                order: action.payload.data.order,
                isActive: action.payload.data.isActive,
                images: action.payload.data.image !== null ? [globalURL + "/images/" + action.payload.data.image.name] : []
            }
        case "ERROR_DETAIL_BANNER":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_BANNER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_BANNER":
            var index = state.data.findIndex(x => Number(x.order) === Number(action.payload.data.order));
            console.log(index)
            if (index > -1) {
                state.data[index].order = state.data[action.rowIndex].order;
            }
            state.data[action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false,
                title: "",
                description: "",
                order: 1,
                isActive: false,
                text: "",
                text_eng: "",
                text_helpertext: "",
                text_eng_helpertext: "",
                title_helpertext: "",
                title_eng_helpertext: "",
                description_helpertext: "",
                description_eng_helpertext: "",
                order_helpertext: "",
                text_eng_error: false,
                text_error: false,
                title_error: false,
                title_eng_error: false,
                isActive_helpertext: "",
                isActive_error: false,
                order_error: false,
                description_error: false,
                description_eng_error: false
            }
        case "ERROR_UPDATE_BANNER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_BANNER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_BANNER":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_BANNER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
