import { globalURL, fetchApiHeaders } from "../../app/constants/constants";

const toggleModal = (show, process) => dispatch => {
    dispatch({
        type: "TOGGLE_ORDER_CANCEL_LOG_MODAL",
        payload: {
            show: show,
            process: process
        }
    });

    if (!show) {
        dispatch({
            type: "SET_TABLE_ROW_DATA",
            payload: {
                row: null,
                rowIndex: 0
            }
        })
    }
}


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_ORDER_CANCEL_LOG",
        payload: {
            key: key,
            value: value
        }
    })
}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_ALL_ORDER_CANCEL_LOGS"
    })
    fetch(globalURL + "/orderCancelLog/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (orderCancelLogs) => {
            if (orderCancelLogs.success) {
                dispatch({
                    type: "FETCH_ALL_ORDER_CANCEL_LOGS",
                    payload: orderCancelLogs.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_ALL_ORDER_CANCEL_LOGS",
                    payload: orderCancelLogs
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_ALL_ORDER_CANCEL_LOGS",
                payload: error
            })
        })
}


const tableActions = (process, id, token) => dispatch => {
    if (process === "update") {
        dispatch({
            type: "GET_DETAIL_ORDER_CANCEL_LOG"
        })
        fetch(globalURL + "/orderCancelLog/getDetail", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((result) => result.json())
            .then(async (detail) => {
                if (detail.success) {
                    dispatch({
                        type: "FETCH_DETAIL_ORDER_CANCEL_LOG",
                        payload: detail
                    })
                    dispatch({
                        type: "TOGGLE_ORDER_CANCEL_LOG_MODAL",
                        payload: {
                            show: true,
                            process: process
                        }
                    })
                } else {
                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "İşlem gerçekleştirilirken bir hata oluştu.",
                            type: "error"
                        }
                    });

                    dispatch({
                        type: "ERROR_DETAIL_ORDER_CANCEL_LOG",
                        payload: detail
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });
                dispatch({
                    type: "ERROR_DETAIL_ORDER_CANCEL_LOG",
                    payload: error
                })
            })
    } else {
        dispatch({
            type: "TOGGLE_ORDER_CANCEL_LOG_MODAL",
            payload: {
                show: true,
                process: process
            }
        })
    }
}


const deleteEntity = (token, row, rowIndex) => dispatch => {
    dispatch({
        type: "GET_DELETE_ORDER_CANCEL_LOG"
    })
    fetch(globalURL + "/orderCancelLog/delete", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        },
        body: JSON.stringify({
            id: row.id
        })
    })
        .then((result) => result.json())
        .then(async (deleteRecord) => {
            if (deleteRecord.success) {
                dispatch({
                    type: "FETCH_DELETE_ORDER_CANCEL_LOG",
                    payload: deleteRecord,
                    rowIndex: rowIndex
                });

                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem başarıyla gerçekleşti.",
                        type: "success"
                    }
                });
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_DELETE_ORDER_CANCEL_LOG",
                    payload: deleteRecord
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });
            dispatch({
                type: "ERROR_DELETE_ORDER_CANCEL_LOG",
                payload: error
            })
        })
}



export {
    toggleModal,
    setValue,
    getAll,
    tableActions,
    deleteEntity
}