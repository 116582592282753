import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  toggleModal,
  setValue,
  update,
  tableActions,
  getMailingCustomers
} from "../../../../../redux/actions/customerActions";
import moment from '../../../../constants/moment';
import {
  Button,
  Modal,
  Badge
} from "react-bootstrap";
import DataTable from "../../../../helpers/datatable/DataTable";
import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Avatar,
  Chip,
  FormHelperText
} from "@material-ui/core";
import Loading from "../../../../helpers/loading/Loading";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function MailingBlackListPage(props) {
  useEffect(() => {
    props.getMailingCustomers(props.auth.user.token, "none");
    return () => {
      // will unMount 
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const columns = [
    {
      dataField: "name",
      text: "Ad",
      sort: true,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.customer.data.length > 0 ? "block" : "none"
        }
      }),
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "surname",
      text: "Soyad",
      sort: true,
      sortCaret: sortCaret,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.customer.data.length > 0 ? "block" : "none"
        }
      }),
      headerSortingClasses
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      filter: textFilter({
        placeholder: "Ara",
        style: {
          display: props.customer.data.length > 0 ? "block" : "none"
        }
      }),
      headerSortingClasses
    },
    {
      dataField: "isBlocked",
      text: "DURUM",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell) => {
        return cell === true ? <Badge variant="dark" className="mr-1">
          bloke
        </Badge> : <Badge variant="success" className="mr-1">
          aktif
        </Badge>
      },
    },
    {
      dataField: "action",
      text: "",
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      formatter: (cell, row) => {
        return row.name ? actionsFormatter(cell, row, props.tableActions, props.auth.user.token, true) : null
      }

    }
  ];

  return <>
    <Loading show={props.customer.isFetchingDetail || props.customer.isFetching} />
    <Modal backdrop="static" size="lg" show={props.customer.showModal} onHide={() => props.toggleModal(false, "")}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i style={{ marginRight: 5 }} className={"fa fa-edit"}></i> Kullanıcı Detay
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.customer.detail !== null ?
            <>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>KULLANICI BİLGİLERİ</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Ad
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.customer.detail.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Soyad
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.customer.detail.surname}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.customer.detail.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Telefon
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.customer.detail.phone}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Dil
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.customer.detail.preferedLanguage}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      OS
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.customer.detail.os}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Katılma Zamanı
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <small>{moment(props.customer.detail.createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                      <small>{moment(props.customer.detail.createdAt).fromNow()}</small>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Durum
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Badge variant={props.customer.isBlocked ? "dark" : "success"} className="mr-1">
                        {props.customer.isBlocked ? "engellendi" : "aktif"}
                      </Badge>
                    </TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Kullanıcıyı Engelle
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        style={{ marginLeft: -12 }}
                        control={
                          <Switch
                            color="primary"
                            checked={props.customer.isBlocked}
                            onChange={(event) => props.setValue("isBlocked", event.target.checked)}
                            value={props.customer.isBlocked}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Marketing Onayı
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Badge variant={props.customer.detail.isConfirmedMarketing ? "success" : "dark"} className="mr-1">
                        {props.customer.detail.isConfirmedMarketing ? "evet" : "hayır"}
                      </Badge>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <>
                {
                  props.customer.detail.updatedBy !== null && props.customer.detail.updatedBy ?
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>İŞLEM GEÇMİŞİ</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <FormHelperText>Son Güncelleyen Kullanıcı / Zamanı</FormHelperText>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Chip
                              style={{ marginLeft: -5 }}
                              avatar={<Avatar>{String(props.customer.detail.updatedBy.name[0]).toUpperCase() + String(props.customer.detail.updatedBy.surname[0]).toUpperCase()}</Avatar>}
                              label={props.customer.detail.updatedBy.username}
                            /><br />
                            <small>{moment(props.customer.detail.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                            <small>{moment(props.customer.detail.updatedAt).fromNow()}</small>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table> : null
                }
              </>
              <br />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>AKTİF ADRES BİLGİLERİ</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {
                  props.customer.detail.address.length > 0 ?
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Adres Başlığı
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].title}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          İsim
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].nameSurname}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Telefon
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {"+" + props.customer.detail.address[0].phone}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          TC Kimlik No
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].tcNumber}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Posta Kodu
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].postalCode}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Adres
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].address}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Sokak / Mah.
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].street.streetName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          İlçe
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].district.districtName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Şehir
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].city.cityName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Şahıs / Firma
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.customer.detail.address[0].isPersonalInvoice ? "Şahıs" : "Firma"}
                        </TableCell>
                      </TableRow>
                      {
                        props.customer.detail.address[0].isPersonalInvoice ? null :
                          <>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Firma Adı
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {props.customer.detail.address[0].firmName}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Vergi Dairesi
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {props.customer.detail.address[0].taxOffice}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Vergi Numarası
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {props.customer.detail.address[0].taxNumber}
                              </TableCell>
                            </TableRow>
                          </>
                      }
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Oluşturulma Zamanı
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <small>{moment(props.customer.detail.address[0].createdAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                          <small>{moment(props.customer.detail.address[0].createdAt).fromNow()}</small>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Son Değişiklik Zamanı
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <small>{moment(props.customer.detail.address[0].updatedAt).format('DD.MM.YYYY HH:mm:ss')}</small><br />
                          <small>{moment(props.customer.detail.address[0].updatedAt).fromNow()}</small>
                        </TableCell>
                      </TableRow>
                    </TableBody> :
                    <TableBody>
                      <TableRow>

                        <TableCell component="th" scope="row">
                          Kullanıcının Kayıtlı Teslimat Adresi Bulunmamaktadır.
                        </TableCell>
                        <TableCell component="th" scope="row">

                        </TableCell>
                      </TableRow>
                    </TableBody>
                }
              </Table>
            </> : null
        }
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={props.customer.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.customer.process)}>
          İptal
        </Button>
        {
          props.customer.detail !== null && props.customer.detail.isBlocked !== props.customer.isBlocked ?
            <Button onClick={() => {
              props.update(props.auth.user.token, props.customer.detail.id, [
                {
                  key: "isBlocked",
                  value: props.customer.isBlocked
                },
                {
                  key: "name",
                  value: props.customer.detail.name
                },
                {
                  key: "surname",
                  value: props.customer.detail.surname
                }
              ], props.table.index, "noneMailing")
            }} style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.customer.isFetchingActions} variant="primary">
              Güncelle
            </Button> : null
        }
      </Modal.Footer>
    </Modal>
    <DataTable
      actionsButtonHidden={true}
      handleModal={props.toggleModal}
      title="Mailing Güncel Liste"
      buttonTitle="Yeni Sipariş"
      cardIcon="fa fa-envelope-open-text"
      buttonIcon="fa fa-plus"
      searchPlaceholder="Ara"
      data={props.customer.noneMailing}
      columns={columns}
      token={props.auth.user.token}
      query="none"
      refreshTable={props.getMailingCustomers}
    />
  </>
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    customer: state.customer,
    table: state.table
  }
}

export default connect(mapStateToProps,
  {
    toggleModal,
    getMailingCustomers,
    setValue,
    update,
    tableActions,

  })(MailingBlackListPage);
