import { combineReducers } from 'redux';
import {
    authReducer,
    usersReducer,
    loginReducer,
    campaignReducer,
    shippingReducer,
    roleReducer,
    titleReducer,
    tableReducer,
    createPasswordReducer,
    colorReducer,
    configurationReducer,
    categoryReducer,
    dashboardReducer,
    currencyReducer,
    addressReducer,
    productReducer,
    orderReducer,
    customerReducer,
    feedbackReducer,
    sizeReducer,
    stockManagementReducer,
    alertReducer,
    bannerReducer,
    imageSliderReducer,
    templateReducer,
    orderCancelLogReducer,
    refundReducer
} from './importReducers';

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    login: loginReducer,
    role: roleReducer,
    title: titleReducer,
    shipping: shippingReducer,
    campaign: campaignReducer,
    table: tableReducer,
    createPassword: createPasswordReducer,
    color: colorReducer,
    configuration: configurationReducer,
    category: categoryReducer,
    dashboard: dashboardReducer,
    currency: currencyReducer,
    address: addressReducer,
    product: productReducer,
    order: orderReducer,
    customer: customerReducer,
    feedback: feedbackReducer,
    size: sizeReducer,
    stockManagement: stockManagementReducer,
    alert: alertReducer,
    banner: bannerReducer,
    imageSlider: imageSliderReducer,
    template: templateReducer,
    orderCancelLog: orderCancelLogReducer,
    refund: refundReducer
});

export default rootReducer;