import { globalURL, fetchApiHeaders } from "../../app/constants/constants";
import { checkFormFields } from "../../app/helpers/functions";


const setValue = (key, value) => dispatch => {
    dispatch({
        type: "SET_VALUE_CONFIGURATION",
        payload: {
            key: key,
            value: value
        }
    })
}

const getAll = (token) => dispatch => {
    dispatch({
        type: "GET_CONFIGURATION"
    })
    fetch(globalURL + "/configuration/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST,
            token: token
        }
    })
        .then((result) => result.json())
        .then(async (configurations) => {
            if (configurations.success) {
                dispatch({
                    type: "FETCH_CONFIGURATION",
                    payload: configurations.data
                })
            } else {
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_CONFIGURATION",
                    payload: configurations
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "TOGGLE_APP_ALERT",
                payload: {
                    show: true,
                    message: "İşlem gerçekleştirilirken bir hata oluştu.",
                    type: "error"
                }
            });

            dispatch({
                type: "ERROR_CONFIGURATION",
                payload: error
            })
        })
}


const update = (id, token, data) => dispatch => {
    dispatch({
        type: "GET_UPDATE_CONFIGURATION"
    });

    var checkForm = checkFormFields(data, true);
    if (!checkForm.result) {
        // update
        fetch(globalURL + "/configuration/update", {
            method: 'POST',
            headers: {
                ...fetchApiHeaders.POST,
                token: token
            },
            body: JSON.stringify({
                id: id,
                config: {
                    "minCartTotal": data[0].value,
                    "tax": data[1].value,
                    "avaragePackageCost": data[2].value,
                    "avarageCardCost": data[3].value,
                    "avarageShippingCost": data[4].value,
                    "isOpenForSale": data[5].value,
                    "isOpenForAbroad": data[6].value,
                    "isOpenCurrency": data[7].value,
                    "maxCancelPerMonth": data[8].value,
                    "maxRefundPerMonth": data[9].value,
                }
            })
        })
            .then((result) => result.json())
            .then(async (update) => {
                if (update.success) {
                    dispatch({
                        type: "FETCH_UPDATE_CONFIGURATION",
                        payload: update
                    });

                    dispatch({
                        type: "TOGGLE_APP_ALERT",
                        payload: {
                            show: true,
                            message: "Değişiklikler başarıyla kaydedildi.",
                            type: "success"
                        }
                    });
                } else {
                    if (update.field) {
                        dispatch({
                            type: "SET_VALUE_ERROR_CONFIGURATION",
                            payload: {
                                key: update.field,
                                helpertext: update.errorMessage
                            }
                        })
                    } else {
                        dispatch({
                            type: "TOGGLE_APP_ALERT",
                            payload: {
                                show: true,
                                message: "İşlem gerçekleştirilirken bir hata oluştu.",
                                type: "error"
                            }
                        });
                    }
                    dispatch({
                        type: "ERROR_UPDATE_CONFIGURATION",
                        payload: update
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: "TOGGLE_APP_ALERT",
                    payload: {
                        show: true,
                        message: "İşlem gerçekleştirilirken bir hata oluştu.",
                        type: "error"
                    }
                });

                dispatch({
                    type: "ERROR_UPDATE_CONFIGURATION",
                    payload: error
                })
            })

    } else {
        checkForm.nullFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_CONFIGURATION",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        });

        checkForm.invalidFields.map((item, index) => {
            return dispatch({
                type: "SET_VALUE_ERROR_CONFIGURATION",
                payload: {
                    ...item,
                    helpertext: item.helperText
                }
            })
        });
    }
}

export {
    getAll,
    setValue,
    update
}