const initialState = {
    data: null,
    isFetching: true,
    error: false,
    minCartTotal: 0,
    tax: 0,
    avaragePackageCost: 0,
    avarageCardCost: 0,
    avarageShippingCost: 0,
    isOpenForSale: false,
    isOpenForAbroad: false,
    isOpenCurrency: false,
    tax_error: false,
    taxt_helpertext: "",
    avaragePackageCost_error: false,
    avaragePackageCost_helpertext: "",
    avarageCardCost_error: false,
    avarageCardCost_helpertext: "",
    avarageShippingCost_error: false,
    avarageShippingCost_helpertext: "",
    isOpenForSale_error: false,
    isOpenForSale_helpertext: "",
    isOpenForAbroad_error: false,
    isOpenForAbroad_helpertext: "",
    isOpenCurrency_error: false,
    isOpenCurrency_helpertext: "",
    maxCancelPerMonth: 0,
    maxRefundPerMonth: 0,
    maxCancelPerMonth_helpertext: "",
    maxRefundPerMonth_helpertext: "",
    maxCancelPerMonth_error: false,
    maxRefundPerMonth_error: false


}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_CONFIGURATION":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_CONFIGURATION":
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                ...action.payload
            }
        case "ERROR_CONFIGURATION":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "SET_VALUE_CONFIGURATION":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CONFIGURATION":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_UPDATE_CONFIGURATION":
            return {
                ...state,
                isFetching: false,
                error: false
            }
        case "FETCH_UPDATE_CONFIGURATION":
            return {
                ...state,
                isFetching: false
            }
        case "ERROR_UPDATE_CONFIGURATION":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }

}
