
const initialState = {
    data: null,
    isFetching: false,
    isRefreshing: false,
    error: false,
   
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_CURRENCY":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_CURRENCY":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "GET_REFRESH_CURRENCY":
            return {
                ...state,
                isRefreshing: true,
                error: false
            }
        case "FETCH_REFRESH_CURRENCY":
            return {
                ...state,
                isRefreshing: false,
                data: action.payload
            }
        case "ERROR_CURRENCY":
            return {
                ...state,
                isFetching: false,
                isRefreshing: false,
                error: true
            }
        default:
            return state
    }

}
