import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    toggleModal,
    getAll,
    setValue,
    createNew,
    update,
    deleteEntity,
    tableActions,
    
} from "../../../../../redux/actions/feedbackActions";
import {
    Button,
    Modal
} from "react-bootstrap";
import DataTable from "../../../../helpers/datatable/DataTable";

import Loading from "../../../../helpers/loading/Loading";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import actionsFormatter from "../../../../helpers/datatable/formatters/actionsFormatter";
import { textFilter } from 'react-bootstrap-table2-filter';

function FeedbacksPage(props) {
    useEffect(() => {
        props.getAll(props.auth.user.token);
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const columns = [
        {
            dataField: "name",
            text: "Ad",
            sort: true,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "surname",
            text: "Soyad",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            filter: textFilter({
                placeholder: "Ara",
                style: {
                    display: props.feedback.data.length > 0 ? "block" : "none"
                }
            }),
            headerSortingClasses
        },
        {
            dataField: "action",
            text: "",
            
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            formatter: (cell, row) => {
                return actionsFormatter(cell, row, props.tableActions, props.auth.user.token)
            }

        }
    ];

    return <>
        <Loading show={props.feedback.isFetchingDetail} />
        <Modal backdrop="static" size="lg" show={props.feedback.showModal} onHide={() => props.toggleModal(false, "")}>
            <Modal.Header closeButton>
                <Modal.Title>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.feedback.isFetchingActions} variant="secondary" onClick={() => props.toggleModal(false, props.feedback.process)}>
                    İptal
            </Button>
                <Button style={{ minWidth: 50, minHeight: 39, display: "flex", flexDirection: "row", alignItems: "center" }} disabled={props.feedback.isFetchingActions} variant="primary">
                    Kaydet
                </Button>
            </Modal.Footer>
        </Modal>
        <DataTable
            actionsButtonHidden={true}
            handleModal={props.toggleModal}
            title="Müşteri Talepleri"
            buttonTitle="Yeni Sipariş"
            cardIcon="fa fa-comments"
            buttonIcon="fa fa-plus"
            searchPlaceholder="Ara"
            data={props.feedback.data}
            columns={columns}
        />
    </>
}

const mapStateToProps = function (state) {
    return {
        auth: state.auth,
        feedback: state.feedback,
        table: state.table
    }
}

export default connect(mapStateToProps,
    {
        toggleModal,
        getAll,
        setValue,
        createNew,
        update,
        deleteEntity,
        tableActions,
        
    })(FeedbacksPage);
