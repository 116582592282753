const initialState = {
    data: [],
    blocked: [],
    blockedMailing: [],
    spamMailing: [],
    quitMailing: [],
    noneMailing: [],
    detail: null,
    isFetching: false,
    isFetchingDetail: false,
    isFetchingActions: false,
    error: false,
    process: "",
    showModal: false,
    isBlocked : false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ALL_CUSTOMERS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_ALL_CUSTOMERS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "GET_MAILING_CUSTOMERS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_MAILING_CUSTOMERS":
            return {
                ...state,
                isFetching: false,
                [action.field + "Mailing"]: action.payload
            }
        case "GET_BLOCKED_CUSTOMERS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_BLOCKED_CUSTOMERS":
            return {
                ...state,
                isFetching: false,
                blocked: action.payload
            }
        case "ERROR_ALL_CUSTOMERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "TOGGLE_CUSTOMER_MODAL":
            return {
                ...state,
                process: action.payload.process,
                showModal: action.payload.show
            }
        case "SET_VALUE_CUSTOMER":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CUSTOMER":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_DETAIL_CUSTOMER":
            return {
                ...state,
                isFetchingDetail: true,
                error: false
            }
        case "FETCH_DETAIL_CUSTOMER":
            return {
                ...state,
                isFetchingDetail: false,
                detail: action.payload.data,
                isBlocked : action.payload.data.isBlocked
            }
        case "ERROR_DETAIL_CUSTOMER":
            return {
                ...state,
                isFetchingDetail: false,
                error: true
            }
        case "GET_UPDATE_CUSTOMER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_CUSTOMER":
            state[action.field][action.rowIndex] = action.payload.data;
            return {
                ...state,
                isFetchingActions: false,
                showModal: false
            }
        case "ERROR_UPDATE_CUSTOMER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        case "GET_DELETE_CUSTOMER":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_CUSTOMER":
            state.data.splice(action.rowIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                data: state.data,
                showModal: false
            }
        case "ERROR_DELETE_CUSTOMER":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
      
        default:
            return state
    }

}
