const globalURL = "https://api.iolafashion.com";
const globalApiFile = "";
const fetchApiHeaders = {
    POST: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}

export {
    globalURL,
    globalApiFile,
    fetchApiHeaders
}