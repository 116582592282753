
const initialState = {
    row: null,
    rowIndex: 0,
    currentIndex: 0,
    sizePerPage: 5,
    page: 1,
    index: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_TABLE_ROW_DATA":
            return {
                ...state,
                row: action.payload.row,
                rowIndex: action.payload.rowIndex,
                currentIndex: action.payload.currentIndex,
                sizePerPage: action.payload.sizePerPage,
                page: action.payload.page,
                index: action.payload.index
            }
        case "SET_TABLE_CURRENT_PAGE":
            return {
                ...state,
                currPage: action.payload
            }
        default:
            return state
    }

}
