import React, { useState } from 'react';
import Select from 'react-select';

// eslint-disable-next-line no-extend-native
String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) { return letters[letter]; })
    return string.toUpperCase();
}


function customFilter(option, searchText) {
    if (option.label.includes(searchText.turkishToUpper())) {
        return true;
    } else {
        return false;
    }
}

export default function CustomAriaLive(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    return (
        <Select
            isMenuOpen={isMenuOpen}
            isMulti={props.isMulti ? props.isMulti : false}
            filterOption={customFilter}
            placeholder={props.placeholder}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            isSearchable={true}
            isOptionSelected={option => {
                return props.address.selected === option.value ? true : false;
            }}
            onChange={(selected) => {
                props.setValue("selected", selected.value);
                props.setValue("selectedCity", selected);
                props.setValue("selectedDistrict", null);
                props.getAllDistricts(props.auth.user.token, selected.value);
            }}
            options={props.address.cities}
        />
    );
}